<template>
  <div v-if="localReferences.length" class="mb-2">
    <h5>
      {{ $t("activerecord.models.minutes.other", company.locale) }}
    </h5>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="pt-0 pb-1 col-shrink">
              {{ $t("activerecord.attributes.meeting.date", company.locale) }}
            </th>

            <th class="pt-0 pb-1">
              {{ $t("activerecord.models.meeting.one", company.locale) }}
            </th>

            <th class="col-shrink pt-0 pb-1">
              {{ $t("activerecord.attributes.meeting.status", company.locale) }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="reference in localReferences"
            :key="`reference-${reference.id}`"
          >
            <td class="col-shrink">
              {{ $d(new Date(reference.minutes.meeting_start_at), "short") }}
            </td>

            <td>
              <be-link
                class="d-print-none"
                :href="url(`/meetings/${reference.minutes.meeting_id}/minutes`)"
              >
                {{
                  `#${reference.minutes.meeting_number} - ${reference.minutes.title}`
                }}
              </be-link>

              <span class="d-none d-print-inline">
                {{
                  `#${reference.minutes.meeting_number} - ${reference.minutes.title}`
                }}
              </span>
            </td>

            <td class="col-shrink">
              {{ minutesStatus(reference.minutes) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const materialHelpers = createNamespacedHelpers("material");

export default {
  props: {
    itemId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...materialHelpers.mapGetters(["followUpReferences"]),

    localReferences() {
      return this.followUpReferences.filter(
        (reference) =>
          reference.item_id === this.itemId &&
          reference.reference_type === "Material"
      );
    },
  },

  methods: {
    minutesStatus(minutes) {
      if (minutes.signed_at) {
        return this.$t(
          "components.meetings.material.item_meetings.status.signed",
          this.company.locale
        );
      } else if (minutes.approved_at) {
        return this.$t(
          "components.meetings.material.item_meetings.status.approved",
          this.company.locale
        );
      } else if (minutes.sealed) {
        return this.$t(
          "components.meetings.material.item_meetings.status.sealed",
          this.company.locale
        );
      } else {
        return this.$t(
          "components.meetings.material.item_meetings.status.not_sealed",
          this.company.locale
        );
      }
    },
  },
};
</script>
