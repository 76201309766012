const state = {
  jobCallbacks: [],
};

const getters = {
  getJobCallbacks: (state) => state.jobCallbacks,

  getNewestJobCallbackByReference: (state) => {
    const callbacks = {};
    state.jobCallbacks.forEach((callback) => {
      const key = `${callback.reference_type}-${callback.reference_id}`;
      const currentDate = new Date(callback.created_at);

      if (
        !callbacks[key] ||
        currentDate > new Date(callbacks[key].created_at)
      ) {
        callbacks[key] = callback;
      }
    });
    return callbacks;
  },
};

const mutations = {
  updateCallback(state, callback) {
    const index = state.jobCallbacks.findIndex((cb) => cb.id === callback.id);
    if (index > -1) {
      state.jobCallbacks[index] = callback;
    } else {
      state.jobCallbacks.push(callback);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
