<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="media mb-4 mb-sm-3">
        <div class="align-self-center align-self-md-start mt-md-1 mr-3">
          <div class="media-icon">
            <div class="h3">
              <i class="fal fa-fw fa-calendar text-primary" />
            </div>
          </div>
        </div>

        <div class="media-body">
          <div class="d-flex">
            <h5 class="mb-0">{{ $t("components.meetings.info.date") }}</h5>

            <a
              v-if="inlineCalendarLink"
              v-be-tooltip="
                $t(
                  'components.annual_cycles.meeting_container.download_calendar_file'
                )
              "
              :href="meeting.paths.calendar"
              class="ml-2"
            >
              <i class="fal fa-calendar-arrow-down"></i>
            </a>
          </div>
          {{
            meeting.start_at
              ? formattedDateRange
              : $t("components.meetings.info.no_date_decided_html")
          }}
        </div>
      </div>

      <div class="media mb-4 mb-sm-3">
        <div class="align-self-center align-self-md-start mt-md-1 mr-3">
          <div class="media-icon">
            <div class="h3">
              <i class="fal fa-fw fa-map-marker-alt text-primary" />
            </div>
          </div>
        </div>

        <div class="media-body">
          <h5 class="mb-0">{{ $t("components.meetings.info.location") }}</h5>

          <span v-if="meeting.location">
            {{ meeting.location }}
          </span>

          <span v-else-if="!meeting.video_conference_meeting"> - </span>

          <div>
            <be-link
              v-if="meeting.video_conference_active_now"
              :href="
                url(`/meetings/${meeting.id}/video_meeting`, {
                  nanoid: meeting.company_id,
                })
              "
              target="_blank"
            >
              {{ $t("companies.meetings.video_meeting_link_title") }}
            </be-link>

            <strong v-else-if="meeting.video_conference_meeting">
              {{ $t("components.meetings.info.video_meeting_no_url") }}
            </strong>
          </div>
        </div>
      </div>

      <div v-if="showMeetingSeries" class="media mb-4 mb-sm-3">
        <div class="align-self-center align-self-md-start mt-md-1 mr-3">
          <div class="media-icon">
            <div class="h3">
              <i class="fal fa-fw fa-screen-users text-primary" />
            </div>
          </div>
        </div>

        <div class="media-body">
          <h5 class="mb-0">
            {{ $t("activerecord.attributes.meeting.meeting_series_type") }}
          </h5>

          <span v-if="meeting.meeting_series_type">
            {{
              $t(
                `models.meeting.meeting_series_type.${meeting.meeting_series_type}.one`
              )
            }}
          </span>

          <span v-else> - </span>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <div class="media mb-4 mb-sm-3">
        <div class="align-self-center align-self-md-start mt-md-1 mr-3">
          <div class="media-icon">
            <div class="h3">
              <i class="fal fa-fw fa-info-circle text-primary" />
            </div>
          </div>
        </div>

        <div class="media-body">
          <h5 class="mb-0">
            {{ $t("components.meetings.info.theme_and_reason") }}
          </h5>

          <span v-if="meeting.theme">
            {{ meeting.theme }}
          </span>

          <span v-if="meeting.theme && meeting.reason"> / </span>

          <span
            v-if="meeting.reason"
            v-dompurify-html="meeting.formatted_reason"
          ></span>

          <span v-if="!meeting.theme && !meeting.reason"> - </span>
        </div>
      </div>

      <div class="media mb-4 mb-sm-3">
        <div class="align-self-center align-self-md-start mt-md-1 mr-3">
          <div class="media-icon">
            <div class="h3">
              <i class="fal fa-fw fa-book text-primary" />
            </div>
          </div>
        </div>

        <div class="media-body">
          <h5 class="mb-0">
            {{ $t("components.meetings.info.preparations") }}
          </h5>

          <span
            v-if="meeting.formatted_preparations"
            v-dompurify-html="meeting.formatted_preparations"
          />

          <span v-else> - </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    meeting: {
      type: Object,
      required: true,
    },

    inlineCalendarLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    formattedDateRange() {
      if (this.meeting.start_at && this.meeting.end_at) {
        const startAt = new Date(this.meeting.start_at);
        const endAt = new Date(this.meeting.end_at);

        if (startAt.getDate() == endAt.getDate()) {
          return this.$i18n.t(
            "components.meetings.info.same_day_w_date_w_from_w_to",
            {
              date: this.$d(startAt, "date"),
              from: this.$d(startAt, "time"),
              to: this.$d(endAt, "time"),
            }
          );
        }
        return this.$i18n.t("components.meetings.info.date_from_to", {
          from: this.$d(startAt, "dateTime"),
          to: this.$d(endAt, "dateTime"),
        });
      }
      return this.$i18n.t("components.meetings.info.no_date_decided_html");
    },

    showMeetingSeries() {
      return (
        this.flipperFlag("meeting_series_flipper") &&
        this.$platform.features.meeting_series
      );
    },
  },
};
</script>
