<template>
  <div>
    <div class="d-flex align-items-center">
      <i v-if="showIcon" :class="`${iconClass} text-muted mr-2`" />

      <div>
        <template v-if="readonly">
          {{ documentFilename }}
        </template>

        <be-link
          v-else
          v-be-modal="modalId"
          :class="{ 'text-break': textbreak }"
        >
          {{ documentFilename }}
        </be-link>
      </div>
    </div>

    <document-modal
      :modal-id="modalId"
      :document-id="documentId"
      :company-id="companyId"
      @reading-updated="$emit('reading-updated', $event)"
    />
  </div>
</template>

<script>
import { getClassNameForFilename } from "@/vendor/font-awesome-filetypes";
import DocumentModal from "./DocumentModal.vue";

export default {
  components: {
    DocumentModal,
  },

  props: {
    documentId: {
      type: Number,
      required: true,
    },

    companyId: {
      type: Number,
      default: null,
      require: false,
    },

    filename: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: null,
    },

    icon: {
      type: String,
      default: null,
    },

    textbreak: {
      type: Boolean,
      default: true,
    },

    showIcon: {
      type: Boolean,
      default: true,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["reading-updated"],

  computed: {
    documentFilename() {
      return this.title || this.filename;
    },

    iconClass() {
      return this.icon || `fal ${getClassNameForFilename(this.filename)}`;
    },

    modalId() {
      return `document-modal-${this.documentId}-${Math.random()}`;
    },
  },
};
</script>
