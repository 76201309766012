import axios from "axios";
import { url } from "@/url-helpers";
import uuid from "@/mixins/uuid/index";
import { handleError } from "@/utils/error-handling";

export const state = {
  templatePackage: null,
  templatePackages: [],
  userTemplates: [],
  savedTemplates: [],
  invitations: [],
};

export const getters = {
  getTemplatePackage: (state) => state.templatePackage,
  getInvitations: (state) => state.invitations,

  publicTemplates: (state) =>
    state.templatePackages
      .filter((templatePackage) => templatePackage.visibility == "public")
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at)),

  privateTemplates: (state) =>
    state.templatePackages
      .filter((templatePackage) => templatePackage.visibility == "private")
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at)),

  publishedTemplates: (state, getters, rootState, rootGetters) =>
    state.templatePackages
      .filter(
        (templatePackage) =>
          templatePackage.company_id == rootGetters["company/getCompany"].id
      )
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at)),

  userTemplates: (state) =>
    state.userTemplates
      .filter((template) => template.template_type != "Phrase")
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at)),

  savedTemplates: (state) =>
    state.savedTemplates.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    ),
};

export const actions = {
  setTemplatePackage(context, templatePackage) {
    const { invitations, ...packageData } = templatePackage;
    context.commit("setTemplatePackage", packageData);
    if (invitations) {
      context.commit("setInvitations", invitations);
    }
  },

  addInvitation(context) {
    context.commit("addInvitation");
  },

  async unpublish(context, templatePackage) {
    const company = context.rootState.company.company;
    const unpublishUrl = url(
      `/template_packages/${templatePackage.id}/unpublish`,
      { company: company }
    );

    await axios.post(unpublishUrl);

    context.commit("removeTemplatePackage", templatePackage);
    context.commit("updateUserTemplates", templatePackage);
  },

  async inviteUser(context, parameters) {
    const templatePackage = context.getters.getTemplatePackage;

    if (templatePackage?.id) {
      try {
        const response = await axios.post(
          url(`/template_packages/${templatePackage.id}/invitations`),
          {
            invitation: {
              name: parameters.user.name,
              email: parameters.user.email,
            },
          }
        );

        context.commit("updateInvitation", response.data);
      } catch (error) {
        if (error.response?.status === 422) {
          context.commit("updateInvitation", error.response.data);
        }
        handleError(error);
      }
    }
  },
};

export const mutations = {
  load(state, templatePackages) {
    state.templatePackages = templatePackages;
  },

  loadUserTemplates(state, userTemplates) {
    state.userTemplates = userTemplates;
  },

  loadSavedTemplates(state, savedTemplates) {
    state.savedTemplates = savedTemplates;
  },

  setTemplatePackage(state, templatePackage) {
    state.templatePackage = templatePackage;
  },

  setInvitations(state, invitations) {
    state.invitations = invitations;
  },

  addInvitation(state) {
    state.invitations.push({
      uuid: uuid.methods.generateUuid(),
      destroyed: false,
      persisted: false,

      user: {
        name: "",
        email: "",
      },
    });
  },

  updateInvitation(state, invitation) {
    let index = state.invitations.findIndex((existingInvitation) => {
      let alreadyInvited =
        invitation.id && existingInvitation.user_id === invitation.user_id;

      let newInvitation =
        !existingInvitation.id &&
        existingInvitation.user?.email === invitation.user?.email;

      return alreadyInvited || newInvitation;
    });

    if (index === -1) {
      state.invitations.push(invitation);
    } else {
      invitation["uuid"] = uuid.methods.generateUuid();
      state.invitations[index] = invitation;
    }
  },

  removeTemplatePackage(state, templatePackage) {
    let idx = state.templatePackages.findIndex((existingTemplatePackage) => {
      return existingTemplatePackage.id == templatePackage.id;
    });

    if (idx > -1) {
      state.templatePackages.splice(idx, 1);
    }
  },

  updateUserTemplates(state, templatePackage) {
    templatePackage.template_package_items.forEach((packageItem) => {
      // Set original template to get updated policy states
      let idx = state.userTemplates.findIndex((existingTemplate) => {
        if (packageItem.original) {
          return existingTemplate.id == packageItem.original.id;
        }
      });

      if (idx > -1) {
        state.userTemplates[idx] = packageItem.original;
      }
    });
  },

  saveTemplate(state, templatePackage) {
    let savedTemplateIds = state.savedTemplates.map(
      (savedTemplate) => savedTemplate.id
    );

    // Change package item in templatePackages to the updated since it's now downloaded
    let idx = state.templatePackages.findIndex(
      (existingTemplatePackage) =>
        existingTemplatePackage.id == templatePackage.id
    );

    if (idx > -1) {
      state.templatePackages[idx] = templatePackage;
    }

    // Update saved templates
    templatePackage.template_package_items.forEach((packageItem) => {
      if (packageItem.company_saved_template_object) {
        if (
          !savedTemplateIds.includes(
            packageItem.company_saved_template_object.id
          )
        ) {
          state.savedTemplates.push(packageItem.company_saved_template_object);
        }
      }
    });
  },

  removeTemplate(state, template) {
    // Remove template from saved templates
    let idx = state.savedTemplates.findIndex(
      (existingTemplate) => existingTemplate.id == template.id
    );

    if (idx > -1) {
      state.savedTemplates.splice(idx, 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
