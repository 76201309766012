import axios from "axios";
import { url } from "@/url-helpers";
import { handleError } from "@/utils/error-handling";

export const state = {
  invitations: [],
  loadedInvitations: false,
  loadedMessages: false,
  messages: [],
  thread: null,
};

export const getters = {
  getLatestMessage: (state) => {
    let length = state.messages.length;
    if (length > 0) {
      return state.messages[length - 1];
    }
    return null;
  },

  getInvitations: (state) => state.invitations,

  getActiveInvitations: (state) =>
    state.invitations.filter(
      (invitation) =>
        invitation.id && !invitation.deleted_at && invitation.created_at
    ),

  getMessages: (state) => state.messages,
  getThread: (state) => state.thread,
  loadedInvitations: (state) => state.loadedInvitations,
  loadedMessages: (state) => state.loadedMessages,

  getMessage: (state) => (message) => {
    return state.messages.find((existing) => {
      return existing.id == message.id;
    });
  },
};

export const actions = {
  async createThread(context, parameters) {
    try {
      let response = await axios.post(url("/message_threads"), parameters);
      context.commit("loadThread", response.data);
      return true;
    } catch (error) {
      context.commit("loadThread", error.response.data);
      handleError(error);
      return false;
    }
  },

  async deleteThread(context) {
    let thread = context.getters.getThread;

    if (!thread) {
      return false;
    }

    try {
      await axios.delete(url(`/message_threads/${thread.id}`));
      context.commit("selectThread", null);
      return true;
    } catch (error) {
      handleError(error);
      return false;
    }
  },

  async fetchInvitations(context, reload = false) {
    let thread = context.getters.getThread;
    let load = reload || !context.getters.loadedInvitations;
    if (!load) {
      return;
    }

    let invitations_url = url("/invitations");
    if (thread?.id) {
      invitations_url = url(`/message_threads/${thread.id}/invitations`);
    }

    try {
      const response = await axios.get(invitations_url);
      context.commit("loadInvitations", response.data);
    } catch (error) {
      handleError(error);
    }
  },

  async fetchMessages(context, reload = false) {
    let thread = context.getters.getThread;
    let load = reload || !context.getters.loadedMessages;
    if (load && thread?.id) {
      let response = await axios.get(
        url(`/message_threads/${thread.id}/messages`)
      );
      context.commit("loadMessages", response.data);
    }
  },

  async inviteUser(context, user_id) {
    let thread = context.getters.getThread;
    if (thread?.id) {
      let response = await axios.post(
        url(`/message_threads/${thread.id}/invitations`),
        {
          invitation: {
            user_id: user_id,
          },
        }
      );
      context.commit("updateInvitation", response.data);
    }
  },

  async uninviteUser(context, invitation) {
    let thread = context.getters.getThread;
    let response = await axios.delete(
      url(`/message_threads/${thread.id}/invitations/${invitation.id}`)
    );
    context.commit("updateInvitation", response.data);
  },

  async createMessage(context, parameters) {
    let thread = context.getters.getThread;
    let response = await axios.post(
      url(`/message_threads/${thread.id}/messages`),
      parameters
    );
    context.commit("updateMessage", response.data);
    return response.data;
  },

  async updateMessage(context, message) {
    let thread = context.getters.getThread;
    let response = await axios.patch(
      url(`/message_threads/${thread.id}/messages/${message.id}`),
      {
        message: message,
      }
    );
    context.commit("updateMessage", response.data);
  },
};

export const mutations = {
  selectThread(state, thread) {
    state.thread = thread;
    state.invitations = [];
    state.loadedInvitations = false;
    state.messages = [];
    state.loadedMessages = false;
  },

  loadThread(state, thread) {
    state.thread = thread;
  },

  setMessage(state, message) {
    const index = state.messages.findIndex(
      (existingMessage) => existingMessage.id == message.id
    );

    if (index > -1) {
      state.messages[index] = message;
    }
  },

  updateInvitation(state, invitation) {
    let index = state.invitations.findIndex(
      (existing) => existing.user_id == invitation.user_id
    );
    if (index === -1) {
      state.invitations.push(invitation);
    } else {
      state.invitations[index] = invitation;
    }
  },

  loadInvitations(state, invitations) {
    state.invitations = invitations;
    state.loadedInvitations = true;
  },

  loadMessages(state, messages) {
    state.messages = messages;
    state.loadedMessages = true;
  },

  updateMessage(state, message) {
    const index = state.messages.findIndex(
      (existing) => existing.id == message.id
    );
    if (index === -1) {
      state.messages.push(message);
    } else {
      state.messages[index] = message;
    }
  },

  updateMessageAttributes(state, payload) {
    Object.entries(payload.data).forEach((data) => {
      payload.message[data[0]] = data[1];
    });
  },

  removeDocument(state, payload) {
    const documentIndex = payload.message.documents.findIndex(
      (existing) => existing.id == payload.document.id
    );

    if (documentIndex > -1) {
      payload.message.documents.splice(documentIndex, 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
