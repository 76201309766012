<template>
  <input
    v-bind="computedAttrs"
    ref="input"
    :class="computedClass"
    :value="localValue"
    @input="onInput"
    @change="onChange"
    @focus="onFocus"
    @blur="onBlur"
    @keyup="onKeyup"
    @keydown="onKeydown"
    @paste="onPaste"
  />
</template>

<script>
import formStateMixin from "@/mixins/forms/form-state";
import formTextMixin from "@/mixins/forms/form-text";
import { generateId } from "@/utils/id";

// Valid supported input types
const SUPPORTED_TYPES = [
  "text",
  "password",
  "email",
  "number",
  "url",
  "tel",
  "search",
  "range",
  "color",
  "date",
  "time",
  "datetime",
  "datetime-local",
  "month",
  "week",
];

export default {
  name: "BeFormInput",

  mixins: [formStateMixin, formTextMixin],

  props: {
    id: {
      type: String,
      required: false,

      default: () => {
        return generateId("be-form-input");
      },
    },

    max: {
      type: [Number, String],
      required: false,
      default: undefined,
    },

    min: {
      type: [Number, String],
      required: false,
      default: undefined,
    },

    modelValue: {
      type: [String, Number],
      required: false,
      default: "",
    },

    size: {
      type: String,
      required: false,
      default: undefined,
    },

    step: {
      type: [Number, String],
      required: false,
      default: undefined,
    },

    type: {
      type: String,
      required: false,
      default: "text",

      validator: (value) => {
        return SUPPORTED_TYPES.includes(value);
      },
    },
  },

  computed: {
    localType() {
      // Default to "text" if type is not supported
      return SUPPORTED_TYPES.includes(this.type) ? this.type : "text";
    },

    computedAttrs() {
      return {
        id: this.id,
        name: this.name,
        form: this.form,
        type: this.localType,
        disabled: this.disabled || null,
        placeholder: this.placeholder,
        required: this.computedRequired || null,
        autocomplete: this.autocomplete || null,
        readonly: this.readonly || this.plaintext || null,
        min: this.min,
        max: this.max,
        step: this.step,
        "aria-required": this.required ? "true" : null,
        "aria-invalid": this.computedAriaInvalid || null,
      };
    },
  },

  mounted() {
    if (this.type === "tel") {
      console.warn("BeFormInput: Use `BeTelInput` instead.");
    }
  },
};
</script>
