import uuid from "@/mixins/uuid/index";
import { url } from "@/url-helpers";
import { handleError } from "@/utils/error-handling";
import axios from "axios";

import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";
import sortBy from "lodash/sortBy";
import zipWith from "lodash/zipWith";

export const state = {
  meetings: [],
  initialNumber: null,
  initialMeetingCount: null,
  year: null,
  expandedMeetingUuids: [],
};

export const getters = {
  meetings: (state) => state.meetings,
  initialNumber: (state) => state.initialNumber,
  initialMeetingCount: (state) => state.initialMeetingCount,
  meetingCount: (state) => state.meetings.length,
  year: (state) => state.year,
  expandedMeetingUuids: (state) => state.expandedMeetingUuids,
};

export const actions = {
  setMeetings({ commit }, { meetings, initialNumber }) {
    commit("setMeetings", meetings);
    commit("setInitialNumber", meetings[0]?.number || initialNumber);
  },

  async submitMeetings(context) {
    try {
      await axios.post(url("/annual_cycle"), {
        year: context.getters.year,
        meetings: context.getters.meetings,
      });
      return true;
    } catch (error) {
      if (error.response?.status === 422) {
        console.error("422", error);
        context.commit("setMeetings", error.response.data);
      } else {
        handleError(error);
      }
      return false;
    }
  },

  removeMeeting(context, meeting) {
    context.commit("removeMeeting", meeting);
    context.commit("reorderMeetings");
  },
};

export const mutations = {
  setYear(state, year) {
    state.year = year;
  },

  setMeetings(state, meetings) {
    state.initialMeetingCount = meetings.length;
    state.expandedMeetingUuids = [];
    meetings.forEach((meeting) => {
      meeting.uuid = uuid.methods.generateUuid();
      if (Object.keys(meeting.errors || {}).length > 0) {
        state.expandedMeetingUuids.push(meeting.uuid);
      }
    });
    state.meetings = meetings;
  },

  setInitialNumber(state, initialNumber) {
    state.initialNumber = initialNumber;
  },

  toggleMeeting(state, uuid) {
    const index = state.expandedMeetingUuids.findIndex(
      (existing) => existing === uuid
    );

    if (index > -1) {
      state.expandedMeetingUuids.splice(index, 1);
    } else {
      state.expandedMeetingUuids.push(uuid);
    }
  },

  expandMeeting(state, uuid) {
    const index = state.expandedMeetingUuids.findIndex(
      (existing) => existing === uuid
    );

    if (index === -1) {
      state.expandedMeetingUuids.push(uuid);
    }
  },

  collapseMeeting(state, uuid) {
    const index = state.expandedMeetingUuids.findIndex(
      (existing) => existing === uuid
    );

    if (index > -1) {
      state.expandedMeetingUuids.splice(index, 1);
    }
  },

  updateMeeting(state, meeting) {
    const index = state.meetings.findIndex(
      (m) => m.uuid && m.uuid === meeting.uuid
    );

    if (index > -1) {
      state.meetings[index] = meeting;
    } else {
      state.meetings.push(meeting);
      state.expandedMeetingUuids.push(meeting.uuid);
    }
  },

  removeMeeting(state, meeting) {
    const index = state.meetings.findIndex(
      (m) => m.uuid && m.uuid === meeting.uuid
    );

    if (index === -1) {
      return;
    }

    state.meetings.splice(index, 1);
  },

  reorderMeetings(state, event) {
    const meetings = cloneDeep(state.meetings);
    if (event) {
      event.apply(meetings);
    }
    let currentNumber = state.initialNumber;

    meetings.forEach((meeting) => {
      if (!meeting._destroy) {
        meeting.number = currentNumber;
        currentNumber += 1;
      }
    });

    state.meetings = meetings;
  },

  reorderDates(state) {
    const meetings = cloneDeep(state.meetings);
    const dates = meetings.map((meeting) =>
      pick(meeting, ["start_at", "end_at"])
    );
    const sortedDates = sortBy(dates, "start_at");
    state.meetings = zipWith(meetings, sortedDates, (meeting, date) => {
      return { ...meeting, ...date };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
