<template>
  <div>
    <h5>
      {{
        $t(
          `components.meetings.material.preview.${translationScope}.agenda_title`,
          company.locale
        )
      }}
    </h5>

    <div v-if="invitations.length > 0">
      <p v-for="invitation in invitations" :key="invitation.id" class="m-0">
        {{ invitation.name }}
      </p>
    </div>

    <em v-else class="text-muted mb-3">
      {{
        $t(
          `components.meetings.material.preview.${translationScope}.none_invited`,
          company.locale
        )
      }}
    </em>
  </div>
</template>

<script>
import materialType from "./materialState";
import { mapGetters } from "vuex";

export default {
  mixins: [materialType],

  props: {
    invitations: {
      type: Array,
      required: true,
    },

    translationScope: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      company: "company/getCompany",
    }),
  },
};
</script>
