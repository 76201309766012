import isEqual from "lodash/isEqual";

/*
 * Utility methods
 */

// Method to get the correct $nextTick method from the vnode instance
function getNextTick(vnode) {
  return vnode.ctx?.$nextTick || vnode.ctx?.ctx?.$nextTick;
}

// Process options to return an object
function processOptions(value) {
  let options;

  if (typeof value === "function") {
    options = { callback: value };
  } else {
    options = value;
  }

  return options;
}

// Throttle function to avoid triggering the callback too often
function throttle(callback, delay, options = {}) {
  let timeout, lastState, currentArgs;

  // The throttled function that will be returned
  const throttled = (state, ...args) => {
    // Store the arguments for later use
    currentArgs = args;

    // If there's a timeout already and the state hasn't changed, don't do anything
    if (timeout && state === lastState) {
      return;
    }

    // Get the leading option - this determines whether the callback should be invoked on the leading edge of the delay
    let leading = options.leading;
    // If leading is a function, call it with the current and last state
    if (typeof leading === "function") {
      leading = leading(state, lastState);
    }

    // If there's no timeout or the state has changed and leading is true, call the callback immediately
    if ((!timeout || state !== lastState) && leading) {
      callback(state, ...currentArgs);
    }

    // Store the current state as the last state
    lastState = state;

    // Clear any existing timeout
    clearTimeout(timeout);

    // Set a new timeout to call the callback after the delay
    timeout = setTimeout(() => {
      callback(state, ...currentArgs);
      // Clear the timeout flag
      timeout = 0;
    }, delay);
  };

  // Add a clear method to the throttled function to clear the timeout if necessary
  throttled._clear = () => {
    clearTimeout(timeout);
    timeout = null;
  };

  // Return the throttled function
  return throttled;
}

/*
 * VisibilityObserver class
 */

class VisibilityObserver {
  // Constructor initializes the observer with the element, options, and Vue node
  constructor(el, options, vnode) {
    this.el = el;
    this.observer = null;
    this.frozen = false;
    this.createObserver(options, vnode);
  }

  // Getter for the threshold value from the options
  get threshold() {
    return this.options.intersection &&
      typeof this.options.intersection.threshold === "number"
      ? this.options.intersection.threshold
      : 0;
  }

  // Method to create the Intersection Observer
  createObserver(options, vnode) {
    // If an observer already exists, destroy it before creating a new one
    if (this.observer) {
      this.destroyObserver();
    }

    // If the observer is frozen, don't create a new one
    if (this.frozen) {
      return;
    }

    // Process the options
    this.options = processOptions(options);

    // Define the callback function to be used by the observer
    this.callback = (result, entry) => {
      this.options.callback(result, entry);

      // If the result is true and the observer is set to only trigger once, freeze the observer
      if (result && this.options.once) {
        this.frozen = true;
        this.destroyObserver();
      }
    };

    // If a throttle value is set in the options, throttle the callback function
    if (this.callback && this.options.throttle) {
      const { leading } = this.options.throttleOptions || {};

      this.callback = throttle(this.callback, this.options.throttle, {
        leading: (state) => {
          return (
            leading === "both" ||
            (leading === "visible" && state) ||
            (leading === "hidden" && !state)
          );
        },
      });
    }

    this.oldResult = undefined;

    // Create the Intersection Observer
    this.observer = new IntersectionObserver((entries) => {
      let entry = entries[0];

      // If there are multiple entries, find the first one that is intersecting
      if (entries.length > 1) {
        const intersectingEntry = entries.find((entry) => entry.isIntersecting);

        if (intersectingEntry) {
          entry = intersectingEntry;
        }
      }

      // If a callback function is defined, call it with the result and the entry
      if (this.callback) {
        const result =
          entry.isIntersecting && entry.intersectionRatio >= this.threshold;

        // If the result is the same as the old result, don't call the callback
        if (result === this.oldResult) {
          return;
        }

        this.oldResult = result;
        this.callback(result, entry);
      }
    }, this.options.intersection);

    // Wait for the element to be in the DOM before observing it,
    // if $nextTick does not exist, observe immediately
    const $nextTick = getNextTick(vnode);
    if ($nextTick) {
      $nextTick(() => {
        if (this.observer) {
          this.observer.observe(this.el);
        }
      });
    } else {
      if (this.observer) {
        this.observer.observe(this.el);
      }
    }
  }

  // Method to destroy the Intersection Observer
  destroyObserver() {
    // Disconnect the observer and set it to null
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }

    // If a throttled callback function is defined, clear it
    if (this.callback && this.callback._clear) {
      this.callback._clear();
      this.callback = null;
    }
  }
}

/*
 * Directive lifecycle hooks and definition
 */

function mount(el, { value }, vnode) {
  // If the value is falsy, don't create the observer
  if (!value) {
    return;
  }

  // If the Intersection Observer API is not available, warn the user
  if (typeof IntersectionObserver === "undefined") {
    console.warn(
      "[vue-observe-visibility] IntersectionObserver API is not available in your browser. Please install this polyfill: https://github.com/w3c/IntersectionObserver/tree/master/polyfill"
    );
  } else {
    // Create a new VisibilityObserver instance and store it on the element
    const state = new VisibilityObserver(el, value, vnode);
    el._vue_visibilityState = state;
  }
}

function update(el, { value, oldValue }, vnode) {
  // If the value is the same, don't do anything
  if (isEqual(value, oldValue)) {
    return;
  }

  // Get the current state from the element
  const state = el._vue_visibilityState;

  // If the new value is falsy, unmount the observer
  if (!value) {
    unmount(el);
    return;
  }

  if (state) {
    // If there is a state, update the observer
    state.createObserver(value, vnode);
  } else {
    // If there is no state, mount the observer
    mount(el, { value }, vnode);
  }
}

function unmount(el) {
  // Get the current state from the element
  const state = el._vue_visibilityState;

  // If there is a state, destroy the observer and delete the state
  if (state) {
    state.destroyObserver();
    delete el._vue_visibilityState;
  }
}

export default {
  beforeMount: mount,
  updated: update,
  unmounted: unmount,
};
