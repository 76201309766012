/**
 * Mixin to extract some logic from the BeModal component.
 *
 * This mixin is used by the BeModal component to handle the
 * scrollbar and z-index adjustments when opening/closing modals,
 * works in conjunction with the `modals` store module.
 */

import { mapGetters, mapMutations } from "vuex";
import { helpers } from "@/store/modules/modals";

// Selectors for padding/margin adjustments
const SELECTOR_FIXED_CONTENT =
  ".fixed-top, .fixed-bottom, .is-fixed, .sticky-top";
const SELECTOR_STICKY_CONTENT = ".sticky-top";

export default {
  data() {
    return {
      zIndex: helpers.calculateBaseZIndex(),
      isTop: true,
    };
  },

  computed: {
    ...mapGetters("modals", [
      "modals",
      "baseZIndex",
      "scrollbarWidth",
      "isBodyOverflowing",
      "modalCount",
      "modalsAreOpen",
    ]),
  },

  watch: {
    modalCount(newCount, oldCount) {
      this.setScrollbarWidth();

      if (newCount > 0 && oldCount === 0) {
        // Transitioning to modal(s) open
        this.checkScrollbar();
        this.setScrollbar();
        document.body.classList.add("modal-open");
      } else if (newCount === 0 && oldCount > 0) {
        // Transitioning to modal(s) closed
        this.resetScrollbar();
        document.body.classList.remove("modal-open");
      }

      document.body.setAttribute("data-modal-open-count", newCount);
    },

    modals: {
      handler(newValue) {
        this.checkScrollbar();
        this.$nextTick(() => {
          this.updateModals(newValue || []);
        });
      },

      deep: true,
    },
  },

  methods: {
    ...mapMutations("modals", [
      "setScrollbarWidth",
      "checkScrollbar",
      "registerModal",
      "unregisterModal",
    ]),

    unregister(modal) {
      this.unregisterModal(modal);

      // Reset the modal's data
      if (!modal._isBeingDestroyed && !modal._isDestroyed) {
        this.resetModal(modal);
      }
    },

    updateModals(modals) {
      const { baseZIndex } = this;

      modals.forEach((modal, index) => {
        modal.zIndex = baseZIndex + index;
        modal.isTop = index === modals.length - 1;
      });
    },

    resetModal(modal) {
      if (modal) {
        modal.zIndex = helpers.calculateBaseZIndex();
        modal.isTop = true;
      }
    },

    setScrollbar() {
      const body = document.body;

      body._paddingChangedForModal = body._paddingChangedForModal || [];
      body._marginChangedForModal = body._marginChangedForModal || [];

      if (this.isBodyOverflowing) {
        const scrollbarWidth = this.scrollbarWidth;

        // Adjust fixed content padding
        document.querySelectorAll(SELECTOR_FIXED_CONTENT).forEach((el) => {
          const actualPadding = el.style.paddingRight || "";
          el.setAttribute("data-padding-right", actualPadding);
          el.style.paddingRight = `${
            parseFloat(getComputedStyle(el).paddingRight) + scrollbarWidth
          }px`;
          body._paddingChangedForModal.push(el);
        });

        // Adjust sticky content margin
        document.querySelectorAll(SELECTOR_STICKY_CONTENT).forEach((el) => {
          const actualMargin = el.style.marginRight || "";
          el.setAttribute("data-margin-right", actualMargin);
          el.style.marginRight = `${
            parseFloat(getComputedStyle(el).marginRight) - scrollbarWidth
          }px`;
          body._marginChangedForModal.push(el);
        });

        // Adjust body padding
        const actualPadding = body.style.paddingRight || "";
        body.setAttribute("data-padding-right", actualPadding);
        body.style.paddingRight = `${
          parseFloat(getComputedStyle(body).paddingRight) + scrollbarWidth
        }px`;
      }
    },

    resetScrollbar() {
      const body = document.body;

      // Restore fixed content padding
      if (body._paddingChangedForModal) {
        body._paddingChangedForModal.forEach((el) => {
          if (el.hasAttribute("data-padding-right")) {
            el.style.paddingRight = el.getAttribute("data-padding-right");
            el.removeAttribute("data-padding-right");
          }
        });
      }

      // Restore sticky content margin
      if (body._marginChangedForModal) {
        body._marginChangedForModal.forEach((el) => {
          if (el.hasAttribute("data-margin-right")) {
            el.style.marginRight = el.getAttribute("data-margin-right");
            el.removeAttribute("data-margin-right");
          }
        });
      }

      // Restore body padding
      if (body.hasAttribute("data-padding-right")) {
        body.style.paddingRight = body.getAttribute("data-padding-right");
        body.removeAttribute("data-padding-right");
      }

      // Reset data
      body._paddingChangedForModal = null;
      body._marginChangedForModal = null;
    },
  },
};
