import axios from "axios";
import { url } from "@/url-helpers";
import { handleError } from "@/utils/error-handling";

export const state = {
  company: {
    enabled_integrations: null,
    features: [],
    memberships: [],

    financials: {
      initializing: true,
      initialized: false,
      endDate: null,
      endDateString: null,
      origin: null,
      numberScale: null,
      decimalScale: null,
      displayCorporateGroup: false,
      drillDown: true,
    },

    logo: null,
  }, // Current Company

  companies: [], // All Companies
  usedStoragePercentage: null,
  loadedProjects: false,
  projects: [],

  unreads: {
    pending_signatures: null,
    contract_notifications: null,
  },

  unreadsLoadedAt: null,
  unreadsLoading: false,
};

export const getters = {
  getById: (state) => (id) => {
    return state.company.id === id
      ? state.company
      : state.companies.find((company) => company.id == id);
  },

  getNanoIdForCompany: (state) => (id) => {
    const company =
      state.company.id === id
        ? state.company
        : state.companies.find((company) => company.id == id);
    return company ? company.nanoid : null;
  },

  memberships: (state) => state.company.memberships,

  // Get all users from memberships and filter out duplicates
  users: (state) => {
    const users = {};

    state.company.memberships.forEach((membership) => {
      if (membership.user) {
        users[membership.user.id] = membership.user;
      }
    });

    return Object.values(users);
  },

  userById: (state) => (id) => {
    const membership = state.company.memberships.find(
      (membership) => membership.user?.id === id
    );
    return membership?.user;
  },

  membershipByUserId: (state) => (userId) => {
    return state.company.memberships.find(
      (membership) => membership.user?.id === userId
    );
  },

  activeFinancialsUuid: (state) => {
    if (
      state.company.financials.displayCorporateGroup &&
      state.company.corporate_group_uuid
    ) {
      return state.company.corporate_group_uuid;
    } else {
      return state.company.financials_uuid;
    }
  },

  getCompany: (state) => state.company,
  getFinancials: (state) => state.company.financials,
  getUsers: (state) => state.company.users,
  getFeatures: (state) => state.company.features,
  getOnboardingProgress: (state) => state.company.progress,
  projects: (state) => state.projects,

  getOrganizationType: (state) =>
    state.company.preferences["type_of_organization"],

  subscriptionFor: (state) => (subscribed_id, subscribed_type) => {
    // TODO Check if empty object should be returned here?
    // Something needs to be returned if the subscription isn't found
    return state.company.subscriptions.find((subscription) => {
      return (
        subscription.subscribed_id == subscribed_id &&
        subscription.subscribed_type == subscribed_type
      );
    });
  },

  isContractAdmin:
    (state, getters, rootState, rootGetters) =>
    (user = rootGetters["current_user/getUser"]) => {
      return state.company.memberships.some((membership) => {
        return (
          membership.user.id === user.id && membership.role === "contract_admin"
        );
      });
    },

  hasFeature: (state) => (featureName) => {
    if (!Object.keys(state.company).length) {
      return false;
    }

    // TODO Check if this works everywhere
    if (!state.company.subscriptions) {
      return false;
    }

    return state.company.subscriptions.some(
      (enabled) => enabled.feature_name === featureName
    );
  },

  hasActiveFeature: (state) => (feature) => {
    if (!Object.keys(state.company).length) {
      return false;
    }

    // TODO Check if this works everywhere
    if (!state.company.subscriptions) {
      return false;
    }

    if (typeof feature === "string") {
      feature = {
        name: feature,
      };
    }

    const foundSubscription = state.company.subscriptions.find(
      (subscription) => subscription.feature_name == feature.name
    );

    return foundSubscription ? foundSubscription.current : false;
  },

  hasCancelledFeature: (state) => (feature) => {
    if (!Object.keys(state.company).length) {
      return false;
    }

    // TODO Check if this works everywhere
    if (!state.company.subscriptions) {
      return false;
    }

    return state.company.subscriptions.some((subscription) => {
      return (
        subscription.feature_name === feature.name &&
        !subscription.is_subscribed
      );
    });
  },

  hasEnabledIntegration: (state) => (integration) => {
    if (!Object.keys(state.company).length) {
      return false;
    }

    // TODO Check if this works everywhere
    if (!state.company.enabled_integrations) {
      return false;
    }

    return state.company.enabled_integrations.some(
      (enabled) => enabled.key === integration
    );
  },

  hasLoadedProjects: (state) => state.loadedProjects,
  hasLoadedInquiries: (state) => state.loadedInquiries,

  initializingFinancials: (state) => state.company?.financials?.initializing,

  hasInitializedFinancials: (state) => {
    const financials = state.company?.financials;

    return !financials?.initializing && financials?.initialized;
  },

  hasCorporateGroup: (state) => state.company.corporate_group_uuid,

  inCorporateGroup: (state) => {
    return state.company.financials.displayCorporateGroup;
  },

  usedStoragePercentage: (state) => {
    return state.usedStoragePercentage;
  },

  unreads: (state) => state.unreads,
  unreadsLoadedAt: (state) => state.unreadsLoadedAt,
  unreadsLoading: (state) => state.unreadsLoading,
};

export const actions = {
  async updateCompanySettings({ commit, dispatch, getters }, payload) {
    try {
      let response = await axios.put(
        `/companies/${payload.company.id}/settings`,
        {
          company: payload.companySettings,
        }
      );

      commit("setCompany", response.data);
      // load projects again if they were loaded
      if (getters.hasLoadedProjects) {
        await dispatch("loadProjects");
      }
      return true;
    } catch {
      return false;
    }
  },

  async loadProjects({ commit }) {
    try {
      let response = await axios.get(url("/projects"));
      commit("setProjects", response.data);
      return true;
    } catch {
      return false;
    }
  },

  async fetchEnabledIntegrations({ getters, commit }) {
    const company = getters.getCompany;
    if (company.enabled_integrations != null) {
      // Already loaded
      return;
    }

    try {
      const { data } = await axios.get(url("/enabled_integrations"));
      commit("setIntegrations", data);
    } catch (error) {
      handleError(error);
    }
  },

  async toggleIntegration({ getters, commit }, payload) {
    const integrationType = payload.integrationType;
    const enableIntegration = payload.enableIntegration;

    try {
      if (enableIntegration && getters.hasEnabledIntegration(integrationType)) {
        return false;
      }

      const response = await axios({
        method: enableIntegration ? "PATCH" : "DELETE",

        url: url("/integrations"),

        data: {
          integration: integrationType,
        },
      });

      if (enableIntegration) {
        commit("enableIntegration", response.data);
      } else {
        commit("disableIntegration", response.data);
      }

      return true;
    } catch (error) {
      handleError(error);
      return false;
    }
  },

  async loadStoragePercentage({ getters, commit }) {
    const company = getters.getCompany;
    if (!company.id || getters.usedStoragePercentage != null) {
      return;
    }

    try {
      const response = await axios.get(url("/statistics"));
      if (response?.data?.used_storage_in_percent != undefined) {
        commit("setStoragePercentage", response.data.used_storage_in_percent);
      }
    } catch (error) {
      handleError(error);
    }
  },

  async loadUnreads({ getters, commit }, force = false) {
    if (getters.unreadsLoading) {
      return;
    }

    const fiveMinutes = 5 * 60 * 1000;

    if (
      !force &&
      getters.unreadsLoadedAt &&
      new Date() - getters.unreadsLoadedAt < fiveMinutes
    ) {
      return;
    }

    commit("setUnreadsLoading", true);
    try {
      const response = await axios.get(url("/unread"));
      commit("setUnreads", response.data);
    } catch (error) {
      handleError(error);
    } finally {
      commit("setUnreadsLoading", false);
    }
  },
};

export const mutations = {
  setCompanies(state, companies) {
    state.companies = companies;
  },

  setCompany(state, company) {
    state.company = { ...state.company, ...company };
    state.usedStoragePercentage = null;
    state.loadedProjects = false;
    state.projects = [];
  },

  setProjects(state, projects) {
    state.projects = projects;
    state.loadedProjects = true;
  },

  setFinancials(state, options) {
    state.company.financials = { ...state.company.financials, ...options };
    // TODO: Stop using financials.uuid and instead use activeFinancialsUuid
    if (state.company.financials.displayCorporateGroup) {
      state.company.financials.uuid = state.company.corporate_group_uuid;
    } else {
      state.company.financials.uuid = state.company.financials_uuid;
    }
  },

  setIntegrations(state, integrations) {
    state.company.enabled_integrations = integrations;
  },

  disableIntegration(state, integration) {
    let idx = state.company.enabled_integrations.findIndex(
      (enabledIntegration) => {
        return enabledIntegration.key == integration.key;
      }
    );

    if (idx > -1) {
      state.company.enabled_integrations.splice(idx, 1);
    }
  },

  enableIntegration(state, integration) {
    let index = state.company.enabled_integrations.findIndex(
      (enabledIntegration) => {
        return enabledIntegration.key == integration.key;
      }
    );

    if (index === -1) {
      state.company.enabled_integrations.push(integration);
    }
  },

  setStoragePercentage(state, percentage) {
    state.usedStoragePercentage = percentage;
  },

  updateMembership(state, membership) {
    const index = state.company.memberships.findIndex(
      (existing) => existing.id === membership.id
    );
    if (index > -1) {
      state.company.memberships[index] = membership;
    } else {
      state.company.memberships.push(membership);
    }
  },

  setUnreads(state, unreads) {
    state.unreads = unreads;
    state.unreadsLoadedAt = new Date();
  },

  setUnreadsLoading(state, loading) {
    state.unreadsLoading = loading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
