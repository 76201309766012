import { EventBus } from "@/event-bus";
import store from "@/store";

const HEARTBEAT_DEBOUNCE_IN_SECONDS = 10;

async function refreshToken() {
  try {
    const response = await axios.get("/users/refresh_financials_token");
    const { token, tokenRefreshAfter } = response.data;

    wzGetController().setToken(token);
    setTimeout(refreshToken, tokenRefreshAfter * 1000);
  } catch (error) {
    // If we can't refresh the token, we should reload the page so that the user
    // gets information that its logged out or visualby is down. Any action the user
    // was doing is lost anyway.
    window.location.reload();
  }
}

const BI = {
  initialized: false,

  async bootstrap(options) {
    let tries = 0;
    while (typeof wzGetController === "undefined" && !wzGetController()) {
      // Wait for the visualby to be loaded
      // sleep for 200 ms
      await new Promise((resolve) => setTimeout(resolve, 200));
      tries++;

      if (tries > 50) {
        Sentry.captureMessage("Visualby not loaded after 10 seconds");
        return;
      }
    }

    const { token, tokenRefreshAfter } = window.BIConfig || options || {};

    if (!token) {
      return false;
    }

    const organizationUuids = (options.organizationUuids || []).filter(
      (uuid) => !!uuid
    );

    angular.bootstrap(document.getElementById("ng-app"), ["visualby.app"]);

    // We need this timeout so that the angular app can be loaded
    setTimeout(() => {
      if (this.initialized) {
        console.warn("App was already initialized!");
      }

      this.initialize(token, options, organizationUuids, (economy) => {
        this.initialized = true;
        EventBus.emit("LOADED_BI");
      });
    }, 100);

    if (tokenRefreshAfter) {
      setTimeout(refreshToken, tokenRefreshAfter * 1000);
    }
  },

  initialize(token, options, organizationUuids, successCallback) {
    if (!token) {
      return;
    }

    // relative or absolute path to API
    visualbyConfig.apiRoot = window.BIConfig?.baseUrl || options?.url;

    // relative or absolute path to directive html snippets
    visualbyConfig.visualbyRoot = "/visualby/";

    // allow or disallow animations for the graphs
    visualbyConfig.disableAnimations = true;

    // set language
    visualbyConfig.lang = document.documentElement.lang || "sv";

    // graph settings
    visualbyConfig.graphFontFamily = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--font-family-sans-serif");

    // enable/disable logging
    visualbyConfig.log = false;

    visualbyConfig.directiveRoot = "/resources/app/shared/";

    // Enable features for everyone by default
    wzGetController().setFeature("drill-down", true);
    wzGetController().setFeature("erp", true);
    wzGetController().setFeature("graph-title-v2", true);
    wzGetController().setFeature("organisation-child-state-editor", false);
    wzGetController().setFeature(
      "create-table-report",
      options?.policies?.create_report
    );

    if (typeof wzGetController().enableBetaFeatures === "function") {
      wzGetController().enableBetaFeatures(
        store.getters["platform/visualbyFlipperFlags"]
      );
    }

    // Initialize VisualBy
    wzGetController().init(
      token,
      () => {
        // Initialize the heartbeat
        this.initializeHeartbeatListener(this);

        // Initialize the Zendesk listener
        this.initializeZendeskListener(this);

        // Initialize the table report listener
        this.initializeTableReportListener(this);

        // Initialize the table pdf export listener
        this.initializeTablePdfListener(this);

        // Emit that we're ready to be used
        wzGetController().addGetConfigurationsCallback(
          (configurations, uuid) => {
            EventBus.emit("LOADED_CONFIGURATIONS");

            const adminPanelUuid =
              store.getters["platform/getAdminPanel"]?.financials_uuid;

            if (adminPanelUuid) {
              if (adminPanelUuid !== uuid) {
                return true;
              }
            } else {
              if (store.getters["company/activeFinancialsUuid"] != uuid) {
                return true;
              }
            }

            store.commit("company/setFinancials", {
              initializing: false,
              initialized: true,
            });

            // Execute the callback function for the initiator
            successCallback();
          }
        );
      },
      visualbyConfig.lang,
      organizationUuids
    );
  },

  initializeHeartbeatListener() {
    window.wzGetController().addCustomerInteractionListener(() => {
      axios.post("/sessions/extend");
    }, HEARTBEAT_DEBOUNCE_IN_SECONDS);
  },

  initializeZendeskListener() {
    window.addEventListener("tab-changed", (data) => {
      EventBus.emit("zendesk:set-help-center-suggestions", data.detail?.labels);
    });
  },

  initializeTableReportListener() {
    window.addEventListener("create-table-report", (data) => {
      EventBus.emit("create-table-report", data.detail);
    });
  },

  initializeTablePdfListener() {
    window.addEventListener("export-to-pdf", (data) => {
      EventBus.emit("export-table-pdf", data.detail);
    });
  },
};

export default BI;
