import { mapGetters } from "vuex";
import Config from "@/config";

import { EventBus } from "@/event-bus";

export default {
  computed: {
    ...mapGetters({
      $currentUser: "current_user/getUser",
      $currentCompany: "company/getCompany",
      $platform: "platform/getPlatform",
      $theme: "platform/getTheme",
      $currentAdminPanel: "platform/getAdminPanel",
      $currentMembership: "platform/getMembership",
      platformEnabled: "platform/enabled",
      platformEnabledSome: "platform/enabledSome",
      checkPolicy: "platform/checkPolicy",
      companyActiveFinancialsUuid: "company/activeFinancialsUuid", // Avoid missuse vs $activeFinancialsUuid which checks AdminPanel too
      hasActiveFeature: "company/hasActiveFeature",
      hasEnabledIntegration: "company/hasEnabledIntegration",
      hasInitializedFinancials: "company/hasInitializedFinancials",
      inCorporateGroup: "company/inCorporateGroup",

      // Deprectated - use $currentCompany instead
      company: "company/getCompany",
    }),

    platformAdmin() {
      return this.$currentMembership?.policy_level === "admin";
    },

    $csrfToken() {
      return document.head.querySelector('meta[name="csrf-token"]').content;
    },

    $config() {
      return Config;
    },

    $activeFinancialsUuid() {
      if (this.$currentAdminPanel?.financials_uuid) {
        return this.$currentAdminPanel.financials_uuid;
      } else {
        return this.companyActiveFinancialsUuid;
      }
    },

    hasIntegrationSubscriptions() {
      const integrations = this.$config.INTEGRATION_FEATURE_NAMES;
      return this.$currentCompany.subscriptions.some((subscription) =>
        integrations.includes(subscription.feature_name)
      );
    },
  },

  methods: {
    platformEnabledAndSubscribed(feature) {
      return this.platformEnabled(feature) && this.hasActiveFeature(feature);
    },

    flipperFlag(flag) {
      const flags = this.$platform?.flags || [];
      return flags.includes(flag);
    },

    platformFeature(feature) {
      const features = this.$platform?.features || {};
      return features[feature] || false;
    },

    waitForFinancials(callback) {
      if (typeof callback !== "function") {
        throw new Error("waitForFinancials requires a callback function");
      }

      if (this.hasInitializedFinancials) {
        callback();
      }

      EventBus.on("LOADED_BI", callback);
    },
  },
};
