import axios from "axios";
import { handleError } from "@/utils/error-handling";

export const state = {
  adminPanels: [],
  companies: [],
  pendingGroupOwnerships: [],

  isLoadingOrganizations: false,
  hasLoadedOrganizations: false,
};

export const getters = {
  getAdminPanels: (state) => state.adminPanels,
  getCompanies: (state) => state.companies,
  getPendingGroupOwnerships: (state) => state.pendingGroupOwnerships,

  isLoadingOrganizations: (state) => state.isLoadingOrganizations,
  hasLoadedOrganizations: (state) => state.hasLoadedOrganizations,
};

export const actions = {
  async loadSwitcher(context, params) {
    context.commit("setIsLoadingOrganizations", true);
    await context.dispatch("fetchPendingGroupOwnerships");
    await context.dispatch("fetchOrganizations", params);
    context.commit("setIsLoadingOrganizations", false);
  },

  async fetchPendingGroupOwnerships(context) {
    try {
      const { status, data } = await axios.get(
        "/users/switcher/pending_group_ownerships"
      );

      // Will return 204 no content if not onboarded yet
      if (status !== 204) {
        context.commit(
          "setPendingGroupOwnerships",
          data.pending_group_ownerships
        );
      }
    } catch (error) {
      handleError(error);
    }
  },

  async fetchOrganizations(context, params) {
    try {
      const { data } = await axios.get("/users/switcher", { params: params });

      context.commit("setAdminPanels", data.admin_panels);
      context.commit("setCompanies", data.companies);
      context.commit("setHasLoadedOrganizations", true);
    } catch (error) {
      handleError(error);
    }
  },
};

export const mutations = {
  setAdminPanels(state, adminPanels) {
    if (!adminPanels) {
      return;
    }

    state.adminPanels = adminPanels;
  },

  setCompanies(state, companies) {
    if (!companies) {
      return;
    }

    // Sort favorites first
    state.companies = [
      ...companies.filter((company) => company.is_favorite),
      ...companies.filter((company) => !company.is_favorite),
    ];
  },

  setPendingGroupOwnerships(state, pendingGroupOwnerships) {
    state.pendingGroupOwnerships = pendingGroupOwnerships;
  },

  setIsLoadingOrganizations(state, isLoadingOrganizations) {
    state.isLoadingOrganizations = isLoadingOrganizations;
  },

  setHasLoadedOrganizations(state, hasLoadedOrganizations) {
    state.hasLoadedOrganizations = hasLoadedOrganizations;
  },
};

export default {
  namespaced: true,
  sessionCached: true,
  state,
  getters,
  actions,
  mutations,
};
