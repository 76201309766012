import axios from "axios";
import { url } from "@/url-helpers";
import { handleError } from "@/utils/error-handling";

export const state = {
  currentMeetings: [],
  upcomingMeetings: [],
  hasLoadedMeetings: false,
  loadingMeetings: false,
};

export const getters = {
  currentMeetings: (state) => state.currentMeetings,
  upcomingMeetings: (state) => state.upcomingMeetings,
  hasLoadedMeetings: (state) => state.hasLoadedMeetings,
  loadingMeetings: (state) => state.loadingMeetings,

  meetingsCount: (state) =>
    state.currentMeetings.length + state.upcomingMeetings.length,
};

export const actions = {
  async fetchMeetings(context) {
    context.commit("setLoadingMeetings", true);

    try {
      const response = await axios.get(url("/upcoming_meetings"));
      context.commit("setHasLoadedMeetings", true);
      context.commit("setMeetings", response.data);
    } catch (error) {
      handleError(error);
    } finally {
      context.commit("setLoadingMeetings", false);
    }
  },
};

export const mutations = {
  setMeetings(state, meetings) {
    if (!meetings || !Array.isArray(meetings)) return;

    const now = new Date();
    const thirtyMinutes = 30 * 60 * 1000;

    state.currentMeetings = meetings.filter((meeting) => {
      const meetingStart = new Date(meeting.start_at);
      return (
        meetingStart.getTime() <= now.getTime() + thirtyMinutes &&
        meetingStart.getTime() >= now.getTime() - thirtyMinutes
      );
    });

    state.upcomingMeetings = meetings.filter((meeting) => {
      const meetingStart = new Date(meeting.start_at);
      return meetingStart.getTime() > now.getTime() + thirtyMinutes;
    });
  },

  setHasLoadedMeetings(state, value) {
    state.hasLoadedMeetings = value;
  },

  setLoadingMeetings(state, value) {
    state.loadingMeetings = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
