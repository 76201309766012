<template>
  <div
    v-if="!comment.deleted_at || displayDeleted"
    class="border border-top-0 border-right-0 border-bottom-1 border-left-0 py-3 px-4"
  >
    <div class="d-flex align-items-center mb-2">
      <user-avatar :user="comment.user_id" />

      <div class="d-flex justify-content-between w-100 ml-2">
        <div>
          <h6 class="my-0">{{ comment.user_name }}</h6>

          <small v-if="comment.created_at">
            {{ $d(new Date(comment.created_at), "dateTime") }}
          </small>
        </div>

        <be-dropdown
          v-if="
            comment.id &&
            comment.policy &&
            comment.policy.update &&
            comment.policy.allow_edit_and_delete &&
            !readonly &&
            !hideActionButtons
          "
          :id="`comment-dropdown-${comment.id}`"
          size="sm"
          ellipsis
        >
          <be-dropdown-item @click="toggleEditArea">
            {{ $t("buttons.titles.edit") }}
          </be-dropdown-item>

          <be-dropdown-divider />

          <be-dropdown-item variant="danger" @click="removeComment">
            {{ $t("buttons.titles.destroy") }}
          </be-dropdown-item>
        </be-dropdown>
      </div>
    </div>

    <div class="media-body">
      <div
        v-if="
          comment.policy &&
          comment.policy.update &&
          comment.policy.allow_edit_and_delete &&
          !readonly &&
          open
        "
        class="comment-form form-clean"
      >
        <be-form-group :error="getErrors(comment, 'body')">
          <be-form-textarea
            v-model="comment.body"
            :state="validationState(comment, 'body')"
            @change="clearErrors(comment, 'body')"
          />
        </be-form-group>

        <be-button
          variant="link"
          size="sm"
          class="d-none d-md-inline-block"
          @click="cancelEdit"
        >
          {{ $t("buttons.titles.cancel") }}
        </be-button>

        <be-button
          variant="light"
          size="sm"
          class="d-md-none"
          block
          @click="cancelEdit"
        >
          {{ $t("buttons.titles.cancel") }}
        </be-button>

        <be-button
          variant="primary"
          size="sm"
          @click="$emit('update', comment)"
        >
          {{ $t("buttons.titles.save") }}
        </be-button>
      </div>

      <div v-if="!open">
        <div v-dompurify-html="comment.body" class="text-pre-wrap d-inline" />

        <template v-if="comment.updated_at != comment.created_at">
          <div class="d-block d-md-inline">
            <small v-be-tooltip="formattedUpdatedAt" class="text-muted">
              ({{ $t("components.shared.comments.edited") }})
            </small>
          </div>
        </template>
      </div>
    </div>
  </div>

  <div
    v-else
    class="border border-top-0 border-right-0 border-bottom-1 border-left-0 py-3 px-4"
  >
    <div class="d-flex align-items-center mb-2">
      <user-avatar :user="comment.user_id" />

      <div class="ml-2">
        <h6 class="my-0">{{ comment.user_name }}</h6>

        <small v-if="comment.created_at">
          {{ $d(new Date(comment.created_at), "dateTime") }}
        </small>
      </div>
    </div>

    <p class="text-muted">
      <em>{{
        $t("shared.comments.comment.deleted", {
          deleted_at: $d(new Date(comment.deleted_at), "date"),
        })
      }}</em>
    </p>
  </div>
</template>

<script>
import TextUtilities from "@/mixins/textUtilities";

export default {
  mixins: [TextUtilities],

  props: {
    item: {
      type: Object,
      required: true,
    },

    displayDeleted: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideActionButtons: {
      type: Boolean,
      required: false,
      default: false,
    },

    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["update", "remove"],

  data() {
    return {
      comment: this.cloneDeep(this.item),
      open: false,
    };
  },

  computed: {
    formattedUpdatedAt() {
      return this.$i18n.t("components.shared.comments.edited_at_w_time", {
        time: this.$i18n.d(new Date(this.comment.updated_at), "dateTime"),
      });
    },
  },

  watch: {
    item(comment) {
      this.comment = this.cloneDeep(comment);
      if (Object.keys(this.comment.errors || {}).length > 0) {
        this.open = true;
      } else {
        this.open = false;
      }
    },
  },

  methods: {
    async removeComment() {
      const isConfirmed = await this.promptRemovalConfirm({
        title: this.$t("nav.confirm_delete_w_title", {
          title: this.truncateText(this.comment.body, 20),
        }),

        description: this.$t("nav.owner_description_w_created_at_w_owner", {
          created_at: this.$d(new Date(this.comment.created_at), "dateTime"),
          owner: this.comment.user_name,
        }),
      });

      if (isConfirmed) {
        this.$emit("remove", this.comment);
      }
    },

    cancelEdit() {
      this.open = !this.open;
      this.comment.body = this.item.body;
    },

    toggleEditArea() {
      this.open = !this.open;
    },
  },
};
</script>
