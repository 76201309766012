export default {
  props: {
    state: {
      type: Boolean,
      required: false,
      default: null,

      validator(value) {
        return value === null || typeof value === "boolean";
      },
    },
  },

  computed: {
    computedState() {
      const isGroup = this.getInstanceProperty("isGroup");
      const beGroup = this.getInstanceProperty("beGroup");
      const error = this.getInstanceProperty("error");

      const groupStateValid = isGroup && beGroup?.computedState !== null;
      const stateIsBoolean = typeof this.state === "boolean";

      if (groupStateValid) {
        return beGroup.computedState;
      } else if (error) {
        return false;
      } else if (stateIsBoolean) {
        return this.state;
      } else {
        return null;
      }
    },

    stateClass() {
      const state = this.computedState;

      if (state === true) {
        return "is-valid";
      } else if (state === false) {
        return "is-invalid";
      } else {
        return null;
      }
    },

    computedAriaInvalid() {
      const ariaInvalid = this.getInstanceProperty("ariaInvalid");
      const state = this.computedState;

      if (typeof ariaInvalid === "boolean") {
        return ariaInvalid;
      }

      if (typeof state === "boolean") {
        return state;
      }

      return null;
    },
  },

  methods: {
    getInstanceProperty(propertyName) {
      return propertyName in this ? this[propertyName] : null;
    },
  },
};
