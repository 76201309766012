import axios from "axios";
import { handleError } from "@/utils/error-handling";

export const state = {
  readings: [],
};

export const getters = {
  readingsForReference: (state) => (referenceType, referenceId) => {
    return state.readings.filter(
      (reading) =>
        reading.reference_type === referenceType &&
        reading.reference_id === referenceId
    );
  },
};

export const actions = {
  async fetchReadings({ commit }, resource) {
    if (!resource?.paths?.readings) {
      throw new Error("Resource does not have a readings path");
    }

    try {
      const { data } = await axios.get(resource.paths.readings);

      data.forEach((reading) => {
        commit("updateReading", reading);
      });
    } catch (error) {
      handleError(error);
    }
  },

  async createReading({ commit }, resource) {
    if (!resource?.paths?.readings) {
      throw new Error("Resource does not have a reading path");
    }

    try {
      const { data } = await axios.post(resource.paths.readings);

      commit("updateReading", data);
      return data;
    } catch (error) {
      handleError(error);
    }
  },
};

export const mutations = {
  updateReadings(state, readings) {
    readings.forEach((reading) => {
      const index = state.readings.findIndex((r) => r.id === reading.id);

      if (index === -1) {
        state.readings.push(reading);
      } else {
        state.readings[index] = reading;
      }
    });
  },

  updateReading(state, reading) {
    const index = state.readings.findIndex((r) => r.id === reading.id);

    if (index === -1) {
      state.readings.push(reading);
    } else {
      state.readings[index] = reading;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
