// Default modal backdrop z-index
const DEFAULT_ZINDEX = 1040; // We should probably expose this as a CSS root variable and fetch it here

export const state = {
  modals: [],
  baseZIndex: null,
  scrollbarWidth: null,
  isBodyOverflowing: false,
};

export const getters = {
  modals: (state) => state.modals,
  baseZIndex: (state) => state.baseZIndex,
  scrollbarWidth: (state) => state.scrollbarWidth,
  isBodyOverflowing: (state) => state.isBodyOverflowing,

  modalCount: (state) => state.modals.length,
  modalsAreOpen: (state, getters) => getters.modalCount > 0,
};

export const mutations = {
  setBaseZIndex(state, zIndex) {
    if (zIndex) {
      state.baseZIndex = zIndex;
    } else {
      state.baseZIndex = helpers.calculateBaseZIndex();
    }
  },

  setScrollbarWidth(state, width) {
    if (width) {
      state.scrollbarWidth = width;
    } else {
      state.scrollbarWidth = helpers.calculateScrollbarWidth();
    }
  },

  checkScrollbar(state) {
    // Determine if the body element is overflowing
    const { left, right } = document.body.getBoundingClientRect();
    state.isBodyOverflowing = left + right < window.innerWidth;
  },

  registerModal(state, modal) {
    if (modal && !state.modals.find((m) => m.id === modal.id)) {
      state.modals.push(modal);
    }
  },

  unregisterModal(state, modal) {
    state.modals = state.modals.filter((m) => m.id !== modal.id);
  },
};

export const helpers = {
  calculateBaseZIndex() {
    // Create a temporary div to calculate the z-index of the modal backdrop
    const tempDiv = document.createElement("div");
    tempDiv.className = "modal-backdrop d-none";
    tempDiv.style.display = "none";
    document.body.appendChild(tempDiv);
    const zIndex = parseInt(
      window.getComputedStyle(tempDiv).zIndex || DEFAULT_ZINDEX,
      10
    );
    document.body.removeChild(tempDiv);

    return zIndex;
  },

  calculateScrollbarWidth() {
    // Create a temporary div to measure the scrollbar width
    const tempDiv = document.createElement("div");
    tempDiv.className = "modal-scrollbar-measure";
    document.body.appendChild(tempDiv);
    const scrollbarWidth =
      tempDiv.getBoundingClientRect().width - tempDiv.clientWidth;
    document.body.removeChild(tempDiv);

    return scrollbarWidth;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
