import axios from "axios";
import { url, pathContext } from "@/url-helpers";

export const state = {
  tree: {},
  year_options: [],
  parent_options: [],
  ownership_states: [],
  quick_links: {},
  show_shares: true,
  can_edit: false,
};

const loadingStub = {
  loading: true,
};

const groupUrl =
  pathContext().type === "admin_panel"
    ? url("/companies/group")
    : url("/group");

export const actions = {
  initialize({ dispatch, commit }) {
    const groupTree = window.bootstrapData?.corporate_group_tree;
    if (groupTree) {
      commit("setData", groupTree);
      dispatch("setQueryParamsFromData", groupTree);
    }
  },

  async changeYear({ dispatch }, year) {
    dispatch("setQueryParam", { key: "year", value: year });
    dispatch("loadTree");
  },

  async changeParent({ dispatch }, nanoid) {
    dispatch("setQueryParam", { key: "parent", value: nanoid });
    dispatch("loadTree");
  },

  async changeLevels({ dispatch }, levels) {
    dispatch("setQueryParam", { key: "treelevels", value: levels });
    dispatch("updateSession");
  },

  async toggleEditing({ dispatch }) {
    dispatch("toggleQueryParam", "editing");
    dispatch("updateSession");
  },

  async toggleShares({ dispatch }) {
    dispatch("toggleQueryParam", "show_shares");
    dispatch("updateSession");
  },

  // Fetch new data after changing filters.
  async loadTree({ commit, dispatch, rootGetters }) {
    const getParam = rootGetters["request_query/getParam"];
    const parent = getParam("parent") || state.parent_options[0];
    const year = getParam("year") || new Date().getFullYear().toString();
    const show_shares = getParam("show_shares");
    const treelevels = getParam("treelevels");

    commit("setLoading");

    const { data } = await axios.get(groupUrl, {
      params: { parent, year, show_shares, treelevels },
    });
    commit("setData", data);
    dispatch("setQueryParamsFromData", data);
  },

  // Just inform the server that we changed the filters.
  // (But don't need any new data)
  async updateSession({ rootGetters }) {
    const getParam = rootGetters["request_query/getParam"];
    const parent = getParam("parent") || state.parent_options[0];
    const year = getParam("year") || new Date().getFullYear().toString();
    const show_shares = getParam("show_shares");
    const treelevels = getParam("treelevels");

    await axios.head(groupUrl, {
      params: { parent, year, show_shares, treelevels },
    });
  },

  setQueryParamsFromData({ dispatch }, data) {
    const { selected_parent, selected_year, show_shares, treelevels } = data;
    dispatch("setQueryParam", { key: "year", value: selected_year.toString() });
    dispatch("setQueryParam", { key: "parent", value: selected_parent });
    dispatch("setQueryParam", { key: "treelevels", value: treelevels });
    if (show_shares) {
      dispatch("setQueryParam", { key: "show_shares", value: true });
    }
  },

  setQueryParam({ dispatch }, data) {
    dispatch("request_query/setParam", data, { root: true });
  },

  toggleQueryParam({ dispatch }, key) {
    dispatch("request_query/toggleParam", key, { root: true });
  },
};

export const mutations = {
  setData(state, data) {
    state.tree = data.tree;
    state.parent_options = data.parent_options;
    state.year_options = data.year_options;
    state.ownership_states = data.ownership_states;
    state.quick_links = data.quick_links;
    state.show_shares = data.show_shares === "true";
    state.can_edit = data.tree.can_edit;
  },

  setLoading(state) {
    state.tree = loadingStub;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
