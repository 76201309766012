import { EventBus } from "@/event-bus";
import { handleError } from "./utils/error-handling";

export const handleFlashes = (flashes) => {
  if (!flashes) return;

  flashes = JSON.parse(decodeURIComponent(flashes));

  for (let key of Object.keys(flashes)) {
    let messages = flashes[key];
    if (!Array.isArray(messages)) {
      messages = [messages];
    }

    messages.forEach((message) => {
      EventBus.emit("new-notification", {
        message: message,
        status: key,
      });
    });
  }
};

export const axiosFlashInterceptor = (response) => {
  if (!response) return;

  let flashes = response.headers["x-ajax-flashes"];

  try {
    handleFlashes(flashes);
  } catch (error) {
    handleError(error, { sentryLog: false });
  }
};
