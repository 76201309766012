import { EventBus } from "@/event-bus";

function focusElement(el, binding) {
  if (binding.value !== undefined && !binding.value) return;

  // If the element is inside a modal, we need to wait for the modal to be
  // shown before we can focus the element. If the element is not inside a
  // modal, we can focus it immediately.
  const parentModal = el.closest(".modal");
  if (parentModal) {
    EventBus.on("be::modal::shown", (beEvent, modalId) => {
      if (modalId !== parentModal.id) return;
      el.focus();
    });
  } else {
    el.focus();
  }
}

export default {
  mounted: focusElement,
};
