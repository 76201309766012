<template>
  <div v-if="!useMarkup" class="mb-2">
    <em>
      {{ activityString }}
    </em>
  </div>

  <div v-else>
    <div class="media px-2">
      <div v-if="activity.owner_id" class="d-inline-flex mr-2">
        <user-avatar :user="activity.owner_id" :disable-tooltip="true" />
      </div>

      <div class="media-body">
        <h6 class="my-0">
          {{
            $t(
              `public_activity_beta.long.${activity.trackable_type.toLowerCase()}.title`
            )
          }}
        </h6>

        <div class="small">
          <em>
            <span v-if="preText">{{ preText }}</span>
            {{ activityString }}
            <template v-if="includeDate">
              {{ $t("components.activity.at") }}
              {{ createdAt }}
            </template>

            <template v-if="includeOwner && activity.owner_name">
              {{ $t("components.activity.by") }}
              {{ activity.owner_name }}
            </template>
          </em>
        </div>
      </div>
    </div>

    <hr class="mx-0" />
  </div>
</template>
<script>
export default {
  props: {
    activity: {
      type: Object,
      required: true,
    },

    useMarkup: {
      type: Boolean,
      required: false,
      default: false,
    },

    preText: {
      type: String,
      required: false,
      default: "",
    },

    includeOwner: {
      type: Boolean,
      required: false,
      default: false,
    },

    includeDate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    activityString() {
      let data = {
        date: this.$i18n.d(new Date(this.activity.created_at), "short"),
        user: this.activity.owner_name,
      };

      if (this.activity.recipient) {
        data["recipient"] = this.activity.recipient.name;
      }

      if (this.activity?.trackable?.start_at) {
        data["trackable_date"] = this.$i18n.d(
          new Date(this.activity.trackable.start_at),
          "short"
        ); // , format: 'short'
      }

      // TODO: Scope: 'public_activity.long')
      return this.$i18n.t(
        "public_activity_beta.long." + this.activity.key,
        data
      );
    },

    createdAt() {
      if (this.activity.created_at) {
        return this.$i18n.d(new Date(this.activity.created_at), "dateTime");
      }

      return "";
    },
  },
};
</script>
