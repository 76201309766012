<template>
  <be-avatar
    v-be-tooltip="{
      title: name,
      disabled: disableTooltip || !name,
    }"
    :text="initials"
    :src="image"
    :size="size"
    :style="{
      'background-color': backgroundColor,
      'font-weight': 'bold',
    }"
    :variant="variant"
  />
</template>

<script>
import { mapGetters } from "vuex";

// Need to include the component to work with Inertia and PDF
import BeAvatar from "@/components/shared/BeAvatar.vue";

const BACKGROUND_COLORS = [
  "#d93068",
  "#64396d",
  "#63425E",
  "#4653a5",
  "#1878A5",
  "#38808a",
  "#037c70",
  "#38803A",
  "#656369",
  "#777481",
];

export default {
  name: "UserAvatar",

  components: {
    BeAvatar,
  },

  props: {
    user: {
      type: [Object, String, Number],
      required: false,
      default: () => null,
    },

    avatar: {
      type: Object,
      required: false,

      default: () => ({
        name: "",
        image_url: null,
      }),
    },

    size: {
      type: [Number, String],
      required: false,
      default: null,
    },

    disableTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },

    variant: {
      type: String,
      required: false,
      default: "secondary",
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "current_user/getUser",
    }),

    name() {
      return this.localAvatar.name;
    },

    initials() {
      let parts = this.name.split(/[ -]/);
      let initials = "";

      for (var i = 0; i < parts.length; i++) {
        initials += parts[i].charAt(0);
      }

      if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, "");
      }

      initials = initials.substr(0, 3).toUpperCase();

      return initials;
    },

    image() {
      return this.localAvatar.image_url;
    },

    hasImage() {
      return !!this.image;
    },

    backgroundColor() {
      if (this.hasImage) {
        return;
      }

      return this.randomBackgroundColor(this.name.length, BACKGROUND_COLORS);
    },

    localAvatar: {
      get() {
        if (this.user == null) {
          return this.avatar;
        }

        if (typeof this.user == "number") {
          const userId = this.user;
          const user =
            userId == this.currentUser?.id
              ? this.currentUser
              : this.getUser(userId);

          return this.userToAvatar(user) || this.avatar;
        }

        if (typeof this.user == "string") {
          return {
            ...this.avatar,
            name: this.user,
          };
        }

        if (typeof this.user == "object") {
          if (this.user.id == this.currentUser?.id) {
            return this.userToAvatar(this.currentUser);
          }
          return this.user;
        }

        return this.avatar;
      },
    },
  },

  methods: {
    userToAvatar(user) {
      if (user) {
        return {
          name: user.name,

          image_url:
            user.image_attachment?.thumbnail_url || user.image_attachment?.url,
        };
      }
    },

    randomBackgroundColor(seed, colors) {
      return colors[seed % colors.length];
    },
  },
};
</script>
