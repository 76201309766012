export default {
  methods: {
    getUserName(userId) {
      return this.getUser(userId)?.name;
    },

    getUserEmail(user_id) {
      return this.getUser(user_id)?.email;
    },

    getUser(userId) {
      if (!userId) {
        return null;
      }

      if (this.$currentAdminPanel?.id) {
        return this.$store.getters["memberships/getUser"](userId);
      } else {
        return this.$store.getters["company/userById"](userId);
      }
    },

    getMembershipByUserId(userId) {
      return this.$store.getters["company/membershipByUserId"](userId);
    },
  },
};
