<template>
  <img
    :class="[
      computedRounded,
      {
        'img-fluid': fluid || fluidGrow,
        'w-100': fluidGrow,
        'img-thumbnail': thumbnail,
        'd-block': block,
        'mx-auto': center,
      },
    ]"
    :src="src"
    :width="width"
    :height="height"
    :alt="computedAlt"
  />
</template>

<script>
export default {
  name: "BeImg",

  props: {
    alt: {
      type: String,
      required: false,
      default: null,
    },

    block: {
      type: Boolean,
      required: false,
      default: false,
    },

    center: {
      type: Boolean,
      required: false,
      default: false,
    },

    fluid: {
      type: Boolean,
      required: false,
      default: true,
    },

    fluidGrow: {
      type: Boolean,
      required: false,
      default: false,
    },

    height: {
      type: [String, Number],
      required: false,
      default: null,
    },

    rounded: {
      type: [Boolean, String],
      required: false,
      default: false,
    },

    src: {
      type: String,
      required: true,
    },

    thumbnail: {
      type: Boolean,
      required: false,
      default: false,
    },

    width: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },

  computed: {
    computedAlt() {
      // If `alt` is not provided, use the filename as alt text but modify it according to the following rules:
      // 1. Remove the file extension
      // 2. Remove everything after a dash followed by a generated string (e.g. "-06204408d3188db5656a")
      // 3. Replace underscores and dashes with spaces
      // 4. Capitalize the first letter of each word
      // 5. Remove trailing digits
      // Examples:
      // - "year_plan.png" => "Year Plan"
      // - "year_plan-01.png" => "Year Plan"
      // - "year_plan-01-01.png" => "Year Plan"
      // - "year_plan-06204408d3188db5656a.png" => "Year Plan"
      if (!this.alt) {
        if (!this.src) {
          return "";
        }

        return this.src
          .split("/")
          .pop()
          .replace(/\.[^/.]+$/, "")
          .replace(/-[\w\d]+$/, "")
          .replace(/[_-]/g, " ")
          .replace(
            /\w\S*/g,
            (word) =>
              word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
          )
          .replace(/\d+$/, "")
          .trim();
      }

      return this.alt;
    },

    computedRounded() {
      const { rounded } = this;

      if (rounded === true || rounded === "") {
        return "rounded";
      } else if (!rounded) {
        return "";
      } else {
        return `rounded-${rounded}`;
      }
    },
  },
};
</script>
