export const KEY_CODE_BACKSPACE = 8;
export const KEY_CODE_DELETE = 46;
export const KEY_CODE_DOWN = 40;
export const KEY_CODE_END = 35;
export const KEY_CODE_ENTER = 13;
export const KEY_CODE_ESCAPE = 27;
export const KEY_CODE_HOME = 36;
export const KEY_CODE_LEFT = 37;
export const KEY_CODE_RIGHT = 39;
export const KEY_CODE_SPACE = 32;
export const KEY_CODE_TAB = 9;
export const KEY_CODE_UP = 38;
