import axios from "axios";
import { identifierMatch } from "@/components/shared/functions";
import { url } from "@/url-helpers";
import uuid from "@/mixins/uuid/index";

export const state = {
  evaluationForm: null,
  invitations: [],
};

export const getters = {
  getEvaluationForm: (state) => state.evaluationForm,
  getInvitations: (state) => state.invitations,
  getSections: (state) => state.evaluationForm?.evaluation_sections,

  getSectionById: (state) => (sectionId) => {
    return state.evaluationForm.evaluation_sections.find((localSection) => {
      return identifierMatch(localSection, sectionId);
    });
  },

  getQuestionsForSection: (state, getters) => (section) => {
    const foundSection = getters.getSectionById(section.id || section.uuid);

    return foundSection.evaluation_questions;
  },

  getQuestionById: (state, getters) => (payload) => {
    const section = getters.getSectionById(payload.sectionId);

    return section.evaluation_questions.find((localQuestion) => {
      return identifierMatch(localQuestion, payload.questionId);
    });
  },

  getPossibleAnswerById: (state, getters) => (payload) => {
    const question = getters.getQuestionById({
      sectionId: payload.sectionId,
      questionId: payload.questionId,
    });

    return question.possible_answers.find((possibleAnswer) =>
      identifierMatch(possibleAnswer, payload.possibleAnswerId)
    );
  },
};

export const actions = {
  async loadEvaluationForm(context) {
    let evaluationForm = context.getters.getEvaluationForm;

    let response = await axios.get(
      url(`/evaluation_forms/${evaluationForm.id}`)
    );
    context.commit("setEvaluationForm", response.data);
  },

  setEvaluationForm(context, evaluationForm) {
    // Sort sections and questions so that position is in the right order
    if (evaluationForm.evaluation_sections) {
      evaluationForm.evaluation_sections.sort((a, b) =>
        a.position >= b.position ? 1 : -1
      );

      evaluationForm.evaluation_sections.forEach((section) => {
        section.evaluation_questions.sort((a, b) =>
          a.section_position >= b.section_position ? 1 : -1
        );
      });
    }

    context.commit("setEvaluationForm", evaluationForm);
  },

  removeSection(context, sectionId) {
    const section = context.getters.getSectionById(sectionId);
    context.commit("removeSection", section);
  },

  addSection({ commit }, section) {
    commit("addSection", section);
  },

  addQuestion(context, payload) {
    payload.section = context.getters.getSectionById(payload.sectionId);
    context.commit("addQuestion", payload);
  },

  addPossibleAnswer(context, payload) {
    payload.section = context.getters.getSectionById(payload.sectionId);
    payload.question = context.getters.getQuestionById(payload);

    context.commit("addPossibleAnswer", payload);
  },

  updateForm(context, payload) {
    const evaluationForm = context.getters.getEvaluationForm;

    if (evaluationForm) {
      context.commit("updateForm", { ...payload, evaluationForm });
    }
  },

  updateSection(context, payload) {
    const section = context.getters.getSectionById(payload.sectionId);
    if (section) {
      context.commit("updateSection", { ...payload, section });
    }
  },

  updateSections({ commit }, sections) {
    commit("updateSections", sections);
  },

  updateQuestion(context, payload) {
    const question = context.getters.getQuestionById(payload);

    if (question) {
      context.commit("updateQuestion", { ...payload, question });
    }
  },

  updateQuestions(context, payload) {
    payload.section = context.getters.getSectionById(payload.sectionId);
    context.commit("updateQuestions", payload);
  },

  updateUuids({ commit }) {
    commit("updateUuids");
  },

  updateSectionPositions({ commit }) {
    commit("updateSectionPositions");
  },

  updateQuestionPositions(context, payload) {
    payload.section = context.getters.getSectionById(payload.sectionId);
    context.commit("updateQuestionPositions", payload);
  },

  updatePossibleAnswersPositions(context, payload) {
    payload.section = context.getters.getSectionById(payload.sectionId);
    context.commit("updatePossibleAnswersPositions", payload);
  },

  updatePossibleAnswer(context, payload) {
    const possibleAnswer = context.getters.getPossibleAnswerById(payload);

    if (possibleAnswer) {
      context.commit("updatePossibleAnswer", { ...payload, possibleAnswer });
    }
  },

  /**
   * Invitations
   */
  async loadInvitations(context, reload = false) {
    let evaluationForm = context.getters.getEvaluationForm;
    let load = reload || !context.getters.loadedInvitations;

    if (!load) {
      return;
    }

    let invitations_url = url("/invitations");

    if (!!evaluationForm && !!evaluationForm.id) {
      if (evaluationForm.evaluation_report) {
        invitations_url = url(
          `/evaluation_forms/${evaluationForm.id}/report_invitations`
        );
      } else {
        invitations_url = url(
          `/evaluation_forms/${evaluationForm.id}/invitations`
        );
      }
    }

    let response = await axios.get(invitations_url);
    context.commit("setInvitations", response.data);
  },

  async inviteUser(context, parameters) {
    let evaluationForm = context.getters.getEvaluationForm;

    if (!!evaluationForm && !!evaluationForm.id) {
      let invitations_url = null;

      if (evaluationForm.evaluation_report) {
        invitations_url = url(
          `/evaluation_forms/${evaluationForm.id}/report_invitations`
        );
      } else {
        invitations_url = url(
          `/evaluation_forms/${evaluationForm.id}/invitations`
        );
      }

      if (invitations_url) {
        let response = await axios.post(invitations_url, {
          invitation: {
            user_id: parameters.user_id,
            message: parameters.message,
          },
        });

        context.commit("updateInvitation", response.data);
      }
    }
  },

  setInvitations(context, invitations) {
    context.commit("setInvitations", invitations);
  },
};

export const mutations = {
  setEvaluationForm(state, evaluationForm) {
    state.evaluationForm = evaluationForm;
  },

  removeSection(state, section) {
    if (section) {
      section["_destroy"] = 1;

      section.evaluation_questions.forEach((question) => {
        question["_destroy"] = 1;

        question.possible_answers.forEach((possibleAnswer) => {
          possibleAnswer["_destroy"] = 1;
        });
      });
    }
  },

  addSection(state, section) {
    state.evaluationForm.evaluation_sections.push(section);
  },

  addQuestion(state, payload) {
    payload.section.evaluation_questions.push(payload.question);
  },

  addPossibleAnswer(state, payload) {
    if (payload.question) {
      payload.question.possible_answers.push(payload.possibleAnswer);
    }
  },

  updateForm(state, payload) {
    Object.entries(payload.data).forEach((data) => {
      payload.evaluationForm[data[0]] = data[1];
    });
  },

  updateSection(state, payload) {
    Object.entries(payload.data).forEach((data) => {
      payload.section[data[0]] = data[1];
    });
  },

  updateSections(state, sections) {
    state.evaluationForm.evaluation_sections = sections;
  },

  updateQuestion(state, payload) {
    Object.entries(payload.data).forEach((data) => {
      payload.question[data[0]] = data[1];
    });
  },

  updateQuestions(state, payload) {
    payload.section.evaluation_questions = payload.questions;
  },

  updatePossibleAnswer(state, payload) {
    Object.entries(payload.data).forEach((data) => {
      payload.possibleAnswer[data[0]] = data[1];
    });
  },

  updateUuids(state) {
    const sections = state.evaluationForm.evaluation_sections.filter(
      (section) => section._destroy != 1
    );

    sections.forEach((section) => {
      if (!section.id) {
        section.uuid = uuid.methods.generateUuid();
      }

      section.evaluation_questions.forEach((question) => {
        if (!question.id) {
          question.uuid = uuid.methods.generateUuid();
        }

        question.possible_answers.forEach((possibleAnswer) => {
          if (!possibleAnswer.id) {
            possibleAnswer.uuid = uuid.methods.generateUuid();
          }
        });
      });
    });
  },

  updateSectionPositions(state) {
    let activeSections = state.evaluationForm.evaluation_sections.filter(
      (section) => section._destroy != 1
    );

    activeSections.forEach((section) => {
      let idx = activeSections.findIndex((comparativeSection) =>
        comparativeSection.id
          ? comparativeSection.id == section.id
          : comparativeSection.uuid == section.uuid
      );

      activeSections[idx]["position"] = idx + 1;
    });
  },

  updateQuestionPositions(state, payload) {
    const activeQuestions = payload.section.evaluation_questions.filter(
      (question) => question._destroy != 1
    );

    activeQuestions.forEach((question) => {
      let idx = activeQuestions.findIndex((comparativeQuestion) =>
        comparativeQuestion.id
          ? comparativeQuestion.id == question.id
          : comparativeQuestion.uuid == question.uuid
      );

      activeQuestions[idx]["section_position"] = idx + 1;
    });
  },

  updatePossibleAnswersPositions(state, payload) {
    const question = payload.section.evaluation_questions.find(
      (localQuestion) => {
        return identifierMatch(localQuestion, payload.questionId);
      }
    );

    const activePossibleAnswers = question.possible_answers.filter(
      (possibleAnswer) => {
        return possibleAnswer._destroy != 1;
      }
    );

    activePossibleAnswers.forEach((possibleAnswer) => {
      let idx = activePossibleAnswers.findIndex((comparativePossibleAnswer) =>
        comparativePossibleAnswer.id
          ? comparativePossibleAnswer.id == possibleAnswer.id
          : comparativePossibleAnswer.uuid == possibleAnswer.uuid
      );

      activePossibleAnswers[idx]["position"] = idx + 1;
    });
  },

  /**
   * Invitations
   */
  setInvitations(state, invitations) {
    state.invitations = invitations;
    state.loadedInvitations = true;
  },

  updateInvitation(state, invitation) {
    let index = state.invitations.findIndex(
      (existing) => existing.user_id == invitation.user_id
    );

    if (index === -1) {
      state.invitations.push(invitation);
    } else {
      state.invitations[index] = invitation;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
