<template>
  <comments-list
    :commentable-object="document"
    :comments="comments"
    :loading="loading"
    @comment-added="handleCommentAdded"
    @comment-updated="updateComment"
  />
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    document: {
      type: Object,
      required: true,
    },

    comments: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  emits: ["comment-added"],

  methods: {
    ...mapMutations({
      addComment: "comments/ADD_COMMENT",
      updateComment: "comments/UPDATE_COMMENT",
    }),

    handleCommentAdded(comment) {
      this.addComment(comment);
      this.$emit("comment-added", comment);
    },
  },
};
</script>
