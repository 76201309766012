import { differenceInMinutes } from "date-fns";
import orderBy from "lodash/orderBy";

const GROUP_DIFFERENCE_IN_MINUTES = 60 * 6; // 6 hours

export const state = {
  activities: [],
  mode: "filtered",
  group_difference_in_minutes: GROUP_DIFFERENCE_IN_MINUTES,
};

export const getters = {
  activities: (state) => state.activities,
  getMode: (state) => state.mode,

  getFilteredActivities: (state) => {
    let activities = orderBy(state.activities, "created_at", "desc");
    if (state.mode !== "filtered") {
      return activities;
    }

    return activities.filter((activity, index) => {
      // Always show the most recent activity
      if (!index) {
        return true;
      }

      const previousActivity = activities[index - 1];

      // Allow activities that are different from each other
      if (previousActivity.key != activity.key) {
        return false;
      }

      // Allow activities triggered by different owners
      if (
        previousActivity.owner_id != activity.owner_id &&
        previousActivity.owner_type != activity.owner_type
      ) {
        return false;
      }

      const currentDate = new Date(activity.created_at);
      const previousDate = new Date(previousActivity.created_at);

      return (
        differenceInMinutes(previousDate, currentDate) >
        state.group_difference_in_minutes
      );
    });
  },
};

export const actions = {
  setActivities(context, activities) {
    context.commit("setActivities", activities);
  },

  addActivity(context, activity) {
    context.commit("addActivity", activity);
  },
};

export const mutations = {
  setActivities(state, activities) {
    state.activities = activities;
  },

  toggleMode(state) {
    state.mode = state.mode === "filtered" ? "all" : "filtered";
  },

  addActivity(state, activity) {
    activity.type = "Activity";
    state.activities.push(activity);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
