/*
  Mixin for forms checks if errors present in a form, return these errors and
  clean these errors when the user inputs a new value
*/
export default {
  methods: {
    validationState(errorObject, attributes) {
      if (!Array.isArray(attributes)) {
        attributes = [attributes];
      }

      // Validation state should in simplest form return:
      // * false when there are error messages for the attribute,
      // * null when no error messages are found
      if (!errorObject || !errorObject.errors) {
        return null;
      }

      for (var attribute of attributes) {
        let error = errorObject.errors[attribute];
        if (error) {
          return false; //There is an error message, validation state returns false
        }
      }

      return null; //There is no error message, validation state returns undefined
    },

    getErrors(errorObject, attributes) {
      if (!errorObject || !errorObject.errors) {
        return null;
      }

      if (!Array.isArray(attributes)) {
        attributes = [attributes];
      }

      let errors = "";
      for (var attribute of attributes) {
        if (errorObject.errors) {
          let error = errorObject.errors[attribute];
          if (error) {
            errors += error;
          }
        }
      }

      if (errors) {
        return errors;
      }

      return null;
    },

    clearErrors(errorObject, attributes) {
      if (!Array.isArray(attributes)) {
        attributes = [attributes];
      }

      if (errorObject && errorObject.errors) {
        for (var attribute of attributes) {
          delete errorObject.errors[attribute];
        }
      }
    },
  },
};
