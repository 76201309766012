<template>
  <be-aspect-ratio v-if="!noAspectRatio" :aspect-ratio="aspectRatio">
    <be-skeleton
      :animation="animation"
      :size="size"
      :height="height"
      :width="width"
      :variant="variant"
    />
  </be-aspect-ratio>

  <be-skeleton
    v-else
    :animation="animation"
    :size="size"
    :height="height"
    :width="width"
    :variant="variant"
  />
</template>

<script>
export default {
  name: "BeSkeletonGraph",

  props: {
    animation: {
      type: String,
      required: false,
      default: undefined,
    },

    aspectRatio: {
      type: String,
      required: false,
      default: "4:3",
    },

    height: {
      type: String,
      required: false,
      default: undefined,
    },

    noAspectRatio: {
      type: Boolean,
      required: false,
      default: false,
    },

    size: {
      type: String,
      required: false,
      default: "100%",
    },

    variant: {
      type: String,
      required: false,
      default: undefined,
    },

    width: {
      type: String,
      required: false,
      default: undefined,
    },
  },
};
</script>
