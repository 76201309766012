import extensions from "./extensions";
import icons from "./icons";

/**
 * @param {string} extension
 * @returns {string}
 */
export function getClassNameForExtension(extension) {
  return extensions[extension.toLowerCase()] || icons.file;
}

/**
 * @param {string} extension
 * @returns {string}
 */
export function getIconForExtension(extension) {
  return `<i class="fal ${getClassNameForExtension(extension)} fa-fw"></i>`;
}

/**
 * @param {string} extension
 * @returns {string}
 */
export function getExtensionForFilename(filename) {
  return filename
    .slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2)
    .split("?")[0];
}

/**
 * @param {string} extension
 * @returns {string}
 */
export function getClassNameForFilename(filename) {
  if (!filename) {
    return getClassNameForExtension("file");
  }

  return getClassNameForExtension(getExtensionForFilename(filename));
}

/**
 * @param {string} extension
 * @returns {string}
 */
export function getIconForFilename(filename) {
  return getIconForExtension(getExtensionForFilename(filename));
}

export default getClassNameForExtension;
