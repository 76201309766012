import { EventBus } from "@/event-bus";
const TOKEN = "active-notifications";

export default {
  methods: {
    displayStoredNotifications() {
      const storedNotifications = sessionStorage.getItem(TOKEN);

      if (storedNotifications) {
        const notifications = JSON.parse(storedNotifications);

        if (this.$currentUser && notifications[this.$currentUser.id]) {
          notifications[this.$currentUser.id].forEach((notification) => {
            EventBus.emit("new-notification", notification);
          });
        }

        sessionStorage.removeItem(TOKEN);
      }
    },

    storeActiveNotifications(notifications) {
      sessionStorage.setItem(
        TOKEN,
        JSON.stringify({
          [this.$currentUser.id]: notifications,
        })
      );
    },
  },
};
