<template>
  <be-modal
    :id="modalId"
    size="xl"
    :dialog-class="{
      'modal-fullscreen': fullscreen,
    }"
    :body-class="`${fullscreen ? 'p-0' : 'bg-light'}`"
    :no-footer="fullscreen"
    @hidden="fullscreen = false"
  >
    <template #header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="text-break">
          <i :class="`fal ${iconClass} mx-2`" />

          <span class="d-none d-lg-inline-block">{{ modalTitle }}</span>

          <span class="d-lg-none">{{ truncatedModalTitle }}</span>
        </div>

        <div>
          <be-button
            :icon="fullscreen ? 'fa-compress-alt' : 'fa-expand-alt'"
            size="sm"
            class="text-muted text-decoration-none"
            @click="toggleFullscreen"
          >
            {{
              fullscreen
                ? $t("buttons.titles.minimize")
                : $t("buttons.titles.fullscreen")
            }}
          </be-button>
        </div>
      </div>
    </template>

    <be-file-viewer
      v-if="previewable"
      class="h-100"
      :file-url="fileUrl"
      :file-type="fileType"
    />

    <div v-else class="d-flex align-items-center justify-content-center p-6">
      {{ $t("components.shared.be_file_viewer.non_previewable_file") }}
    </div>

    <template #footer="{ close }">
      <be-button variant="light" @click="close">
        {{ $t("buttons.titles.close") }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
import { getClassNameForFilename } from "@/vendor/font-awesome-filetypes";
import { truncateText } from "@/utils/text-utils";
import { contentTypeIsSupported } from "@/utils/file-preview";

export default {
  props: {
    filename: {
      type: String,
      required: true,
    },

    fileType: {
      type: String,
      required: true,
    },

    fileUrl: {
      type: String,
      required: true,
    },

    modalId: {
      type: String,
      default: `file-preview-modal`,
    },
  },

  data() {
    return {
      fullscreen: false,
    };
  },

  computed: {
    modalTitle() {
      return this.filename;
    },

    truncatedModalTitle() {
      return truncateText(this.filename, 40);
    },

    iconClass() {
      return getClassNameForFilename(this.filename);
    },

    previewable() {
      return contentTypeIsSupported(this.fileType);
    },
  },

  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
  },
};
</script>
