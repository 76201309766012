import axios from "axios";
import { handleError } from "@/utils/error-handling";

export const state = {
  phrases: [],
  selectedPhrase: {},
  currentPhrase: {},
  loaded: false,
};

export const getters = {
  getPhrases: (state) => state.phrases,
  getSelectedPhrase: (state) => state.selectedPhrase,
  getCurrentPhrase: (state) => state.currentPhrase,
};

export const actions = {
  async fetchPhrases(context, { company, reload = false }) {
    if (context.state.loaded && !reload) {
      return;
    }

    try {
      const response = await axios.get(
        `/companies/${company.id}/meetings/phrases`
      );
      context.commit("setPhrases", response.data);
    } catch (error) {
      handleError(error);
    }
  },

  async createPhrase(context, phrase) {
    try {
      const response = await axios.post(
        `/companies/${phrase.company_id}/meetings/phrases`,
        {
          phrase: phrase,
        }
      );
      context.commit("addPhrase", response.data);
      context.commit("editPhrase", {});
    } catch (error) {
      context.commit("editPhrase", error.response.data);
      handleError(error);
    }
  },

  async updatePhrase(context, phrase) {
    try {
      const response = await axios.patch(
        `/companies/${phrase.company_id}/meetings/phrases/${phrase.id}`,
        {
          phrase: phrase,
        }
      );
      context.commit("updatePhrase", response.data);
      context.commit("editPhrase", {});
    } catch (error) {
      context.commit("editPhrase", error.response.data);
      handleError(error);
    }
  },

  async destroyPhrase(context, phrase) {
    try {
      await axios.delete(
        `/companies/${phrase.company_id}/meetings/phrases/${phrase.id}`
      );
      context.commit("destroyPhrase", phrase.id);
      context.commit("editPhrase", {});
    } catch (error) {
      handleError(error);
    }
  },
};

export const mutations = {
  addPhrase(state, phrase) {
    state.phrases.push(phrase);
    state.selectedPhrase = phrase;
  },

  setPhrases(state, phrases) {
    state.phrases = phrases;
  },

  editPhrase(state, phrase) {
    state.currentPhrase = phrase;
  },

  addPhrases(state, phrases) {
    state.selectedPhrase = {};
    phrases.forEach((phrase) => {
      const index = state.phrases.findIndex((el) => el.id === phrase.id);
      if (index === -1) {
        state.phrases.push(phrase);
      } else {
        state.phrases[index] = phrase;
      }
    });
  },

  updatePhrase(state, data) {
    let index = state.phrases.findIndex((phrase) => phrase.id == data.id);
    if (index > -1) {
      state.phrases[index] = data;
      state.selectedPhrase = data;
    }
  },

  destroyPhrase(state, id) {
    let index = state.phrases.findIndex((phrase) => phrase.id == id);
    if (index > -1) {
      state.phrases.splice(index, 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
