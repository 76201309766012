<template>
  <div class="p-3">
    <be-form-group :error="getErrors(localComment, 'body')">
      <be-form-textarea
        id="comment-body"
        v-model="localComment.body"
        :state="validationState(localComment, 'body')"
        rows="3"
        max-rows="20"
        @input="clearErrors(localComment, 'body')"
      />
    </be-form-group>

    <be-button
      variant="primary"
      :size="commentButtonSize"
      :data-loading-text="$t('buttons.states.loading')"
      @click="$emit('save', localComment)"
    >
      {{ $t("shared.comments.new_comment_content.save") }}
    </be-button>
  </div>
</template>

<script>
export default {
  props: {
    comment: {
      type: Object,
      required: true,
    },

    commentButtonSize: {
      type: String,
      required: false,
      default: "",
    },
  },

  emits: ["save"],

  data() {
    return {
      localComment: this.cloneDeep(this.comment),
    };
  },

  watch: {
    comment(comment) {
      this.localComment = this.cloneDeep(comment);
    },
  },
};
</script>
