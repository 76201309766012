import { url } from "@/url-helpers";
import { handleError } from "@/utils/error-handling";
import isEqual from "lodash/isEqual";

import axios from "axios";

export const state = {
  annualReports: [],
  invalidUploadedDocuments: [],
};

function sortAnnualReports(items) {
  return items.sort(
    (a, b) => b.year - a.year || (b.version || 0) - (a.version || 0)
  );
}

function isSameChecksums(a, b) {
  a = a || {};
  b = b || {};
  return Object.values(a).join("") == Object.values(b).join("");
}

export const getters = {
  annualReports: (state) => {
    return state.annualReports;
  },

  activeAnnualReports: (state) => {
    return state.annualReports.filter((report) => report.phase !== "archived");
  },

  archivedAnnualReports: (state) => {
    return state.annualReports.filter((report) => report.phase === "archived");
  },

  getAnnualReport: (state) => (id) => {
    return state.annualReports.find((annualReport) => annualReport.id === id);
  },

  invalidUploadedDocuments: (state) => {
    return state.invalidUploadedDocuments;
  },
};

export const actions = {
  async addAnnualReport({ commit }, annual_report) {
    const { data } = await axios.post(url("/annual_reports"), {
      annual_report,
    });
    commit("setAnnualReport", data);
    return data.id;
  },

  setAnnualReports({ commit }, annualReports) {
    commit("setAnnualReports", annualReports);
  },

  async reloadAnnualReport({ state, commit }, id) {
    try {
      const { data } = await axios.get(url(`/annual_reports/${id}`));

      const oldAnnualReport = state.annualReports.find(
        (annualReport) => annualReport.id === id
      );

      if (!oldAnnualReport) {
        commit("setAnnualReport", data);
        return;
      }

      if (oldAnnualReport.updated_at < data.updated_at) {
        commit("setAnnualReport", data);
        return;
      }

      [
        "can",
        "job_callbacks",
        "annual_report_document",
        "audit_report_document",
      ].forEach((key) => {
        if (!isEqual(oldAnnualReport[key], data[key])) {
          commit("setAnnualReport", data);
          return;
        }
      });
      if (!isSameChecksums(oldAnnualReport.checksums, data.checksums)) {
        commit("setAnnualReportChecksums", data);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        commit("removeAnnualReport", id);
      } else {
        handleError(error);
      }
    }
  },

  async fireAnnualReportEvent({ commit }, { id, event, namespace }) {
    try {
      const { data } = await axios.post(url(`/annual_reports/${id}/event`), {
        event: `${event}_${namespace}`,
      });

      commit("setAnnualReport", data);
    } catch (error) {
      handleError(error);
    }
  },

  async removeDocument({ dispatch }, { annualReport, document }) {
    try {
      await axios.delete(url(`/documents/${document.id}`));

      // Needed because the documents controller returns a document.
      dispatch("reloadAnnualReport", annualReport.id);
    } catch (error) {
      handleError(error);
    }
  },

  async updateAnnualReport({ commit }, annualReport) {
    try {
      const { data } = await axios.patch(
        url(`/annual_reports/${annualReport.id}`),
        {
          annual_report: {
            year: annualReport.year,
            certificate_signee_id: annualReport.certificate_signee_id,
            responsible_id: annualReport.responsible_id,
            auditor_ids: annualReport.auditor_ids,
            ceo_id: annualReport.ceo_id,
            delivery_method: annualReport.delivery_method,
            county_code: annualReport.county_code,
            audit_place: annualReport.audit_place,
            audit_company: annualReport.audit_company,
            audit_opinion: annualReport.audit_opinion,
          },
        }
      );

      commit("setAnnualReport", data);
    } catch (error) {
      handleError(error);
    }
  },

  async uploadDocument(
    { state, commit, dispatch },
    { annualReport, key, doc }
  ) {
    state.invalidUploadedDocuments
      .filter(
        (document) =>
          document.key === key && document.owner_id === annualReport.id
      )
      .forEach((document) => {
        commit("removeInvalidUploadedDocument", document);
      });
    try {
      await axios.patch(annualReport.paths.document_upload, {
        annual_report: {
          document: {
            ...doc,
            key: key,
          },
        },
      });

      dispatch("reloadAnnualReport", annualReport.id);
    } catch (error) {
      if (error.response?.status === 422) {
        commit("addInvalidUploadedDocument", error.response.data);
      } else {
        handleError(error);
      }

      dispatch("reloadAnnualReport", annualReport.id);
    }
  },

  async signatureRequestReminder({ commit }, { annualReport, key, userId }) {
    try {
      const { data } = await axios.post(
        url(`/annual_reports/${annualReport.id}/signature_request_reminders`),
        {
          document: {
            key: key,
            user_id: userId,
          },
        }
      );

      commit("setAnnualReport", data);
    } catch (error) {
      handleError(error);
    }
  },
};

export const mutations = {
  setAnnualReports(state, annualReports) {
    state.annualReports = sortAnnualReports(annualReports);
  },

  setAnnualReport(state, annualReport) {
    const index = state.annualReports.findIndex(
      (existing) => existing.id === annualReport.id
    );

    if (index === -1) {
      state.annualReports.push(annualReport);
      state.annualReports = sortAnnualReports(state.annualReports);
    } else {
      state.annualReports[index] = annualReport;
    }
  },

  setAnnualReportChecksums(state, annualReport) {
    const index = state.annualReports.findIndex(
      (existing) => existing.id === annualReport.id
    );

    if (index !== -1) {
      state.annualReports[index].checksums = annualReport.checksums;
    }
  },

  removeAnnualReport(state, id) {
    const index = state.annualReports.findIndex(
      (annualReport) => annualReport.id === id
    );
    state.annualReports.splice(index, 1);
  },

  addInvalidUploadedDocument(state, document) {
    state.invalidUploadedDocuments.push(document);
  },

  removeInvalidUploadedDocument(state, document) {
    const index = state.invalidUploadedDocuments.findIndex(
      (existing) =>
        existing.key === document.key && existing.filename === document.filename
    );

    state.invalidUploadedDocuments.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
