<template>
  <be-badge
    v-if="inquiry.status"
    id="inquiry-status"
    :variant="inquiryStatusColor"
  >
    {{ $t(`views.companies.inquiries.inquiry.${inquiry.status}`) }}
  </be-badge>

  <be-badge v-else id="inquiry-status" variant="info">
    {{ $t(`views.companies.inquiries.inquiry.pending`) }}
  </be-badge>

  <be-badge
    v-if="inquiry.requisition && inquiry.verified_requisition"
    id="verified-status"
    variant="success"
  >
    {{ $t(`views.companies.inquiries.inquiry.verified_requisition`) }}
  </be-badge>
</template>

<script>
export default {
  props: {
    inquiry: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    inquiryStatusColor() {
      const STATUS_COLORS = {
        approved: "success",
        rejected: "danger",
        returned: "success",
        postponed: "info",
        completed: "success",
      };

      return STATUS_COLORS[this.inquiry.status] || "secondary";
    },
  },
};
</script>
