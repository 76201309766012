/**
 * Intercept browser navigation events to show confirm navigation pop-up
 * Use unsavedChanges {Boolean} to enable/disable. Use it as a 'dirty' flag for forms
 * Intercept submit buttons click events and set unsavedChanges to false.
 * setNavConfirmExceptions() can be used.
 * Change navigationConfirmMessage to set a custom message.
 * Run disableConfirmNavigation to remove event handler. It will re-activate if unsavedChanges becomes true again.
 * Note: Some browsers might ignore it
 */
export const ConfirmNavigation = {
  data() {
    return {
      unsavedChanges: false,
      navigationEventHandler: null,

      navigationConfirmMessage: this.$t(
        "components.shared.confirm_unsaved_changes_navigation"
      ),
    };
  },

  methods: {
    enableConfirmNavigation() {
      this.navigationEventHandler = window.addEventListener(
        "beforeunload",
        this._onNavigationChange
      );
    },

    disableConfirmNavigation() {
      window.removeEventListener("beforeunload", this._onNavigationChange);
    },

    _onNavigationChange(event) {
      if (!this.unsavedChanges) return;

      event.preventDefault();
      event.returnValue = this.navigationConfirmMessage;
      return this.navigationConfirmMessage;
    },

    /**
     * Inject click handlers into elements to bypass confirm navigation popup
     * @param elements {String|HTMLElement|HTMLElement[]} Elements to intercept click events on. jQuery strings can be used
     */
    setNavConfirmExceptions(elements = 'input[type="submit"]') {
      $(elements).click(() => {
        this.unsavedChanges = false;
      });
    },
  },

  watch: {
    unsavedChanges(changes) {
      if (changes) {
        this.enableConfirmNavigation();
      } else {
        this.disableConfirmNavigation();
      }
    },
  },
};

export default ConfirmNavigation;
