function pathContext() {
  const currentPath = window.location.pathname.split("/");

  if (currentPath[1] === "admin_panel") {
    return { type: "admin_panel", id: currentPath[2] };
  }

  if (currentPath[1] === "companies") {
    return { type: "company", id: currentPath[2] };
  }

  return { type: "root" };
}

function optionDefaults() {
  const { type, id } = pathContext();
  if (type === "admin_panel") {
    return { admin_panel_id: id };
  }
  if (type === "company" && id != "new") {
    return { nanoid: id };
  }
  return {};
}

function url(path, urlOptions) {
  let options = { ...optionDefaults(), ...urlOptions }; // urlOptions = second argument

  let basePath = "";
  const trailing = path.startsWith("/") ? "" : "/";

  if ("company" in options && options.company?.nanoid) {
    basePath = `/companies/${options.company.nanoid}${trailing}`;
  } else if ("nanoid" in options && options.nanoid) {
    basePath += `/companies/${options.nanoid}${trailing}`;
  } else if ("admin_panel_id" in options && options.admin_panel_id) {
    basePath = `/admin_panel/${options.admin_panel_id}${trailing}`;
  }

  // Remove trailing slashes for nicer URLs
  return (basePath + path).replace(/\/$/, "");
}

function addQueryToUrl(path, queryParams) {
  if (path.includes("?")) {
    throw new Error("Path already includes query params");
  }

  const params = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    params.append(key, value);
  });

  return `${path}?${params.toString()}`;
}

export { pathContext, url, addQueryToUrl };
