import { createApp, defineComponent } from "vue";
import BeTooltip from "@/components/shared/BeTooltip.vue";

// Request animation frame polyfill
const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;

// Default trigger
const DEFAULT_TRIGGER = "hover focus";

// Valid triggers
const VALID_TRIGGERS = ["focus", "hover", "click", "blur"];

// Modifier regex
const MODIFIER_REGEX = {
  placement:
    /^(auto|top(left|right)?|bottom(left|right)?|left(top|bottom)?|right(top|bottom)?)$/i,
};

// Parse bindings
const parseBindings = (bindings, vnode) => {
  // Default config
  let config = {
    title: "",
    trigger: null, // We set this below
    placement: "top",
    fallbackPlacement: "flip",
    container: "body",
    animation: true,
    offset: 0,
    id: undefined,
    interactive: true,
    disabled: false,
    delay: 0,
    boundary: "scrollParent",
    boundaryPadding: 5,
    customClass: null,
  };

  // Process `bindings.value`
  if (
    typeof bindings.value === "string" ||
    typeof bindings.value === "number"
  ) {
    // Value is a simple string/number, so set the title
    config.title = bindings.value.toString().trim();
  } else if (typeof bindings.value === "function") {
    // Title generator function
    config.title = bindings.value;
  } else if (typeof bindings.value === "object") {
    // Value is an object, so assume a config object
    config = { ...config, ...bindings.value };
  }

  // If title is not provided, try the title attribute instead
  if (!config.title) {
    config.title = vnode?.data?.attrs?.title || vnode?.elm?.title || "";
  }

  // Normalize delay
  if (typeof config.delay === "number" || typeof config.delay === "string") {
    config.delay = {
      show: parseInt(config.delay, 10) || 0,
      hide: parseInt(config.delay, 10) || 0,
    };
  }

  // If argument provided, assume it's the element ID of container element
  if (bindings.arg) {
    config.container = `#${bindings.arg}`;
  }

  // Process modifiers
  // We only allow placement modifiers, for now
  Object.keys(bindings.modifiers).forEach((modifier) => {
    if (MODIFIER_REGEX.placement.test(modifier)) {
      // Placement of popover
      config.placement = modifier;
    }
  });

  // Special handling of trigger modifiers when it's a space separated list
  const selectedTriggers = {};

  // Parse current config value
  Array.prototype
    .concat(config.trigger || "")
    .filter(Boolean)
    .join(" ")
    .toLowerCase()
    .split(/\s+/)
    .forEach((trigger) => {
      selectedTriggers[trigger] = true;
    });

  // Parse modifiers for triggers
  Object.keys(bindings.modifiers).forEach((modifier) => {
    if (VALID_TRIGGERS.includes(modifier)) {
      selectedTriggers[modifier] = true;
    }
  });

  // Sanitize triggers
  config.trigger = Object.keys(selectedTriggers)
    .filter(Boolean)
    .join(" ")
    .trim();

  // If the trigger is "blur" on its own, convert it to "focus"
  if (config.trigger === "blur") {
    config.trigger = "focus";
  }

  // Use default trigger if no trigger type is supplied
  if (!config.trigger) {
    config.trigger = DEFAULT_TRIGGER;
  }

  // If trigger is inside a table, set `boundary` to `viewport`
  if (vnode.el.closest("table")) {
    config.boundary = "viewport";
  }

  // Return the config
  return config;
};

const initTooltipConfig = (el, bindings, vnode) => {
  // Parse bindings
  const config = parseBindings(bindings, vnode);

  // Store config reference on target element
  el._tooltipConfig = config;
};

// Initialize tooltip
const mountTooltip = (el) => {
  // Return if tooltip is already mounted
  if (el._tooltip) {
    return;
  }

  // Fetch stored config from target element
  const config = el._tooltipConfig || {};

  // Return if tooltip is disabled or title is empty/null/undefined
  if (config.disabled || !config.title) {
    return;
  }

  // Create tooltip component instance
  const tooltip = defineComponent(BeTooltip);

  // Container
  const container = document.createElement("div");
  document.body.prepend(container);

  // Create app
  const app = createApp(tooltip, {
    target: el,
    ...config,

    onHidden() {
      destroyTooltip(el);
    },
  });

  // Mount tooltip
  const tooltipInstance = app.mount(container);

  // Add references on target
  el._tooltipApp = app;
  el._tooltipContainer = container;
  el._tooltip = tooltipInstance;

  // Temporarily remove `title` attribute on target to prevent
  // native tooltip from showing up
  removeTitleAttribute(el);

  // Show tooltip
  tooltipInstance.show();
};

const unmountTooltip = (el) => {
  // Return if tooltip is not mounted
  if (!el._tooltip) {
    return;
  }

  // Hide tooltip
  el._tooltip.hide();

  // Restore `title` attribute on target
  restoreTitleAttribute(el);
};

const toggleTooltip = (el, vnode) => {
  // If tooltip is mounted, unmount it, otherwise mount it
  if (el._tooltip) {
    unmountTooltip(el);
  } else {
    mountTooltip(el, vnode);
  }
};

// Destroy tooltip
const destroyTooltip = (el) => {
  if (el && el._tooltip) {
    el._tooltipApp.unmount();
    el._tooltipContainer.remove();

    el._tooltipApp = null;
    el._tooltipContainer = null;
    el._tooltip = null;
  }
};

// Bind trigger events
const bindTriggerEvents = (el, vnode) => {
  // Listen to trigger events on target and mount tooltip
  const triggers = el._tooltipConfig.trigger.trim().split(/\s+/);
  triggers.forEach((trigger) => {
    if (trigger === "click") {
      el.addEventListener("click", () => toggleTooltip(el, vnode));
    } else if (trigger === "focus") {
      el.addEventListener("focusin", () => mountTooltip(el, vnode));
      el.addEventListener("focusout", () => unmountTooltip(el));
    } else if (trigger === "hover") {
      el.addEventListener("mouseenter", () => mountTooltip(el, vnode));
      el.addEventListener("mouseleave", () => unmountTooltip(el));
    } else if (trigger === "blur") {
      el.addEventListener("blur", () => unmountTooltip(el));
    }
  });
};

// Unbind trigger events
const unbindTriggerEvents = (el, bindings, vnode) => {
  // If tooltip config is not set, set it
  if (!el._tooltipConfig) {
    initTooltipConfig(el, bindings, vnode);
  }

  // Stop listening to trigger events on target
  const triggers = el._tooltipConfig.trigger.trim().split(/\s+/);
  triggers.forEach((trigger) => {
    if (trigger === "click") {
      el.removeEventListener("click", toggleTooltip);
    } else if (trigger === "focus") {
      el.removeEventListener("focusin", mountTooltip);
      el.removeEventListener("focusout", unmountTooltip);
    } else if (trigger === "hover") {
      el.removeEventListener("mouseenter", mountTooltip);
      el.removeEventListener("mouseleave", unmountTooltip);
    } else if (trigger === "blur") {
      el.removeEventListener("blur", unmountTooltip);
    }
  });
};

// Wrap element in a span
const wrapElement = (el) => {
  // If element already has a wrapper span, return it
  if (el._wrapped) {
    return el.parentNode;
  }

  const wrapper = document.createElement("span");

  // If element has the `btn-block` or `d-block` class, add the `d-block`
  // class to the wrapper, otherwise add the `d-inline-block` class
  if (el.classList.contains("btn-block") || el.classList.contains("d-block")) {
    wrapper.classList.add("d-block");
  } else {
    wrapper.classList.add("d-block", "d-md-inline-block");
  }

  wrapper.setAttribute("tabindex", "0");
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
  el._wrapped = true;
  return wrapper;
};

// Unwrap element
const unwrapElement = (el) => {
  const wrapper = el.parentNode;

  // If wrapper exists, and it's a span, unwrap it
  if (wrapper && wrapper.tagName === "SPAN") {
    wrapper.parentNode.insertBefore(el, wrapper);
    wrapper.parentNode.removeChild(wrapper);
  }

  // Remove wrapped flag
  delete el._wrapped;
};

const removeTitleAttribute = (el) => {
  if (!el) {
    return;
  }

  // Get current `title` value
  const title = el.getAttribute("title");

  // If `title` exists, store it in `data-be-original-title`
  // and remove `title` attribute
  if (title) {
    el.setAttribute("data-be-original-title", title);
    el.removeAttribute("title");
  }
};

const restoreTitleAttribute = (el) => {
  if (!el) {
    return;
  }

  // Get current `data-be-original-title` value
  const originalTitle = el.getAttribute("data-be-original-title");

  // If `data-be-original-title` exists, restore `title` attribute
  if (originalTitle) {
    el.setAttribute("title", originalTitle);
    el.removeAttribute("data-be-original-title");
  }
};

export default {
  beforeMount(el, bindings, vnode) {
    requestAnimationFrame(() => {
      // If element is disabled, wrap it in a span and
      // apply the tooltip to the span instead, unless
      // it's already wrapped
      if ((el.disabled || el.getAttribute("disabled")) && !el._wrapped) {
        el = wrapElement(el);
      }

      // Initialize tooltip config
      initTooltipConfig(el, bindings, vnode);

      // Set title attribute on target element, unless the tooltip is disabled
      if (!el._tooltipConfig.disabled) {
        el.setAttribute("title", el._tooltipConfig.title);
      }

      // Bind events to triggers
      bindTriggerEvents(el, vnode);
    });
  },

  updated(el, bindings, vnode) {
    // Unbind trigger events
    unbindTriggerEvents(el, bindings, vnode);

    // Destroy tooltip
    destroyTooltip(el);

    // If element was disabled and wrapped, unwrap it
    if (el._wrapped) {
      unwrapElement(el);
    }

    // Re-bind directive (this will also re-initialize the tooltip)
    // Needs to be done in `requestAnimationFrame` to ensure the DOM
    // is updated before re-binding.
    requestAnimationFrame(() => {
      // If element is disabled, wrap it in a span and
      // apply the tooltip to the span instead
      if (el.disabled || el.getAttribute("disabled")) {
        el = wrapElement(el);
      }

      // Initialize tooltip config
      initTooltipConfig(el, bindings, vnode);

      // Set title attribute on target element, unless the tooltip is disabled
      if (!el._tooltipConfig.disabled) {
        el.setAttribute("title", el._tooltipConfig.title);
      }

      // Bind events to triggers
      bindTriggerEvents(el, vnode);
    });
  },

  unmounted(el, bindings, vnode) {
    // If element was disabled and wrapped, unwrap it
    if (el._wrapped) {
      unwrapElement(el);
    }

    // Remove title attribute
    el.removeAttribute("title");

    // Remove trigger events
    unbindTriggerEvents(el, bindings, vnode);

    // Destroy tooltip
    destroyTooltip(el);

    // Remove stored reference to config
    delete el._tooltipConfig;
  },
};
