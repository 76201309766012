/**
 * Methods helpful for translation
 */

import { isSameDay } from "date-fns/isSameDay";
import { parseISO } from "date-fns/parseISO";

export default {
  methods: {
    translateAttribute(model, attribute, required = false) {
      let translation = this.$t(
        `activerecord.attributes.${model}.${attribute}`
      );
      if (required) {
        translation = `${translation} *`;
      }
      return translation;
    },

    dateFormatIfSameDay(date) {
      return isSameDay(date, new Date()) ? "time" : "dateTime";
    },

    parseDate(dateString) {
      if (dateString) {
        return parseISO(dateString);
      }
      return null;
    },
  },
};
