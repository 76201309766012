import Config from "@/config.js";
import translations from "@/translations.js";

import { createI18n } from "vue-i18n";

const i18n = createI18n({
  locale: Config.DEFAULT_LOCALE,
  messages: translations.messages.default,
  datetimeFormats: translations.datetimeFormats,
  numberFormats: translations.currencyFormats,
});

export default i18n;
