<template>
  <div v-if="localReferences.length" class="mb-2">
    <h5>
      {{ $t("activerecord.models.report.other", company.locale) }}
    </h5>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="pt-0 pb-1">
              {{ $t("activerecord.attributes.report.title", company.locale) }}
            </th>

            <th class="col-shrink pt-0 pb-1">
              {{
                $t("activerecord.attributes.report.period_end", company.locale)
              }}
            </th>

            <th class="col-shrink pt-0 pb-1">
              {{
                $t("activerecord.attributes.report.report_type", company.locale)
              }}
            </th>

            <th class="col-shrink pt-0 pb-1">
              {{
                $t(
                  "activerecord.attributes.report.published_at",
                  company.locale
                )
              }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="reference in localReferences"
            :key="`reference-${reference.id}`"
          >
            <td>
              <be-link
                class="d-print-none"
                :href="url(`/reports/${reference.reference_id}`)"
              >
                {{ reference.report.title }}
              </be-link>

              <span class="d-none d-print-inline">
                {{ reference.report.title }}
              </span>
            </td>

            <td class="col-shrink">
              {{
                $d(new Date(reference.report.period_end), {
                  format: "year_month",
                })
              }}
            </td>

            <td class="col-shrink">
              <template v-if="reference.report.report_type != 'general'">
                {{ $t(`models.report.types.${reference.report.report_type}`) }}
              </template>
            </td>

            <td class="col-shrink">
              {{
                $d(new Date(reference.report.published_at), {
                  format: "year_month",
                })
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const materialHelpers = createNamespacedHelpers("material");

export default {
  props: {
    itemId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...materialHelpers.mapGetters(["followUpReferences"]),

    localReferences() {
      return this.followUpReferences.filter(
        (reference) =>
          reference.item_id === this.itemId &&
          reference.reference_type === "Report"
      );
    },
  },
};
</script>
