import ConfirmNavigation from "@/mixins/ConfirmNavigation";

const POLLING_INTERVAL_CHECK = 60 * 1000; // 60 seconds
const POLLING_TIMEOUT = 30 * 1000; // 30 seconds

export const Heartbeat = {
  mixins: [ConfirmNavigation],

  data: () => ({
    signOutAlertInProgress: false,
    intervalChecker: null,
  }),

  mounted() {
    if (this.$currentUser?.id) {
      this.intervalChecker = setInterval(
        this.heartbeat,
        POLLING_INTERVAL_CHECK
      );
    }
  },

  methods: {
    async heartbeat() {
      try {
        const response = await axios.get("/sessions/heartbeat", {
          timeout: POLLING_TIMEOUT,
        });

        const warnAt = Date.parse(response.data.warn_at);

        if (warnAt <= new Date()) {
          this.alertTimeout();
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          this.clearAndReload();
        }
      }

      return false;
    },

    async alertTimeout() {
      if (this.signOutAlertInProgress) {
        return;
      }

      this.signOutAlertInProgress = true;

      try {
        const isConfirmed = await this.promptConfirm({
          title: this.$t("components.shared.heartbeat.header"),
          confirmButtonOnly: true,
          noCloseOnBackdrop: true,
          noCloseOnEsc: true,

          confirmButtonText: this.$t(
            "components.shared.heartbeat.confirmation"
          ),
        });

        if (isConfirmed) {
          this.extendSession();

          this.signOutAlertInProgress = false;
        }
      } catch (error) {
        this.handleError(error);
        this.clearAndReload();
      }
    },

    async extendSession() {
      try {
        await axios.post("/sessions/extend");

        return true;
      } catch (error) {
        this.handleError(error);
        this.clearAndReload();
      }

      return false;
    },

    clearAndReload() {
      this.disableConfirmNavigation();

      // Delete state from session storage
      sessionStorage.removeItem("vuex");

      // Reload the current page, without using the cache
      document.location.reload(true);
    },
  },
};

export default Heartbeat;
