<template>
  <div>
    <div class="d-flex align-items-center">
      <i v-if="showIcon" :class="`${iconClass} text-muted mr-2`" />

      <div>
        <template v-if="readonly">
          {{ filename }}
        </template>

        <be-link
          v-else
          v-be-modal="modalId"
          :class="{ 'text-break': textbreak }"
        >
          {{ filename }}
        </be-link>
      </div>
    </div>

    <file-modal
      :modal-id="modalId"
      :filename="filename"
      :file-type="fileType"
      :file-url="fileUrl"
    />
  </div>
</template>

<script>
import { getClassNameForFilename } from "@/vendor/font-awesome-filetypes";
import FileModal from "./FileModal.vue";

export default {
  components: {
    FileModal,
  },

  props: {
    filename: {
      type: String,
      required: true,
    },

    fileType: {
      type: String,
      required: true,
    },

    fileUrl: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: null,
    },

    textbreak: {
      type: Boolean,
      default: true,
    },

    showIcon: {
      type: Boolean,
      default: true,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["reading-updated"],

  computed: {
    iconClass() {
      return this.icon || `fal ${getClassNameForFilename(this.filename)}`;
    },

    modalId() {
      return `file-modal-${Math.random()}`;
    },
  },
};
</script>
