<template>
  <component
    :is="computedTag"
    v-bind="computedAttrs"
    ref="button"
    :class="computedClasses"
    @click="onClick"
    @mousedown="onMouseDown"
  >
    <div
      v-if="loading"
      :class="computedLoadingSpinnerClasses"
      :style="computedLoadingSpinnerStyle"
    >
      <be-spinner v-if="loading" :variant="computedSpinnerVariant" />
    </div>

    <i v-if="icon" :class="computedIconClass" />

    <span v-if="loading" :class="{ invisible: loading }">
      <slot />
    </span>

    <slot v-else />
  </component>
</template>

<script>
import { KEY_CODE_ENTER, KEY_CODE_SPACE } from "@/constants/key-codes";
import BeSpinner from "@/components/shared/BeSpinner.vue";

export default {
  name: "BeButton",

  components: {
    BeSpinner,
  },

  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },

    block: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    href: {
      type: String,
      required: false,
      default: undefined,
    },

    icon: {
      type: String,
      required: false,
      default: undefined,

      validator: (value) => {
        // Must start with "fa-"
        return value.startsWith("fa-");
      },
    },

    iconCustomClass: {
      type: String,
      required: false,
      default: undefined,
    },

    iconSpacingClass: {
      type: String,
      required: false,
      default: "mr-1",
    },

    iconStyle: {
      type: String,
      required: false,
      default: "fal",

      validator: (value) => {
        return ["fal", "fas", "far", "fab", "fa-duotone"].includes(value);
      },
    },

    inline: {
      type: Boolean,
      required: false,
      default: false,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    pill: {
      type: Boolean,
      required: false,
      default: false,
    },

    rel: {
      type: String,
      required: false,
      default: undefined,
    },

    size: {
      type: String,
      required: false,
      default: undefined,
    },

    tag: {
      type: String,
      required: false,
      default: undefined,
    },

    target: {
      type: String,
      required: false,
      default: undefined,
    },

    type: {
      type: String,
      required: false,
      default: "button",

      validator: (value) => {
        return ["button", "reset", "submit"].includes(value);
      },
    },

    variant: {
      type: String,
      required: false,
      default: "outline-secondary",
    },
  },

  emits: ["click", "mousedown"],

  computed: {
    computedAttrs() {
      const {
        disabled,
        href,
        loading,
        computedRel,
        computedTarget,
        dataAttrs,
        isButton,
        isLink,
      } = this;

      const linkAttrs = isLink
        ? {
            href,
            rel: computedRel,
            target: computedTarget,
          }
        : {};

      return {
        disabled: disabled || loading || null,
        type: isButton ? this.type : null,
        "aria-disabled": disabled || loading ? "true" : null,
        "aria-busy": loading ? "true" : null,
        ...linkAttrs,
        ...dataAttrs,
      };
    },

    computedClasses() {
      const { active, block, disabled, inline, pill, size, variant } = this;

      return [
        "btn",
        `btn-${variant}`,
        "position-relative",
        "mt-md-0",
        {
          active,
          disabled,
          "btn-block": block,
          "btn-inline-block": inline,
          "rounded-pill": pill,
          [`btn-${size}`]: size,
          "w-100 w-md-auto": !inline && !block,
        },
      ];
    },

    computedIconClass() {
      const {
        icon,
        iconCustomClass,
        iconSpacingClass,
        computedIconStyle,
        loading,
      } = this;

      return [
        computedIconStyle,
        icon,
        {
          [iconSpacingClass]: !this.emptySlot,
          invisible: loading,
        },
        iconCustomClass,
      ];
    },

    computedIconStyle() {
      const { icon, iconStyle } = this;

      // Default these icons to "fas"
      const fasIcons = [
        "fa-times",
        "fa-check",
        "fa-plus",
        "fa-rotate-right",
        "fa-rotate-left",
      ];

      if (fasIcons.includes(icon)) {
        return "fas";
      } else {
        return iconStyle;
      }
    },

    computedLoadingSpinnerClasses() {
      return [
        "position-absolute",
        "d-flex",
        "align-items-center",
        "justify-content-center",
        "h-100",
        "w-100",
        "rounded",
      ];
    },

    computedLoadingSpinnerStyle() {
      return {
        left: 0,
        top: 0,
      };
    },

    computedSpinnerVariant() {
      switch (this.variant) {
        case "primary":
        case "success":
          return "light";
        case "light":
          return "primary";
        default:
          return this.variant;
      }
    },

    computedRel() {
      const { rel, computedTarget: target } = this;

      if (target === "_blank") {
        return "noopener noreferrer";
      } else if (rel) {
        return rel;
      } else {
        return null;
      }
    },

    computedTag() {
      const { tag, isLink } = this;

      if (tag) {
        return tag;
      }

      return isLink ? "a" : "button";
    },

    computedTarget() {
      return this.target || "_self";
    },

    dataAttrs() {
      return Object.keys(this.$attrs).reduce((attrs, key) => {
        if (key.startsWith("data-")) {
          attrs[key] = this.$attrs[key];
        }

        return attrs;
      }, {});
    },

    emptySlot() {
      return this.$slots.default === undefined;
    },

    isButton() {
      return !this.href;
    },

    isLink() {
      return !!this.href;
    },
  },

  mounted() {
    // If `href` is "#", we want to apply some accessibility attributes
    // and event listeners to the rendered link, so that it behaves
    // like a button for screen readers and keyboard users.
    if (this.href === "#") {
      this.$el.setAttribute("role", "button");
      this.$el.setAttribute("tabindex", "0");
      this.$el.addEventListener("keydown", this.onKeyDown);
    }

    this.$nextTick(() => {
      // Check if previous sibling is also a button, and if so, add
      // margin utility classes to this button, unless their parent:
      // - has a `gap-*` utility class
      // - is a button group
      // - is a input-group
      const previousSibling = this.$el.previousElementSibling;
      const parent = this.$el.parentElement;
      if (previousSibling && previousSibling.classList.contains("btn")) {
        const parentClasses = Array.from(parent.classList);
        const parentHasGapUtilityClass = parentClasses.some((className) =>
          className.startsWith("gap-")
        );
        const parentIsInputGroup = parentClasses.some((className) =>
          className.startsWith("input-group")
        );
        const parentIsButtonGroup = parent.classList.contains("btn-group");

        if (
          !parentHasGapUtilityClass &&
          !parentIsButtonGroup &&
          !parentIsInputGroup
        ) {
          if (!this.block) {
            this.$el.classList.add("ml-md-1");
          }

          if (!this.inline) {
            this.$el.classList.add("mt-2");
          }
        }
      }
    });
  },

  methods: {
    onClick(event) {
      if (this.disabled) {
        event.preventDefault();
        return;
      }

      if (this.href === "#") {
        // Prevent browser from scrolling to top
        event.preventDefault();
        event.stopPropagation();

        // Remove focus from clicked element
        event.target.blur();
      }

      // Emit to parent
      this.$emit("click", event);
    },

    onMouseDown(event) {
      if (this.disabled) {
        event.preventDefault();
        return;
      }

      this.$emit("mousedown", event);
    },

    onKeyDown(event) {
      if (
        event.keyCode === KEY_CODE_ENTER ||
        event.keyCode === KEY_CODE_SPACE
      ) {
        event.preventDefault();
        this.$el.click();
      }
    },
  },
};
</script>
