const formatOptions = (options) => {
  if (typeof options === "string") {
    return {
      title: options,
    };
  } else {
    return options;
  }
};

export default {
  methods: {
    promptConfirm(options) {
      return this.$confirm(formatOptions(options));
    },

    promptRemovalConfirm(options) {
      const defaultRemovalConfirmOptions = {
        confirmButtonText: this.$t("buttons.titles.remove"),
        variant: "danger",
      };

      return this.$confirm({
        ...defaultRemovalConfirmOptions,
        ...formatOptions(options),
      });
    },
  },
};
