import { createStore } from "vuex";

import activities from "./modules/activities";
import annual_cycle from "./modules/annual_cycle";
import annual_reports from "./modules/annual_reports";
import attendances from "./modules/attendances";
import comments from "./modules/comments";
import company from "./modules/company";
import context_switcher from "./modules/context_switcher";
import contracts from "./modules/contracts";
import corporate_group_tree from "./modules/corporate_group_tree";
import current_user from "./modules/current_user";
import documents from "./modules/documents";
import evaluation_forms from "./modules/evaluation_forms";
import inertia_form from "./modules/inertia_form";
import inquiries from "./modules/inquiries";
import invitations from "./modules/invitations";
import job_callbacks from "./modules/job_callbacks";
import material from "./modules/material";
import meetings from "./modules/meetings";
import memberships from "./modules/memberships";
import message_threads from "./modules/message_threads";
import modals from "./modules/modals";
import phrases from "./modules/phrases";
import platform from "./modules/platform";
import readings from "./modules/readings";
import reports from "./modules/reports";
import request_query from "./modules/request_query";
import session from "./modules/session";
import shareholder_register from "./modules/shareholder_register";
import syna from "./modules/syna";
import tasks from "./modules/tasks";
import template_packages from "./modules/template_packages";
import upcoming_meetings from "./modules/upcoming_meetings";

const modules = {
  activities,
  annual_cycle,
  annual_reports,
  attendances,
  comments,
  company,
  context_switcher,
  contracts,
  corporate_group_tree,
  current_user,
  documents,
  evaluation_forms,
  inertia_form,
  inquiries,
  invitations,
  job_callbacks,
  material,
  meetings,
  memberships,
  message_threads,
  modals,
  phrases,
  platform,
  readings,
  reports,
  request_query,
  session,
  shareholder_register,
  syna,
  tasks,
  template_packages,
  upcoming_meetings,
};

export default createStore({
  modules,
});
