<template>
  <div>
    <div v-if="summary.statuses">
      <div v-for="(statusSummary, status) in summary.statuses" :key="status">
        <h3 class="mb-3">
          {{ $t("models.inquiry.status" + "." + status, company.locale) }}
        </h3>

        <!-- Inquiries -->
        <div
          v-for="inquiry in inquiries(status)"
          :key="`item-inquiry-${inquiry.id}`"
          class="bg-light rounded p-2 mb-2"
        >
          <div class="row">
            <!-- External ID -->
            <div class="col-4 col-xl-2 col-print-4 mt-2">
              <div class="font-weight-bold">
                {{
                  $t(
                    "activerecord.attributes.inquiry.external_id",
                    company.locale
                  )
                }}
              </div>

              <be-link :href="inquiry.paths.base">
                {{ inquiry.external_id }}
              </be-link>
            </div>

            <!-- Title -->
            <div
              v-if="platformEnabled('inquiries_title')"
              class="col-4 col-xl-2 col-print-4 mt-2"
            >
              <div class="font-weight-bold">
                {{
                  $t("activerecord.attributes.inquiry.title", company.locale)
                }}
              </div>

              <div>
                {{ inquiry.title }}
              </div>
            </div>

            <!-- Receiver -->
            <div
              v-if="platformEnabled('inquiries_receiver')"
              class="col-4 col-xl-2 col-print-4 mt-2"
            >
              <div class="font-weight-bold">
                {{
                  $t("activerecord.attributes.inquiry.receiver", company.locale)
                }}
              </div>

              <div>
                <template v-if="inquiry.inquiry_receiver?.name">
                  {{ inquiry.inquiry_receiver.name }}
                </template>

                <template v-else> - </template>
              </div>
            </div>

            <!-- Requested Value -->
            <div class="col-4 col-xl-2 col-print-4 mt-2">
              <div class="font-weight-bold">
                {{
                  $t(
                    "activerecord.attributes.inquiry_item.requested_value",
                    company.locale
                  )
                }}
              </div>

              <div>
                <money-format
                  :currency="inquiry.currency"
                  :value="Number(inquiry.requested_value)"
                />
              </div>
            </div>

            <!-- Granted Value -->
            <div class="col-4 col-xl-2 col-print-4 mt-2">
              <div class="font-weight-bold">
                {{
                  $t(
                    "activerecord.attributes.inquiry_item.granted_value",
                    company.locale
                  )
                }}
              </div>

              <div>
                <money-format
                  v-if="inquiry.latest_inquiry_item.status == 'approved'"
                  :currency="inquiry.currency"
                  :value="inquiry.latest_inquiry_item.granted_value"
                />

                <span v-else>-</span>
              </div>
            </div>

            <!-- Status -->
            <div class="col-4 col-xl-2 col-print-4 mt-2">
              <div class="font-weight-bold">
                {{
                  $t(
                    "activerecord.attributes.inquiry_item.status",
                    company.locale
                  )
                }}
              </div>

              <inquiry-status-badge :inquiry="inquiry.latest_inquiry_item" />
            </div>
          </div>

          <!-- Comment -->
          <div v-if="inquiry.latest_inquiry_item.comment" class="row mt-2">
            <div class="col-sm-9">
              <p class="font-weight-bold mb-0">
                {{
                  $t(
                    "activerecord.attributes.inquiry_item.comment",
                    company.locale
                  )
                }}
              </p>

              <p
                v-dompurify-html="inquiry.latest_inquiry_item.comment"
                class="ql-viewer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MoneyFormat from "@/components/shared/MoneyFormat.vue";
import InquiryStatusBadge from "@/components/inquiries/components/StatusBadge.vue";

export default {
  components: { MoneyFormat, InquiryStatusBadge },

  data() {
    return {
      statuses: ["approved", "rejected", "postponed"],
    };
  },

  computed: {
    ...mapGetters({
      getSummary: "inquiries/getSummary",
      getInquiries: "inquiries/getInquiriesWithItems",
      meeting: "material/meeting",
    }),

    summary() {
      return this.getSummary({ meeting: this.meeting });
    },
  },

  mounted() {
    this.checkAndLoadInquiries();
  },

  methods: {
    ...mapActions("inquiries", ["loadInquiries"]),

    checkAndLoadInquiries() {
      if (!this.platformEnabled("inquiries")) {
        return;
      }

      this.loadInquiries();
    },

    inquiries(status) {
      return this.getInquiries({ status: status, meeting: this.meeting });
    },
  },
};
</script>
