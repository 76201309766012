<template>
  <div class="d-inline">
    <be-button
      v-if="hasFeature('e-signature') && doc.signature_status === 'available'"
      variant="outline-secondary"
      @click="open = !open"
    >
      {{ $t("components.documents.document_row.send_for_signatures") }}
    </be-button>

    <signature-form
      :doc="signatureDocument"
      :available-users="users"
      @document-updated="handleDocumentUpdated"
      @reset="() => (signatureDocument = {})"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SignatureForm from "@/components/documents/SignatureForm.vue";

export default {
  components: {
    SignatureForm,
  },

  props: {
    doc: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      open: false,
      signatureDocument: {},
    };
  },

  computed: {
    ...mapGetters({
      hasFeature: "company/hasFeature",
      users: "company/users",
    }),
  },

  watch: {
    open(val) {
      if (val) this.signatureDocument = this.doc;
    },
  },

  methods: {
    handleDocumentUpdated(document) {
      this.$store.commit("documents/updateDocument", document);
      location.reload();
    },
  },
};
</script>
