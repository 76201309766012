<template>
  <div class="btn-group gap-0">
    <template v-for="availability in availabilities">
      <be-button
        v-if="!meetingSealed"
        :key="availability.id"
        v-be-tooltip="availabilityTooltip(availability)"
        :size="buttonSize"
        :variant="availabilityButtonClass(availability, suggestion)"
        inline
        @click="markAvailability(availability, suggestion)"
      >
        <i :class="availabilityIconClass(availability)" />
      </be-button>

      <be-button
        v-else
        :key="availability.id"
        :size="buttonSize"
        :variant="availabilityButtonClass(availability, suggestion)"
        disabled
        :class="availabilityButtonClass(availability)"
        inline
      >
        <i :class="availabilityIconClass(availability)" />
      </be-button>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    availabilities: {
      type: Array,
      required: false,
      default: () => ["available", "inconvenient", "unavailable"],
    },

    meetingSealed: {
      type: Boolean,
      required: true,
    },

    suggestion: {
      type: Object,
      required: true,
    },

    buttonSize: {
      type: String,
      required: false,
      default: "",
    },
  },

  emits: ["mark-availability"],

  methods: {
    availabilityButtonClass(availability, suggestion) {
      const userAvailability = this.currentUserAvailability(suggestion);
      const buttonClasses = {
        available: {
          selected: "success",
          default: "outline-success",
        },

        unavailable: {
          selected: "danger",
          default: "outline-danger",
        },

        inconvenient: {
          selected: "warning",
          default: "outline-warning",
        },
      };

      return buttonClasses[availability][
        userAvailability == availability ? "selected" : "default"
      ];
    },

    availabilityTooltip(availability) {
      switch (availability) {
        case "available":
          return this.$t("models.suggestion.availabilities.available");
        case "unavailable":
          return this.$t("models.suggestion.availabilities.unavailable");
        case "inconvenient":
        default:
          return this.$t("models.suggestion.availabilities.inconvenient");
      }
    },

    availabilityIconClass(availability) {
      switch (availability) {
        case "available":
          return "fas fa-check";
        case "unavailable":
          return "fas fa-times";
        case "inconvenient":
        default:
          return "fas fa-question";
      }
    },

    currentUserAvailability(suggestion) {
      if (suggestion) {
        const choice = suggestion.suggestion_choices.find(
          (choice) => choice.user_id === this.$currentUser.id
        );
        if (choice) {
          return choice.availability;
        }
      }
      return "not_responded";
    },

    markAvailability(availability, suggestion) {
      this.$emit("mark-availability", {
        suggestion: suggestion,
        availability: availability,
      });
    },
  },
};
</script>
