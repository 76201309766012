import { BeEvent } from "@/helpers/be-event";

/**
 * BeModalEvent
 *
 * A custom event class for modal events, extends BeEvent. Mainly used
 * to pass a cancelable event from the modal component.
 */

class BeModalEvent extends BeEvent {
  constructor(type, eventInit = {}) {
    super(type, eventInit);

    // Freeze our new props as readonly, but leave them enumerable
    Object.defineProperties(this, {
      trigger: { enumerable: true, configurable: false, writable: false },
    });
  }

  static get Defaults() {
    return {
      ...super.Defaults,
      trigger: null,
    };
  }
}

export { BeModalEvent };
