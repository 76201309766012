<template>
  <div :class="{ 'table-responsive': responsive }">
    <table :class="computedTableClasses">
      <thead v-if="!hideHeader">
        <tr>
          <th v-for="column in Array.from({ length: columns })" :key="column">
            <be-skeleton :animation="animation" />
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="row in Array.from({ length: rows })" :key="row">
          <td v-for="column in Array.from({ length: columns })" :key="column">
            <be-skeleton :animation="animation" />
          </td>
        </tr>
      </tbody>

      <tfoot v-if="showFooter">
        <tr>
          <th v-for="column in Array.from({ length: columns })" :key="column">
            <be-skeleton :animation="animation" />
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: "BeSkeletonTable",

  props: {
    animation: {
      type: String,
      required: false,
      default: undefined,
    },

    columns: {
      type: [Number, String],
      required: false,
      default: 5,

      validator: (value) => {
        return Number(value) >= 1;
      },
    },

    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },

    responsive: {
      type: Boolean,
      required: false,
      default: true,
    },

    rows: {
      type: [Number, String],
      required: false,
      default: 3,

      validator: (value) => {
        return Number(value) >= 1;
      },
    },

    showFooter: {
      type: Boolean,
      required: false,
      default: false,
    },

    tableProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  computed: {
    computedTableClasses() {
      const { bordered, hover, striped } = this.tableProps;

      return [
        "table",
        {
          "table-bordered": bordered,
          "table-hover": hover,
          "table-striped": striped,
        },
      ];
    },
  },
};
</script>
