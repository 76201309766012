export const state = {
  platform: {},
  membership: {},
  admin_panel: {},
  policies: {},
};

export const getters = {
  getPlatform: (state) => state.platform,
  getMembership: (state) => state.membership,
  getAdminPanel: (state) => state.admin_panel,

  getTheme: (state) => {
    if (!state.platform?.theme) return {};

    return {
      name: state.platform.theme.theme_name,
      ...state.platform.theme.theme_config,
    };
  },

  visualbyFlipperFlags: (state) => {
    return (state.platform?.flags || []).filter((flag) =>
      flag.startsWith("vb-")
    );
  },

  enabled: (state) => (features) => {
    const allFeatures = Array(features).flat();
    return allFeatures.every(
      // If we can't find any platform limitations for a feature, then
      // assume that it's enabled (true) for the user.
      (feature) => {
        const platformFeatures = state.platform?.features || {};
        if (!Object.keys(platformFeatures).includes(feature)) {
          return true;
        }

        return platformFeatures[feature];
      }
    );
  },

  enabledSome: (state) => (features) => {
    const allFeatures = Array(features).flat();
    return allFeatures.some(
      // If we can't find any platform limitations for a feature, then
      // assume that it's enabled (true) for the user.
      (feature) => {
        const platformFeatures = state.platform?.features || {};
        if (!Object.keys(platformFeatures).includes(feature)) {
          return true;
        }

        return platformFeatures[feature];
      }
    );
  },

  checkPolicy: (state) => (policy) => {
    const policies = state.policies || {};
    return policies[policy] || false;
  },
};

export const mutations = {
  setPlatform(state, platform) {
    state.platform = platform;
  },

  setMembership(state, membership) {
    state.membership = membership;
  },

  setAdminPanel(state, adminPanel) {
    state.admin_panel = adminPanel;
  },

  setPolicies(state, policies) {
    state.policies = policies;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
