<template>
  <div class="table-responsive">
    <table class="table table-bordered">
      <tbody>
        <tr>
          <th>
            {{ $t("activerecord.models.document.one") }}
          </th>

          <td>
            <document-link
              :document-id="document.id"
              :filename="document.filename"
            />
          </td>
        </tr>

        <tr>
          <th>
            {{ $t("activerecord.attributes.signature.created_at") }}
          </th>

          <td>
            {{ signatureSentAt }}
          </td>
        </tr>

        <tr v-if="signatureRequestedBy">
          <th>
            {{
              $t("activerecord.attributes.document.signature_requested_by_id")
            }}
          </th>

          <td>
            {{ signatureRequestedBy }}
          </td>
        </tr>

        <tr>
          <th>
            {{ $t("models.document.signature_solutions") }}
          </th>

          <td>
            <be-badge
              v-for="solution in document.allowed_signature_types || []"
              :key="`signature-solution-${solution}`"
              v-be-tooltip="
                $t(
                  `models.document.signature_solution_list.${solution}.description`
                )
              "
              variant="primary"
              class="mr-1"
            >
              {{
                $t(`models.document.signature_solution_list.${solution}.title`)
              }}
            </be-badge>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    document: {
      type: Object,
      required: true,
    },
  },

  computed: {
    signatureRequestedBy() {
      const membership = this.getMembershipByUserId(
        this.document.signature_requested_by_id
      );

      return membership?.user?.name;
    },

    signatureSentAt() {
      const signature = this.document.signatures[0];
      if (signature) {
        return this.$d(this.parseDate(signature.created_at), "dateTime");
      }
      return null;
    },
  },
};
</script>
