<template>
  <be-modal
    :id="id"
    :title="title"
    size="lg"
    :ok-only="true"
    ok-variant="light"
    :ok-title="$t('buttons.titles.close')"
    @show="modalOpened"
    @hide="modalHidden"
  >
    <!-- Loading any previous exports -->
    <div v-if="loading && !exportInProgress" class="rounded p-3 bg-light">
      <div class="d-flex flex-grow-1 align-items-center py-2">
        <be-spinner class="ml-1">
          {{ $t("buttons.states.loading") }}
        </be-spinner>
      </div>
    </div>

    <!-- Any of the exports failed -->
    <be-alert v-else-if="exportFailed" variant="danger" class="mb-0">
      {{ $t("components.shared.export_modal.export_failed") }}.

      <be-link @click="requestExports">
        {{ $t("buttons.titles.try_again") }}?
      </be-link>
    </be-alert>

    <!-- Exports are processing or are finished -->
    <div v-else class="d-flex flex-column">
      <exported-file
        v-for="exportCase in currentExportCases"
        :key="exportCase.id"
        :export-case="exportCase"
        @request-export="requestExport"
      />

      <!-- Exports are finished -->
      <div
        v-if="showStatusText"
        class="d-flex align-items-center pl-1 text-muted mt-2"
      >
        {{ statusText }}.

        <be-button
          variant="link"
          class="p-0 ml-1 text-reset-line-height"
          @click="requestExports"
        >
          {{ `${$t("buttons.titles.create_new_export", exportTypes.length)}?` }}
        </be-button>
      </div>
    </div>
  </be-modal>
</template>

<script>
import orderBy from "lodash/orderBy";
import ExportedFile from "./ExportedFile.vue";

// Generate new export if it is older than 10 minutes
const VALID_FOR_MINUTES = 10;

export default {
  components: {
    ExportedFile,
  },

  props: {
    exportTypes: {
      type: Array,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    id: {
      type: String,
      required: true,
    },

    exportParams: {
      type: Object,
      required: false,
      default: null,
    },

    directExport: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      exportCases: [],
      interval: null,
      updateNowInterval: null,
      loading: false,
      now: new Date(),
    };
  },

  computed: {
    currentExportCases() {
      const results = {};

      orderBy(this.exportCases, "created_at", "desc").forEach((exportCase) => {
        if (!results[exportCase.export_type]) {
          results[exportCase.export_type] = exportCase;
        }
      });

      return Object.values(results);
    },

    minutesSinceExport() {
      let minutes = 1;

      for (const exportCase of this.currentExportCases) {
        const minutesSinceExport = Math.round(
          (this.now - new Date(exportCase.created_at)) / 1000 / 60
        );
        if (minutesSinceExport > minutes) {
          minutes = minutesSinceExport;
        }
      }

      return minutes < 1 ? 1 : minutes;
    },

    exportFailed() {
      return this.currentExportCases.some(
        (exportCase) => exportCase.status === "failed"
      );
    },

    exportInProgress() {
      if (this.exportFailed) {
        return false;
      }

      return this.currentExportCases.some((exportCase) =>
        ["pending", "processing"].includes(exportCase.status)
      );
    },

    multipleExports() {
      return this.exportTypes.length > 1;
    },

    showStatusText() {
      return (
        !this.exportInProgress &&
        this.minutesSinceExport &&
        this.currentExportCases.length > 0
      );
    },

    statusText() {
      return this.multipleExports
        ? this.$t(
            "components.shared.export_modal.exports_created_w_minutes",
            {
              minutes: this.minutesSinceExport,
            },
            this.minutesSinceExport
          )
        : this.$t(
            "components.shared.export_modal.export_created_w_minutes",
            {
              minutes: this.minutesSinceExport,
            },
            this.minutesSinceExport
          );
    },
  },

  watch: {
    exportInProgress() {
      if (this.exportInProgress) {
        this.startPolling();
      } else {
        this.stopPolling();
      }
    },
  },

  beforeUnmount() {
    this.stopPolling();
    clearInterval(this.updateNowInterval);
  },

  methods: {
    async requestExports() {
      for (const exportType of this.exportTypes) {
        await this.requestExport(exportType);
      }
    },

    async requestExport(exportType) {
      try {
        const response = await axios.post(this.url("exports"), {
          export: {
            export_type: exportType,
            ...this.exportParams,
          },
        });
        this.exportCases.push(response.data);
      } catch (error) {
        this.handleError(error);
      }
    },

    async fetchExports(sentryLog = false) {
      try {
        this.loading = true;
        const response = await axios.get(this.url("exports"), {
          params: {
            export_types: this.exportTypes,
          },
        });
        this.exportCases = response.data;

        if (this.minutesSinceExport > VALID_FOR_MINUTES) {
          await this.requestExports();
        }
      } catch (error) {
        this.handleError(error, { sentryLog });
      } finally {
        this.loading = false;
      }
    },

    startPolling() {
      if (this.interval == null) {
        this.interval = setInterval(this.fetchExports, 3000);
      }
    },

    stopPolling() {
      clearInterval(this.interval);
      this.interval = null;
    },

    async modalOpened() {
      if (!this.directExport) {
        await this.fetchExports(true);
      }

      if (this.currentExportCases.length == 0 || this.directExport) {
        await this.requestExports();
      }

      if (this.exportInProgress) {
        this.startPolling();
      }

      this.updateNowInterval = setInterval(() => {
        this.now = new Date();
      }, 1000 * 10); // 10 seconds
    },

    modalHidden() {
      this.stopPolling();
    },
  },
};
</script>
