<template>
  <div>
    <be-button
      class="d-none d-lg-inline-block text-nowrap mb-3 mb-md-0"
      variant="outline-secondary"
      size="sm"
      icon="fa-comment"
      icon-style="far"
      inline
      @click="$emit('toggle-comments', { ...reference, mode: 'toggle' })"
    >
      {{ title }}
    </be-button>

    <be-button
      class="d-lg-none text-nowrap mb-3 mb-md-0"
      variant="outline-secondary"
      size="sm"
      icon="fa-comment"
      icon-style="far"
      inline
      @click="$emit('toggle-comments', { ...reference, mode: 'modal' })"
    >
      {{ title }}
    </be-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    reference: {
      type: Object,
      required: true,
    },
  },

  emits: ["toggle-comments"],

  computed: {
    ...mapGetters({
      commentsForReference: "comments/getCommentsForReference",
    }),

    numberOfComments() {
      return this.commentsForReference(this.reference).length;
    },

    title() {
      return this.$t("models.comment.comments_w_count", {
        count: this.numberOfComments,
      });
    },
  },
};
</script>
