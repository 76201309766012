<template>
  <div
    class="d-flex align-items-center justify-content-between w-100 rounded bg-light p-3 mb-2"
  >
    <template v-if="exportPending">
      <div class="d-flex align-items-center py-2">
        <be-spinner>
          {{ $t("components.shared.export_modal.creating_new_export") }}
        </be-spinner>
      </div>
    </template>

    <template v-else>
      <div class="d-flex align-items-center mr-2 py-1 text-break text-truncate">
        <i :class="`fal ${fileIconClass} fa-lg text-muted ml-1 mr-2`" />

        <span v-be-tooltip="exportCase.filename" class="text-truncate">
          {{ exportCase.filename }}
        </span>
      </div>

      <div class="d-flex align-items-center">
        <be-button
          :href="exportCase.paths.download"
          variant="primary"
          class="text-nowrap"
          icon="fa-arrow-down-to-bracket"
        >
          {{ $t("buttons.titles.download") }}
        </be-button>
      </div>
    </template>
  </div>
</template>

<script>
import { getClassNameForFilename } from "@/vendor/font-awesome-filetypes";

export default {
  props: {
    exportCase: {
      type: Object,
      required: true,
    },
  },

  computed: {
    exportPending() {
      return ["pending", "processing"].includes(this.exportCase.status);
    },

    fileIconClass() {
      return getClassNameForFilename(this.exportCase.filename);
    },
  },
};
</script>
