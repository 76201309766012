import axios from "axios";

/**
 * Fetches and caches data from Syna API
 *
 * Syna API represents data that change slowly,
 * there is no need to fetch data more than once
 * per session.
 */

function isIdentical(a, b) {
  return JSON.stringify(a) == JSON.stringify(b);
}

export const state = {
  companySuggestions: {
    loaded: false,
    source: "",
    items: [],
  },

  userSuggestions: {
    items: [],
  },
};

export const getters = {
  getUserSuggestionsForCompany: (state) => (companyId) => {
    const item = state.userSuggestions.items.find(
      (item) => item.company_id === companyId
    );
    if (!item || !item.people) {
      return [];
    }
    return item.people;
  },

  // Returns suggestions that are grouped so that a person has
  // identical functions in multiple companies, they become a single
  // suggestion. This is used in the client manager, where you can add
  // a multiple memberships (one person, multiple companies) at once.
  getGroupedUserSuggestions: (state) => {
    return state.userSuggestions.items.reduce(
      (groupedSuggestions, { company_id, people }) => {
        people.forEach((suggestion) => {
          const identicalSuggestion = groupedSuggestions.find((other) =>
            isIdentical(other.suggestion, suggestion)
          );
          if (identicalSuggestion) {
            identicalSuggestion.companies.push(company_id);
          } else {
            groupedSuggestions.push({
              suggestion,
              companies: [company_id],
            });
          }
        });

        return groupedSuggestions;
      },
      []
    );
  },

  getSharedCompanySuggestions: (state) => {
    return state.companySuggestions.items.filter(
      (item) => item.source === "companies"
    );
  },

  getPersonalCompanySuggestions: (state) => {
    return state.companySuggestions.items.filter(
      (item) => item.source === "identification_number"
    );
  },

  getAllCompanySuggestions: (state) => state.companySuggestions.items,
};

export const mutations = {
  setCompanySuggestions(state, { source, items }) {
    state.companySuggestions.loaded = true;
    state.companySuggestions.items = items;
    state.companySuggestions.source = source;
  },

  setUserSuggestions(state, { items }) {
    state.userSuggestions.items = [...state.userSuggestions.items, ...items];
  },
};

export const actions = {
  async loadCompanySuggestions({ state, commit }) {
    if (state.companySuggestions.loaded) {
      return;
    }

    try {
      await axios.get("/syna/my_companies").then(({ data }) => {
        if (data.status == "ok") {
          commit("setCompanySuggestions", data);
        }
      });
    } catch (error) {
      // Syna is icing on the cake, errors should go unnoticed
    }
  },

  async loadUserSuggestions({ state, commit }, companyId) {
    if (
      state.userSuggestions.items.some((item) => item.company_id === companyId)
    ) {
      return;
    }

    try {
      await axios
        .get("/syna/leadership", {
          params: { company_id: companyId },
        })
        .then(({ data }) => {
          if (data.status == "ok") {
            commit("setUserSuggestions", data);
          }
        });
    } catch (error) {
      // Syna is icing on the cake, errors should go unnoticed
    }
  },
};

export default {
  namespaced: true,
  sessionCached: true,
  state,
  getters,
  actions,
  mutations,
};
