/**
 * Hover directive
 *
 * This directive is used to listen to hover events on elements.
 *
 * Usage:
 *
 * <div v-be-hover="hoverHandler">Hover me</div>
 *
 * Heavily inspired by the hover directive from BootstrapVue
 * https://github.com/bootstrap-vue/bootstrap-vue/blob/dev/src/directives/hover/hover.js#L4
 */

// Property name we use to store info on root element
const PROPERTY = "__BE_hover_HANDLER__";

// Create a listener for hover events
const createListener = (handler) => {
  const listener = (event) => {
    handler(event.type === "mouseenter", event);
  };

  listener.fn = handler;
  return listener;
};

// Update hover listeners
const updateListeners = (on, el, listener) => {
  const method = on ? "addEventListener" : "removeEventListener";
  el[method]("mouseenter", listener);
  el[method]("mouseleave", listener);
};

// Directive bind/update/unbind handler
const directive = (el, { value: handler = null }) => {
  const listener = el[PROPERTY];
  const hasListener = !!listener && typeof listener === "function";
  const handlerChanged = !(hasListener && listener.fn === handler);

  if (hasListener && handlerChanged) {
    updateListeners(false, el, listener);
    delete el[PROPERTY];
  }

  if (typeof handler === "function" && handlerChanged) {
    el[PROPERTY] = createListener(handler);
    updateListeners(true, el, el[PROPERTY]);
  }
};

export default {
  beforeMount: directive,
  updated: directive,

  unmounted(el) {
    directive(el, { value: null });
  },
};
