<template>
  <span>{{ value.toLocaleString() }} {{ currency }}</span>
</template>

<script>
export default {
  props: {
    currency: {
      type: String,
      required: true,
    },

    value: {
      type: Number,
      required: true,
    },
  },
};
</script>
