import { createApp, defineComponent } from "vue";
import BePopover from "@/components/shared/BePopover.vue";

// Request animation frame polyfill
const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;

// Default trigger
const DEFAULT_TRIGGER = "click";

// Valid triggers
const VALID_TRIGGERS = ["click", "hover", "focus", "blur"];

// Modifier regex
const MODIFIER_REGEX = {
  placement:
    /^(auto|top(left|right)?|bottom(left|right)?|left(top|bottom)?|right(top|bottom)?)$/i,
};

// Parse bindings
const parseBindings = (bindings, vnode) => {
  // Default config
  let config = {
    title: "",
    content: "",
    trigger: null, // We set this below
    placement: "top",
    fallbackPlacement: "flip",
    container: "body",
    animation: true,
    offset: 0,
    id: undefined,
    interactive: true,
    disabled: false,
    delay: 0,
    boundary: "scrollParent",
    boundaryPadding: 5,
    customClass: null,
  };

  // Process `bindings.value`
  if (
    typeof bindings.value === "string" ||
    typeof bindings.value === "number"
  ) {
    // Value is a simple string/number, so set the content
    config.content = bindings.value.toString().trim();
  } else if (typeof bindings.value === "function") {
    // Content generator function
    config.content = bindings.value;
  } else if (typeof bindings.value === "object") {
    // Value is an object, so assume a config object
    config = { ...config, ...bindings.value };
  }

  // If title is not provided, try the title attribute instead
  if (!config.title) {
    config.title = vnode?.data?.attrs?.title || vnode?.elm?.title || "";
  }

  // Normalize delay
  if (typeof config.delay === "number" || typeof config.delay === "string") {
    config.delay = {
      show: parseInt(config.delay, 10) || 0,
      hide: parseInt(config.delay, 10) || 0,
    };
  }

  // If argument provided, assume it's the element ID of container element
  if (bindings.arg) {
    config.container = `#${bindings.arg}`;
  }

  // Process modifiers
  // We only allow placement modifiers, for now
  Object.keys(bindings.modifiers).forEach((modifier) => {
    if (MODIFIER_REGEX.placement.test(modifier)) {
      // Placement of popover
      config.placement = modifier;
    }
  });

  // Special handling of trigger modifiers when it's a space separated list
  const selectedTriggers = {};

  // Parse current config value
  Array.prototype
    .concat(config.trigger || "")
    .filter(Boolean)
    .join(" ")
    .toLowerCase()
    .split(/\s+/)
    .forEach((trigger) => {
      selectedTriggers[trigger] = true;
    });

  // Parse modifiers for triggers
  Object.keys(bindings.modifiers).forEach((modifier) => {
    if (VALID_TRIGGERS.includes(modifier)) {
      selectedTriggers[modifier] = true;
    }
  });

  // Sanitize triggers
  config.trigger = Object.keys(selectedTriggers)
    .filter(Boolean)
    .join(" ")
    .trim();

  // If the trigger is "blur" on its own, convert it to "focus"
  if (config.trigger === "blur") {
    config.trigger = "focus";
  }

  // Use default trigger if no trigger type is supplied
  if (!config.trigger) {
    config.trigger = DEFAULT_TRIGGER;
  }

  // Return the config
  return config;
};

const initPopoverConfig = (el, bindings, vnode) => {
  // Parse bindings
  const config = parseBindings(bindings, vnode);

  // Store config reference on target element
  el._popoverConfig = config;
};

// Initialize popover
const mountPopover = (el) => {
  // Return if popover is already mounted
  if (el._popover) {
    return;
  }

  // Fetch stored config from target element
  const config = el._popoverConfig;

  // Return if popover is disabled or title and content are empty
  if (config.disabled || (!config.title && !config.content)) {
    return;
  }

  // Create popover component instance
  const popover = defineComponent(BePopover);

  // Container
  const container = document.createElement("div");
  document.body.appendChild(container);

  // Create app
  const app = createApp(popover, {
    target: el,
    ...config,

    onHidden() {
      destroyPopover(el);
    },
  });

  // Mount popover
  const popoverInstance = app.mount(container);

  // Add references on target
  el._popoverApp = app;
  el._popoverContainer = container;
  el._popover = popoverInstance;

  // Show popover
  popoverInstance.show();
};

const unmountPopover = (el) => {
  // Return if popover is not mounted
  if (!el._popover) {
    return;
  }

  // Hide popover
  el._popover.hide();
};

const togglePopover = (el, vnode) => {
  // If popover is mounted, unmount it, otherwise mount it
  if (el._popover) {
    unmountPopover(el);
  } else {
    mountPopover(el, vnode);
  }
};

const destroyPopover = (el) => {
  if (el && el._popover) {
    el._popoverApp.unmount();
    el._popoverContainer.remove();

    el._popoverApp = null;
    el._popoverContainer = null;
    el._popover = null;
  }
};

// Bind trigger events
const bindTriggerEvents = (el, vnode) => {
  // Listen to trigger events on target and mount popover
  const triggers = el._popoverConfig.trigger.trim().split(/\s+/);
  triggers.forEach((trigger) => {
    if (trigger === "click") {
      el.addEventListener("click", () => togglePopover(el, vnode));
    } else if (trigger === "focus") {
      el.addEventListener("focusin", () => mountPopover(el, vnode));
      el.addEventListener("focusout", () => unmountPopover(el));
    } else if (trigger === "hover") {
      el.addEventListener("mouseenter", () => mountPopover(el, vnode));
      el.addEventListener("mouseleave", () => unmountPopover(el));
    } else if (trigger === "blur") {
      el.addEventListener("blur", () => unmountPopover(el));
    }
  });
};

// Unbind trigger events
const unbindTriggerEvents = (el, bindings, vnode) => {
  // If popover config is not set, set it
  if (!el._popoverConfig) {
    initPopoverConfig(el, bindings, vnode);
  }

  // Stop listening to trigger events on target
  const triggers = el._popoverConfig.trigger.trim().split(/\s+/);
  triggers.forEach((trigger) => {
    if (trigger === "click") {
      el.removeEventListener("click", () => togglePopover(el, vnode));
    } else if (trigger === "focus") {
      el.removeEventListener("focusin", () => mountPopover(el, vnode));
      el.removeEventListener("focusout", () => unmountPopover(el));
    } else if (trigger === "hover") {
      el.removeEventListener("mouseenter", () => mountPopover(el, vnode));
      el.removeEventListener("mouseleave", () => unmountPopover(el));
    } else if (trigger === "blur") {
      el.removeEventListener("blur", () => unmountPopover(el));
    }
  });
};

// Wrap element in a span
const wrapElement = (el) => {
  const wrapper = document.createElement("span");
  wrapper.classList.add("d-inline-block");
  wrapper.setAttribute("tabindex", "0");
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
  el._wrapped = true;
  return wrapper;
};

// Unwrap element
const unwrapElement = (el) => {
  const wrapper = el.parentNode;

  // If wrapper exists, and it's a span, unwrap it
  if (wrapper && wrapper.tagName === "SPAN") {
    wrapper.parentNode.insertBefore(el, wrapper);
    wrapper.parentNode.removeChild(wrapper);
  }

  // Remove wrapped flag
  delete el._wrapped;
};

export default {
  beforeMount(el, bindings, vnode) {
    requestAnimationFrame(() => {
      // If element is disabled, wrap it in a span and
      // apply the popover to the span instead
      if (el.disabled) {
        el = wrapElement(el);
      }

      // Initialize popover config
      initPopoverConfig(el, bindings, vnode);

      // Bind events to triggers
      bindTriggerEvents(el, vnode);
    });
  },

  updated(el, bindings, vnode) {
    // Unding trigger events
    unbindTriggerEvents(el, bindings, vnode);

    // Destroy popover
    destroyPopover(el);

    // If element was disabled and wrapped, unwrap it
    if (el._wrapped) {
      unwrapElement(el);
    }

    // If element is disabled, wrap it in a span and
    // apply the popover to the span instead
    if (el.disabled) {
      el = wrapElement(el);
    }

    // Initialize popover config
    initPopoverConfig(el, bindings, vnode);

    // Bind events to triggers
    bindTriggerEvents(el, vnode);
  },

  unmounted(el, bindings, vnode) {
    // If element was disabled and wrapped, unwrap it
    if (el._wrapped) {
      unwrapElement(el);
    }

    // Unbind trigger events
    unbindTriggerEvents(el, bindings, vnode);

    // Destroy popover
    destroyPopover(el);

    // Remove stored reference to config
    delete el._popoverConfig;
  },
};
