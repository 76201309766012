<template>
  <div v-if="invitations.length > 0" class="row">
    <div class="col-12 col-md-4 col-print-4 mb-3 mb-md-0">
      <agenda-invitations-column
        :invitations="regularsAndChairman"
        translation-scope="attendances"
      />
    </div>

    <div class="col-12 col-md-4 col-print-4 mb-3 mb-md-0">
      <agenda-invitations-column
        :invitations="alternate"
        translation-scope="alternate"
      />
    </div>

    <div class="col-12 col-md-4 col-print-4 mb-3 mb-md-0">
      <agenda-invitations-column
        :invitations="externals"
        translation-scope="externals"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import AgendaInvitationsColumn from "./AgendaInvitationsColumn.vue";
import materialMixin from "@/mixins/meetings/material";

export default {
  components: {
    AgendaInvitationsColumn,
  },

  mixins: [materialMixin],

  computed: {
    ...mapGetters({
      meeting: "material/meeting",
    }),

    regularsAndChairman() {
      return this.invitations.filter((invitation) => {
        return ["chairman", "regular"].includes(invitation.function);
      });
    },

    alternate() {
      return this.invitations.filter((invitation) => {
        return ["alternate"].includes(invitation.function);
      });
    },

    externals() {
      return this.invitations.filter((invitation) => {
        return ["external"].includes(invitation.function);
      });
    },

    invitations() {
      return this.getInvitations(this.meeting).filter(
        (invitation) => invitation.id
      );
    },
  },

  mounted() {
    this.fetchInvitationsForMeeting(this.meeting);
  },
};
</script>
