<template>
  <div>
    <div v-if="noMarkUp">
      {{ activityString }}
    </div>

    <div
      v-else
      class="border border-top-0 border-right-0 border-bottom-1 border-left-0 py-3 px-4"
    >
      <div class="d-flex align-items-center mb-2">
        <div v-if="activity.owner_name" class="d-inline-flex">
          <user-avatar :avatar="activity.avatar" />
        </div>

        <div class="ml-2">
          <h6 class="my-0">{{ activity.owner_name }}</h6>

          <small v-if="activity.created_at">
            {{ $d(new Date(activity.created_at), "dateTime") }}
          </small>
        </div>
      </div>

      <div class="small">
        <em>
          <template v-if="preText">
            {{ preText }}
          </template>

          {{ activityString }}
        </em>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },

    noMarkUp: {
      type: Boolean,
      required: false,
      default: false,
    },

    preText: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      activity: this.cloneDeep(this.item),
    };
  },

  computed: {
    activityString() {
      const statusChanges = this.activity?.parameters?.changes?.status;

      return this.$i18n.t("public_activity_beta.limited." + this.activity.key, {
        date: this.$i18n.d(new Date(this.activity.created_at), "date"),
        user: this.activity.owner_name,

        recipient: this.activity.recipient_name
          ? this.activity.recipient_name
          : "",

        trackable_date: this.activity.trackable_start_at
          ? this.$i18n.d(new Date(this.activity.trackable_start_at), "date")
          : "",

        status_from:
          statusChanges && statusChanges[0]
            ? this.$i18n.t(`models.decision.statuses.${statusChanges[0]}`)
            : "",

        status_to:
          statusChanges && statusChanges[1]
            ? this.$i18n.t(`models.decision.statuses.${statusChanges[1]}`)
            : "",
      });
    },
  },
};
</script>
