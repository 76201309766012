import * as messages from "./translations.json";

const currencies = {
  SEK: {
    style: "currency",
    currency: "SEK",
    currencyDisplay: "code",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },

  EUR: {
    style: "currency",
    currency: "EUR",
    currencyDisplay: "code",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },

  USD: {
    style: "currency",
    currency: "USD",
    currencyDisplay: "code",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
};

const dateFormats = {
  date: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },

  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },

  humanReadable: {
    day: "numeric",
    month: "short",
    year: "numeric",
  },

  // 24 dec. 2020 12:35
  humanReadableWithTime: {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  },

  localShort: {
    month: "short",
    day: "numeric",
  },

  year_month: {
    month: "short",
    year: "numeric",
  },

  month: {
    month: "short",
  },

  dateTime: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },

  time: {
    hour: "numeric",
    minute: "numeric",
  },

  monthDayShort: {
    month: "short",
    day: "numeric",
  },
};

export default {
  messages: messages,

  datetimeFormats: {
    en: dateFormats,
    sv: dateFormats,
  },

  currencyFormats: {
    en: currencies,
    sv: currencies,
  },
};
