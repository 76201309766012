export default {
  props: {
    ariaInvalid: {
      type: [Boolean, String],
      required: false,
      default: false,
    },

    autocomplete: {
      type: String,
      required: false,
      default: undefined,
    },

    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },

    debounce: {
      type: [Number, String],
      required: false,
      default: 0,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    form: {
      type: String,
      required: false,
      default: null,
    },

    formatter: {
      type: Function,
      required: false,
      default: undefined,
    },

    lazy: {
      type: Boolean,
      required: false,
      default: false,
    },

    lazyFormatter: {
      type: Boolean,
      required: false,
      default: false,
    },

    name: {
      type: String,
      required: false,
      default: null,
    },

    number: {
      type: Boolean,
      required: false,
      default: false,
    },

    placeholder: {
      type: String,
      required: false,
      default: undefined,
    },

    plaintext: {
      type: Boolean,
      required: false,
      default: false,
    },

    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    trim: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: [
    "update",
    "input",
    "change",
    "focus",
    "blur",
    "keyup",
    "keydown",
    "update:modelValue",
    "paste",
  ],

  data() {
    return {
      localValue: this.formatValue(this.modelValue),
      vModelValue: this.modifyValue(this.modelValue),
      inFocus: false,
    };
  },

  computed: {
    computedClass() {
      const { plaintext, size } = this;

      return [
        this.stateClass,
        {
          "form-control": !plaintext,
          "form-control-plaintext": plaintext,
          [`form-control-${size}`]: size,
        },
      ];
    },

    computedRequired() {
      if (this.required) {
        return this.required;
      } else if (this.isGroup && this.beGroup) {
        return this.beGroup.localRequired;
      } else {
        return false;
      }
    },

    computedDebounce() {
      // Ensure debounce is a positive number
      return Math.max(Number(this.debounce), 0);
    },

    hasFormatter() {
      return typeof this.formatter === "function";
    },

    isGroup() {
      // Check if the parent is a `BeFormGroup` component
      if (this.$parent && this.$parent.$options.name === "BeFormGroup") {
        return true;
      } else if (
        // Check if the parent's parent is a `BeFormGroup` component
        this.$parent.$parent &&
        this.$parent.$parent.$options.name === "BeFormGroup"
      ) {
        return true;
      } else {
        return false;
      }
    },

    beGroup() {
      if (!this.isGroup) {
        return null;
      }

      // Check if the parent is a `BeFormGroup` component
      if (this.$parent && this.$parent.$options.name === "BeFormGroup") {
        return this.$parent;
      } else if (
        // Check if the parent's parent is a `BeFormGroup` component
        this.$parent.$parent &&
        this.$parent.$parent.$options.name === "BeFormGroup"
      ) {
        return this.$parent.$parent;
      }
    },
  },

  watch: {
    modelValue(newValue) {
      if (this.inFocus) {
        return;
      }

      if (newValue === undefined || newValue === null) {
        newValue = "";
      }

      const stringifyValue = String(newValue);
      const modifiedValue = this.modifyValue(newValue);

      if (
        stringifyValue !== this.localValue ||
        modifiedValue !== this.vModelValue
      ) {
        // Clear any pending debounce timeout, as we are overwriting the user input
        this.clearDebounce();

        // Update the local values
        this.localValue = stringifyValue;
        this.vModelValue = modifiedValue;
      }
    },
  },

  created() {
    this.$_inputDebounceTimer = null;
  },

  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    }

    if (this.hasFormatter) {
      const formattedValue = this.formatValue(this.modelValue, null, true);
      this.localValue = formattedValue;
      this.vModelValue = this.modifyValue(formattedValue);
    }
  },

  beforeUnmount() {
    this.clearDebounce();
  },

  methods: {
    clearDebounce() {
      clearTimeout(this.$_inputDebounceTimer);
      this.$_inputDebounceTimer = null;
    },

    formatValue(value, event, force = false) {
      if (value === undefined || value === null) {
        value = "";
      } else {
        value = String(value);
      }

      if (this.hasFormatter && (!this.lazyFormatter || force)) {
        value = this.formatter(value, event);
      }

      return value;
    },

    modifyValue(value) {
      if (value === undefined || value === null) {
        return "";
      }

      value = String(value);

      // Emulate `.trim` modifier behaviour
      if (this.trim) {
        value = value.trim();
      }

      // Emulate `.number` modifier behaviour
      if (this.number) {
        value = Number(value);
      }

      return value;
    },

    updateValue(value, force = false) {
      const { lazy } = this;
      if (lazy && !force) {
        return;
      }

      // Make sure we always clear the debounce when `updateValue()`
      // is called, even when the v-model hasn't changed
      this.clearDebounce();

      // Define the shared update logic in a method to be able to use
      // it for immediate and debounces value changes
      const doUpdate = () => {
        value = this.modifyValue(value);

        if (value !== this.vModelValue) {
          this.vModelValue = value;
          this.$emit("update", value);
          this.$emit("input", value);
          this.$emit("update:modelValue", value);
        } else if (this.hasFormatter) {
          // When the `vModelValue` hasn't changed but the actual input value
          // is out of sync, make sure to change it to the given one
          // Usually caused by browser autocomplete and how it triggers the
          // change or input event, or depending on the formatter function
          const $input = this.$refs.input;
          if ($input && value !== $input.value) {
            $input.value = value;
          }
        }
      };

      // Only debounce the value update when a value greater than `0`
      // is set and we are not in lazy mode or this is a forced update
      const debounce = this.computedDebounce;
      if (debounce > 0 && !lazy && !force) {
        this.$_inputDebounceTimer = setTimeout(doUpdate, debounce);
      } else {
        // Immediately update the v-model
        doUpdate();
      }
    },

    focus() {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },

    blur() {
      if (this.$refs.input) {
        this.$refs.input.blur();
      }
    },

    onInput(event) {
      const { value } = event.target;
      const formattedValue = this.formatValue(value, event);

      // Exit when the `formatter` function strictly returned `false`
      // or prevented the input event
      if (formattedValue === false || event.defaultPrevented) {
        event.preventDefault();
        return;
      }

      this.localValue = formattedValue;
      this.updateValue(formattedValue);
    },

    onChange(event) {
      const { value } = event.target;
      const formattedValue = this.formatValue(value, event);

      // Exit when the `formatter` function strictly returned `false`
      // or prevented the input event
      if (formattedValue === false || event.defaultPrevented) {
        event.preventDefault();
        return;
      }

      this.localValue = formattedValue;
      this.updateValue(formattedValue, true);
      this.$emit("change", formattedValue);
    },

    onFocus(event) {
      this.$emit("focus", event);
      this.inFocus = true;
    },

    onBlur(event) {
      // Apply the `localValue` on blur to prevent cursor jumps
      // on mobile browsers (e.g. caused by autocomplete)
      const { value } = event.target;
      const formattedValue = this.formatValue(value, event, true);

      if (formattedValue !== false) {
        // We need to use the modified value here to apply the
        // `.trim` and `.number` modifiers properly
        this.localValue = String(this.modifyValue(formattedValue));

        // We pass the formatted value here since the `updateValue()`
        // method handles the modifiers itself
        this.updateValue(formattedValue, true);
      }

      this.$emit("blur", event);
      this.inFocus = false;
    },

    onKeyup(event) {
      this.$emit("keyup", event);
    },

    onKeydown(event) {
      this.$emit("keydown", event);
    },

    onPaste(event) {
      this.$emit("paste", event);
    },
  },
};
