<template>
  <be-teleport to="body">
    <transition
      enter-active-class="fade"
      enter-to-class="show"
      leave-class="show"
      leave-active-class="fade"
      @before-enter="onBeforeEnter"
      @after-enter="onAfterEnter"
      @before-leave="onBeforeLeave"
      @after-leave="onAfterLeave"
    >
      <div
        v-if="isVisible"
        :id="id"
        ref="popover"
        :class="computedClasses"
        role="tooltip"
        tabindex="-1"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
        @focusin="onFocusIn"
        @focusout="onFocusOut"
      >
        <div ref="arrow" class="arrow" />

        <h3
          v-if="title || $slots.title"
          :class="['popover-header', popoverHeaderClass]"
        >
          <slot name="title">
            {{ title }}
          </slot>
        </h3>

        <div
          v-if="content || $slots.default"
          :class="['popover-body', popoverBodyClass]"
        >
          <slot>
            {{ content }}
          </slot>
        </div>
      </div>
    </transition>
  </be-teleport>
</template>

<script>
import Popper from "popper.js";
import debounce from "lodash/debounce";
import { EventBus } from "@/event-bus";
import { generateId } from "@/utils/id";

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;

const RAPID_HOVER_THRESHOLD = 500;

export default {
  name: "BePopover",

  props: {
    boundary: {
      type: [HTMLElement, Object, String],
      required: false,
      default: "window",
    },

    boundaryPadding: {
      type: [Number, String],
      required: false,
      default: 5,
    },

    content: {
      type: String,
      required: false,
      default: undefined,
    },

    customClass: {
      type: String,
      required: false,
      default: undefined,
    },

    delay: {
      type: [Array, Number, Object, String],
      required: false,
      default: 50,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    fallbackPlacement: {
      type: [Array, String],
      required: false,
      default: "flip",
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("popover"),
    },

    interactive: {
      type: Boolean,
      required: false,
      default: true,
    },

    offset: {
      type: [Number, String],
      required: false,
      default: 0,
    },

    placement: {
      type: String,
      required: false,
      default: "top",
    },

    popoverBodyClass: {
      type: [String, Array, Object],
      required: false,
      default: "",
    },

    popoverHeaderClass: {
      type: [String, Array, Object],
      required: false,
      default: "",
    },

    target: {
      type: [HTMLElement, String],
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: undefined,
    },

    trigger: {
      type: [String, Array],
      required: false,
      default: "click",
    },
  },

  emits: [
    "focusin",
    "focusout",
    "hidden",
    "hide",
    "mouseenter",
    "mouseleave",
    "show",
    "shown",
  ],

  data() {
    return {
      attachment: null,
      isClosing: false,
      isVisible: false,
      showTimeout: null,
      hideTimeout: null,
      showDebounced: null,
      hideDebounced: null,
    };
  },

  computed: {
    computedClasses() {
      return [
        "popover",
        "be-popover",
        [`bs-popover-${this.computedPlacementClass}`],
        {
          noninteractive: !this.interactive,
        },
        this.customClass,
      ];
    },

    computedDelay() {
      const delay = { show: 0, hide: 0 };
      // If delay is a number or a string
      if (typeof this.delay === "number" || typeof this.delay === "string") {
        delay.show = delay.hide = parseInt(this.delay, 10);
      } else if (typeof this.delay === "object") {
        // If delay is an object
        delay.show = parseInt(this.delay.show, 10) || 0;
        delay.hide = parseInt(this.delay.hide, 10) || 0;
      } else if (Array.isArray(this.delay)) {
        // If delay is an array
        delay.show = parseInt(this.delay[0], 10) || 0;
        delay.hide = parseInt(this.delay[1], 10) || 0;
      }
      return delay;
    },

    computedPlacementClass() {
      if (this.attachment) {
        return this.attachment;
      }
      switch (this.placement) {
        case "topright":
        case "topleft":
          return "top";
        case "bottomright":
        case "bottomleft":
          return "bottom";
        case "righttop":
        case "rightbottom":
          return "right";
        case "lefttop":
        case "leftbottom":
          return "left";
        default:
          return this.placement;
      }
    },

    computedPlacement() {
      switch (this.placement) {
        case "topright":
          return "top-end";
        case "topleft":
          return "top-start";
        case "bottomright":
          return "bottom-end";
        case "bottomleft":
          return "bottom-start";
        case "righttop":
          return "right-start";
        case "rightbottom":
          return "right-end";
        case "lefttop":
          return "left-start";
        case "leftbottom":
          return "left-end";
        default:
          return this.placement;
      }
    },

    computedTriggers() {
      if (typeof this.trigger === "string") {
        return this.trigger.split(" ");
      } else if (Array.isArray(this.trigger)) {
        return this.trigger;
      } else {
        return [];
      }
    },
  },

  watch: {
    disabled() {
      if (this.disabled) {
        this.hide(true);
      }
    },

    title() {
      this.updatePopper();
    },
  },

  mounted() {
    // Create a debounced version of the `show` method to handle rapid hover events
    this.showDebounced = debounce(this.show, RAPID_HOVER_THRESHOLD, {
      leading: true,
      trailing: false,
    });

    // Create a debounced version of the `hide` method to handle rapid hover events
    this.hideDebounced = debounce(this.hide, RAPID_HOVER_THRESHOLD, {
      leading: true,
      trailing: false,
    });

    if (this.target) {
      // Bind trigger events to target
      this.bindTriggerEvents();
    }

    // If another popover is opened, hide this one
    EventBus.on("be::popover::show", (id) => {
      if (id && id !== this.id && this.isVisible) {
        this.hide(true);
      }
    });

    // Listen to global EventBus show event
    EventBus.on("be::show::popover", (id) => {
      // If an ID is passed, show popover if this popover ID matches
      if (id && id === this.id) {
        this.show();
      }
    });

    // Listen to global EventBus hide event
    EventBus.on("be::hide::popover", (id) => {
      // If an ID is passed, hide popover if this popover ID matches,
      // otherwise hide all popovers
      if (id && id === this.id) {
        this.hide();
      } else {
        this.hide(true);
      }
    });

    // Listen to global EventBus toggle event
    EventBus.on("be::toggle::popover", (id) => {
      // If an ID is passed, toggle popover if this popover ID matches
      if (id && id === this.id) {
        this.toggle();
      }
    });
  },

  beforeUnmount() {
    // Remove popover element from DOM
    requestAnimationFrame(() => {
      this.$el.parentNode?.removeChild(this.$el);
    });

    // Unbind trigger events from target
    this.unbindTriggerEvents();
  },

  methods: {
    show() {
      // Reset `isClosing` flag
      this.isClosing = false;

      // Clear any pending timeouts
      this.clearTimeouts();

      // Cancel show if popover:
      // * Is disabled
      // * Title and content is empty and default slot is not used
      if (
        this.disabled ||
        (!this.title && !this.content && !this.$slots.default)
      ) {
        return;
      }

      // Wait for the defined delay
      this.showTimeout = setTimeout(() => {
        // Show popover in next tick to ensure DOM is ready
        this.$nextTick(() => {
          this.isVisible = true;

          // Set `aria-describedby` on target
          this.setAriaDescribedBy();

          // Temporarily remove `title` attribute on target to prevent
          // native tooltip from showing up
          this.removeTitleAttribute();

          // Initialize Popper and focus on popover
          this.$nextTick(() => {
            requestAnimationFrame(() => {
              this.initPopper();
            });
          });
        });
      }, this.computedDelay.show);
    },

    hide(force = false) {
      // Clear any pending timeouts
      this.clearTimeouts();

      // Set the `isClosing` flag
      this.isClosing = true;

      // Cancel hide if popover is disabled and hide is not forced
      if (this.disabled && !force) {
        return;
      }

      // Wait for the defined delay
      this.hideTimeout = setTimeout(() => {
        // If `isClosing` is false, it means the popover was hovered
        // before the delay ended, so we'll cancel the hide
        if (!this.isClosing && !force) {
          return;
        }

        // Hide popover
        this.isVisible = false;

        // Remove `aria-describedby` from target
        this.removeAriaDescribedBy();

        // Restore `title` attribute on target
        this.restoreTitleAttribute();

        // Reset `isClosing` flag
        this.isClosing = false;
      }, this.computedDelay.hide);
    },

    toggle() {
      if (this.disabled) {
        return;
      }

      if (this.isVisible) {
        this.hide();
      } else {
        this.show();
      }
    },

    initPopper() {
      // Return if popover element doesn't exist
      if (!this.$refs.popover) {
        return;
      }

      // If an existing popper instance exists, destroy it first
      if (this._popper) {
        this._popper.destroy();
      }

      // Get target element
      const target = this.getTarget() || this.$refs.popover.parentNode;

      // Instantiate Popper
      this._popper = new Popper(target, this.$refs.popover, {
        placement: this.computedPlacement,

        modifiers: {
          arrow: {
            element: ".arrow",
          },

          flip: {
            behavior: this.fallbackPlacement,
          },

          offset: {
            offset: this.offset,
          },

          preventOverflow: {
            boundariesElement: this.boundary,
            padding: this.boundaryPadding,
          },
        },

        onCreate: (data) => {
          // Handle flipping arrow classes
          if (data.originalPlacement !== data.placement) {
            this.attachment = data.placement;
          } else {
            this.attachment = null;
          }
        },

        onUpdate: (data) => {
          // Handle flipping arrow classes
          if (data.originalPlacement !== data.placement) {
            this.attachment = data.placement;
          } else {
            this.attachment = null;
          }
        },
      });
    },

    updatePopper() {
      if (this._popper) {
        this._popper.scheduleUpdate();
      }
    },

    destroyPopper() {
      if (this._popper) {
        this._popper.destroy();
        this._popper = null;
      }

      delete this._popper;
    },

    clearTimeouts() {
      clearTimeout(this.showTimeout);
      clearTimeout(this.hideTimeout);
    },

    bindTriggerEvents() {
      const target = this.getTarget();

      if (!target) {
        return;
      }

      // Listen to trigger events on target
      this.computedTriggers.forEach((trigger) => {
        if (trigger === "click") {
          target.addEventListener("click", this.toggleHandler);
        } else if (trigger === "hover") {
          target.addEventListener("mouseenter", this.showDebounced);
          target.addEventListener("mouseleave", this.hideDebounced);
        } else if (trigger === "focus") {
          target.addEventListener("focusin", this.showDebounced);
          target.addEventListener("focusout", this.hideDebounced);
        } else if (trigger === "blur") {
          target.addEventListener("blur", this.hideDebounced);
        }
      });
    },

    unbindTriggerEvents() {
      const target = this.getTarget();

      if (!target) {
        return;
      }

      // Remove trigger events on target
      this.computedTriggers.forEach((trigger) => {
        if (trigger === "click") {
          target.removeEventListener("click", this.toggleHandler);
        } else if (trigger === "hover") {
          target.removeEventListener("mouseenter", this.showDebounced);
          target.removeEventListener("mouseleave", this.hideDebounced);
        } else if (trigger === "focus") {
          target.removeEventListener("focusin", this.showDebounced);
          target.removeEventListener("focusout", this.hideDebounced);
        } else if (trigger === "blur") {
          target.removeEventListener("blur", this.hideDebounced);
        }
      });
    },

    toggleHandler() {
      this.toggle();
    },

    getTarget() {
      // If target is a string, we need to fetch it from the DOM,
      // otherwise we'll assume it's an element
      if (typeof this.target === "string") {
        // If string contains "#" or ".", assume it's a CSS selector,
        // otherwise assume it's an id
        if (this.target.includes("#") || this.target.includes(".")) {
          return document.querySelector(this.target);
        } else {
          return document.getElementById(this.target);
        }
      } else {
        return this.target;
      }
    },

    setAriaDescribedBy() {
      const target = this.getTarget();

      if (!target) {
        return;
      }

      // Get current `aria-describedby` value
      const ariaDescribedBy = target.getAttribute("aria-describedby");

      // If `aria-describedby` exists, append tooltip ID
      if (ariaDescribedBy) {
        target.setAttribute(
          "aria-describedby",
          `${ariaDescribedBy} ${this.id}`
        );
      } else {
        target.setAttribute("aria-describedby", this.id);
      }
    },

    removeAriaDescribedBy() {
      const target = this.getTarget();

      if (!target) {
        return;
      }

      // Get current `aria-describedby` value
      const ariaDescribedBy = target.getAttribute("aria-describedby");

      // If `aria-describedby` exists, remove tooltip ID
      if (ariaDescribedBy) {
        const ids = ariaDescribedBy
          .split(" ")
          .filter((id) => id !== this.id)
          .join(" ");

        if (ids) {
          target.setAttribute("aria-describedby", ids);
        } else {
          target.removeAttribute("aria-describedby");
        }
      }
    },

    removeTitleAttribute() {
      const target = this.getTarget();

      if (!target) {
        return;
      }

      // Get current `title` value
      const title = target.getAttribute("title");

      // If `title` exists, store it in `data-be-original-title`
      // and remove `title` attribute
      if (title) {
        target.setAttribute("data-be-original-title", title);
        target.removeAttribute("title");
      }
    },

    restoreTitleAttribute() {
      const target = this.getTarget();

      if (!target) {
        return;
      }

      // Get current `data-be-original-title` value
      const originalTitle = target.getAttribute("data-be-original-title");

      // If `data-be-original-title` exists, restore `title` attribute
      if (originalTitle) {
        target.setAttribute("title", originalTitle);
        target.removeAttribute("data-be-original-title");
      }
    },

    emitEvent(event) {
      this.$emit(event, this.id);
      EventBus.emit(`be::popover::${event}`, this.id);
    },

    onMouseEnter() {
      clearTimeout(this.hideTimeout);
      this.emitEvent("mouseenter");
      this.isClosing = false;
    },

    onMouseLeave(event) {
      this.emitEvent("mouseleave");

      // Hide the popover if the trigger is `hover`,
      // unless the trigger target is focused
      if (this.computedTriggers.includes("hover")) {
        const popover = this.$refs.popover;
        const target = this.getTarget();

        // Check if the mouse is not over the popover or the target
        if (
          !popover.contains(event.relatedTarget) &&
          (!target || !target.contains(event.relatedTarget))
        ) {
          this.hide();
        }
      }
    },

    onFocusIn() {
      this.emitEvent("focusin");
    },

    onFocusOut() {
      this.emitEvent("focusout");

      // Hide the popover if the trigger is `focus`,
      // unless the trigger target is focused
      if (this.computedTriggers.includes("focus")) {
        const target = this.getTarget();

        if (target && !target.contains(document.activeElement)) {
          this.hide();
        }
      }
    },

    onBeforeEnter() {
      this.emitEvent("show");
      this.isClosing = false;
    },

    onAfterEnter() {
      this.emitEvent("shown");
    },

    onBeforeLeave() {
      this.emitEvent("hide");
    },

    onAfterLeave() {
      this.emitEvent("hidden");
      this.isClosing = false;

      // Ensure popper is destroyed
      this.destroyPopper();

      // Ensure popover is hidden
      this.isVisible = false;
    },
  },
};
</script>
