import { EventBus } from "@/event-bus";
export default {
  methods: {
    documentPrepareSignature(document, signature) {
      return {
        id: signature.id,
        user_id: signature.user_id,
        name: signature.user_name,
        signed_at: signature.signed_at,
        requested_at: signature.created_at,
        reminded_at: this.documentSignatureRemindedAt(signature),
        rejected_at: signature.rejected_at,
        rejection_message: signature.rejection_message,
        remindable: signature.remindable && signature.policy?.remind,
        sub_name: signature.user_id ? null : signature.email,
        path: document.paths.sign,
      };
    },

    // `reminded_at` should not be triggered if notified_at was only set on creation.
    documentSignatureRemindedAt(signature) {
      if (!signature.notified_at) {
        return null;
      }

      const notifiedAt = new Date(signature.notified_at);
      const createdAt = new Date(signature.created_at);

      // If notified_at is within 30 s och created_at we skip it
      // We create the signature and create the Assently case before
      // we set signature.notified_at.
      const thirtySeconds = 30 * 1000;
      if (Math.abs(notifiedAt - createdAt) < thirtySeconds) {
        return null;
      }

      return signature.notified_at;
    },

    async sendDocumentSignatureReminder(signature) {
      try {
        const { data } = await axios.post(
          this.url(`/documents/signatures/${signature.id}/reminder`)
        );

        EventBus.emit("signature-updated", data);
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
