const icons = {
  image: "fa-file-image",
  pdf: "fa-file-pdf",
  word: "fa-file-word",
  powerpoint: "fa-file-powerpoint",
  excel: "fa-file-excel",
  audio: "fa-file-audio",
  video: "fa-file-video",
  zip: "fa-file-archive",
  code: "fa-file-code",
  text: "fa-file-alt",
  file: "fa-file",
};

export default icons;
