export const state = {
  params: [],
};

export const getters = {
  getParam: (state) => (key) => {
    return state.params.find((param) => param.key === key)?.value;
  },
};

export const actions = {
  initialize({ commit }) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.forEach((value, key) => {
      commit("setValue", { key, value });
    });
  },

  setParam({ commit }, { key, value }) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const search = searchParams.toString();
    history.replaceState(null, "", `${window.location.pathname}?${search}`);
    commit("setValue", { key, value });
  },

  toggleParam({ commit, getters }, key) {
    const value = !getters.getParam(key);
    const searchParams = new URLSearchParams(window.location.search);
    if (value) {
      searchParams.set(key, "true");
    } else {
      searchParams.delete(key);
    }

    const search = searchParams.toString();
    history.replaceState(null, "", `${window.location.pathname}?${search}`);
    commit("setValue", { key, value });
  },

  clearParam({ commit }, key) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);
    const search = searchParams.toString();
    history.replaceState(null, "", `${window.location.pathname}?${search}`);
    commit("removeParam", key);
  },
};

export const mutations = {
  setValue(state, { key, value }) {
    const index = state.params.findIndex((param) => param.key === key);
    if (index === -1) {
      state.params.push({ key, value });
    } else {
      state.params[index].value = value;
    }
  },

  removeParam(state, key) {
    state.params = state.params.filter((param) => param.key !== key);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
