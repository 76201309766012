/**
 * BeEvent
 *
 * A simple event class that can be used to create custom events.
 */

export class BeEvent {
  constructor(type, eventInit = {}) {
    if (!type) {
      throw new TypeError(
        `Failed to construct '${this.constructor.name}'. 1 argument required, ${arguments.length} given.`
      );
    }

    // Merge defaults first, then constructor defaults, then eventInit and finally type
    // so that type cannot be overridden
    Object.assign(
      this,
      BeEvent.Defaults,
      this.constructor.Defaults,
      eventInit,
      { type }
    );

    // Freeze some props as readonly, but leave them enumerable
    Object.defineProperties(this, {
      type: { enumerable: true, configurable: false, writable: false },
      cancelable: { enumerable: true, configurable: false, writable: false },
      nativeEvent: { enumerable: true, configurable: false, writable: false },
      target: { enumerable: true, configurable: false, writable: false },
      relatedTarget: { enumerable: true, configurable: false, writable: false },
      vueTarget: { enumerable: true, configurable: false, writable: false },
      componentId: { enumerable: true, configurable: false, writable: false },
    });

    // Create a private variable using closure scoping
    let defaultPrevented = false;

    // Recreate preventDefault() method, one way setter
    this.preventDefault = function preventDefault() {
      if (this.cancelable) {
        defaultPrevented = true;
      }
    };

    // Create `defaultPrevented` publicly accessible prop that is readonly
    Object.defineProperty(this, `defaultPrevented`, {
      enumerable: true,
      configurable: false,

      get() {
        return defaultPrevented;
      },
    });
  }

  static get Defaults() {
    return {
      type: "",
      cancelable: true,
      nativeEvent: null,
      target: null,
      relatedTarget: null,
      vueTarget: null,
      componentId: null,
    };
  }
}
