<template>
  <single-activity
    v-if="activities.length > 0 && owner"
    :activity="activities[0]"
    :use-markup="true"
    include-date
    include-owner
  />
</template>

<script>
import SingleActivity from "@/components/activities/SingleActivity.vue";

export default {
  components: {
    SingleActivity,
  },

  props: {
    owner: {
      type: Object,
      required: true,
    },

    acceptedKeys: {
      type: Array,
      required: true,
    },
  },

  computed: {
    activities() {
      return (
        this.owner.activities?.filter((activity) => {
          return this.acceptedKeys.includes(activity.key);
        }) || []
      );
    },
  },
};
</script>
