/**
 * Initialize the event bus using the mitt library.
 */
import mitt from "mitt";

const EventBus = mitt();

// Extending the EventBus with a `once` method that allows
// us to listen for an event only once.
EventBus.once = (type, handler) => {
  const fn = (...args) => {
    EventBus.off(type, fn);
    handler(args);
  };

  EventBus.on(type, fn);
};

export { EventBus };
