<template>
  <component
    v-bind="computedAttrs"
    :is="computedTag"
    :class="computedClasses"
    :style="computedStyles"
    :disabled="disabled || null"
    @click="onClick"
  >
    <span
      v-if="!localSrc && !text"
      :class="['be-avatar-custom', contentClasses]"
    >
      <slot>
        <i class="fas fa-user" />
      </slot>
    </span>

    <span v-else-if="localSrc" :class="['be-avatar-img', contentClasses]">
      <img
        :alt="alt"
        :aria-label="ariaLabel"
        :src="localSrc"
        class="w-100 h-100"
        @error="onImgError"
      />
    </span>

    <span
      v-else-if="text"
      :style="textFontStyle"
      :class="[
        'be-avatar-text',
        'text-uppercase',
        'text-nowrap',
        contentClasses,
      ]"
    >
      {{ text }}
    </span>
  </component>
</template>

<script>
// Constants
const SIZES = ["sm", null, "lg"];
const FONT_SIZE_SCALE = 0.4;

// Helper methods
const computeSize = (value) => {
  // Parse to number when value is a float-like string
  if (typeof value === "string" && value.match(/^[0-9]*\.?[0-9]+$/)) {
    value = Number(value);
  }

  // Convert the number to pixel value
  return typeof value === "number" ? `${value}px` : value || null;
};

export default {
  name: "BeAvatar",

  props: {
    alt: {
      type: String,
      required: false,
      default: "Avatar",
    },

    ariaLabel: {
      type: String,
      required: false,
      default: "Avatar",
    },

    button: {
      type: Boolean,
      required: false,
      default: false,
    },

    buttonType: {
      type: String,
      required: false,
      default: "button",

      validator: (value) => {
        return ["button", "submit", "reset"].includes(value);
      },
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    href: {
      type: String,
      required: false,
      default: null,
    },

    rel: {
      type: String,
      required: false,
      default: "noopener noreferrer",
    },

    size: {
      type: [Number, String],
      required: false,
      default: null,
    },

    src: {
      type: String,
      required: false,
      default: undefined,
    },

    target: {
      type: String,
      required: false,
      default: "_self",
    },

    text: {
      type: String,
      required: false,
      default: undefined,
    },

    variant: {
      type: String,
      required: false,
      default: "secondary",
    },
  },

  emits: ["click", "img-error"],

  data() {
    return {
      localSrc: this.src,
    };
  },

  computed: {
    beAvatarGroup() {
      if (this.$parent.$options.name === "BeAvatarGroup") {
        return this.$parent;
      }

      return null;
    },

    computedAttrs() {
      const { button, buttonType, href, rel, target } = this;
      return {
        "aria-label": this.ariaLabel,
        type: button ? buttonType : null,
        href: href || null,
        rel: href ? rel : null,
        target: href ? target : null,
      };
    },

    computedClasses() {
      const {
        button,
        computedSize: size,
        computedVariant: variant,
        disabled,
        href,
      } = this;
      return [
        "be-avatar",
        "d-inline-flex",
        "align-items-center",
        "justify-content-center",
        "align-middle",
        "flex-shrink-0",
        "font-weight-bold",
        "text-reset-line-height",
        "text-center",
        "position-relative",
        "overflow-visible",
        "rounded-circle",
        disabled,
        {
          [`be-avatar-${size}`]: size && SIZES.includes(size),
          [`badge-${variant}`]: !button && variant,
          [`btn-${variant}`]: button && variant,
          btn: button,
          "p-0": button || href,
        },
      ];
    },

    computedSize() {
      // Avatar Group size takes precedence
      const { beAvatarGroup } = this;
      return computeSize(beAvatarGroup ? beAvatarGroup.size : this.size);
    },

    computedStyles() {
      const { computedSize: size, marginStyle } = this;
      return {
        width: size,
        height: size,
        ...marginStyle,
      };
    },

    computedTag() {
      const { href, button } = this;

      if (href) {
        return "a";
      } else if (button) {
        return "button";
      } else {
        return "div";
      }
    },

    computedVariant() {
      // Avatar Group variant takes precedence
      const { beAvatarGroup } = this;
      return beAvatarGroup && beAvatarGroup.variant
        ? beAvatarGroup.variant
        : this.variant;
    },

    contentClasses() {
      return [
        "d-flex",
        "align-items-center",
        "justify-content-center",
        "overflow-hidden",
        "w-100",
        "h-100",
      ];
    },

    hasDefaultSlot() {
      return !!this.$slots.default;
    },

    marginStyle() {
      const { computedSize: size, beAvatarGroup } = this;
      const overlapScale = beAvatarGroup ? beAvatarGroup.overlapScale : 0;
      const value =
        size && overlapScale ? `calc(${size} * -${overlapScale})` : null;
      return value ? { marginLeft: value, marginRight: value } : {};
    },

    textFontStyle() {
      const { computedSize: size } = this;
      const fontSize = !SIZES.includes(size)
        ? `calc(${size} * ${FONT_SIZE_SCALE})`
        : null;
      return fontSize ? { fontSize } : {};
    },
  },

  watch: {
    src(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.localSrc = newValue || undefined;
      }
    },
  },

  methods: {
    onClick(event) {
      if (this.disabled) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        this.$emit("click", event);
      }
    },

    onImgError(event) {
      this.localSrc = undefined;
      this.$emit("img-error", event);
    },
  },
};
</script>
