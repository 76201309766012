<template>
  <div class="d-inline-flex align-items-center">
    <component :is="tag" :class="computedClasses" :role="role" />
    <slot>
      <span class="sr-only">
        {{ localSrOnlyLabel }}
      </span>
    </slot>
  </div>
</template>

<script>
// Constants
const SIZES = ["sm", "lg"];

export default {
  name: "BeSpinner",

  props: {
    srOnlyLabel: {
      type: String,
      required: false,
      default: undefined, // $t("buttons.states.loading")
    },

    role: {
      type: String,
      required: false,
      default: "status",
    },

    size: {
      type: String,
      required: false,
      default: "sm",
    },

    tag: {
      type: String,
      required: false,
      default: "span",
    },

    variant: {
      type: String,
      required: false,
      default: "secondary",
    },
  },

  computed: {
    computedClasses() {
      return [
        "spinner-border",
        {
          ["mr-2"]: !!this.$slots.default,

          [`spinner-border-${this.size}`]:
            this.size && SIZES.includes(this.size),

          [`text-${this.variant}`]: this.variant,
        },
      ];
    },

    localSrOnlyLabel() {
      if (this.srOnlyLabel === undefined) {
        return this.$t("buttons.states.loading");
      }

      return this.srOnlyLabel;
    },
  },
};
</script>
