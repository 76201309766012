import { EventBus } from "@/event-bus";

export const EventsChannel = {
  channels: {
    UsersEventsChannel: {
      connected() {},
      rejected() {},

      received({ event }) {
        EventBus.emit(event);
      },

      disconnected() {},
    },
  },

  mounted() {
    if (this.$currentUser?.id) {
      this.$cable.subscribe(
        {
          channel: "Users::EventsChannel",
        },
        "UsersEventsChannel"
      );
    }
  },
};

export default EventsChannel;
