import axios from "axios";
import omit from "lodash/omit";
import { handleError } from "@/utils/error-handling";

export const state = {
  user: {},

  organizations: {
    admin_panel_memberships: [],
    company_memberships: [],
  },

  userActivitySessions: [],
  hasLoadedOrganizations: false,
  lastOrganizationsLoad: null,
  loadingOrganizationsTimeout: false,
};

export const getters = {
  getUser: (state) => state.user,
  getUserLocale: (state) => state.user?.locale,
  // Remove omit once slide show is implemented
  getOnboardingProgress: (state) => omit(state.user.progress, "introduction"),

  getOrganizations: (state) => state.organizations,
  hasLoadedOrganizations: (state) => state.hasLoadedOrganizations,
  lastOrganizationsLoad: (state) => state.lastOrganizationsLoad,
  getUserActivitySessions: (state) => state.userActivitySessions,
};

export const actions = {
  reset(context) {
    context.commit("reset");
  },

  setUser(context, user) {
    context.commit("setUser", user);
  },

  async setPreferences(context, preferences) {
    try {
      const { data } = await axios.patch("/users/preferences", {
        user: preferences,
      });

      context.commit("setUser", data);
    } catch (error) {
      handleError(error);
    }
  },

  async loadOrganizations(context) {
    try {
      const { data } = await axios.get("/users/organizations");
      context.commit("setOrganizations", data);
    } catch (error) {
      handleError(error);
    }
  },
};

export const mutations = {
  reset(state) {
    state.user = {};
  },

  setUser(state, user) {
    state.user = user;
  },

  setOrganizations(state, organizations) {
    state.organizations = organizations;
    state.hasLoadedOrganizations = true;
    state.lastOrganizationsLoad = new Date();
  },

  setLoadingOrganizationsTimeout(state, value) {
    state.loadingOrganizationsTimeout = value;
  },

  setUserActivitySessions(state, sessions) {
    state.userActivitySessions = sessions;
  },

  setUserActivitySession(state, session) {
    const index = state.userActivitySessions.findIndex(
      (existing) => existing.id === session.id
    );

    if (index !== -1) {
      state.userActivitySessions[index] = session;
    }
  },

  removeUserActivitySession(state, sessionId) {
    state.userActivitySessions = state.userActivitySessions.filter(
      (session) => session.id !== sessionId
    );
  },
};

export default {
  namespaced: true,
  sessionCached: true,
  state,
  getters,
  actions,
  mutations,
};
