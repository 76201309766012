/**
 * Toggle directive
 *
 * This directive is used in conjunction with the BeCollapse component to
 * toggle the collapse state of the component.
 *
 * Usage:
 *
 * <button v-be-toggle="'collapse-id'">Toggle collapse</button>
 * <button v-be-toggle.collapse-id>Toggle collapse</button>
 *
 * Heavily inspired by the toggle directive from BootstrapVue
 * https://github.com/bootstrap-vue/bootstrap-vue/blob/dev/src/directives/toggle/toggle.js
 */

import { EventBus } from "@/event-bus";
import { KEY_CODE_ENTER, KEY_CODE_SPACE } from "@/constants/key-codes";

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;

// Property keys we use to store info on root element
const BE_TOGGLE_ROOT_HANDLER = "__BE_toggle_HANDLER__";
const BE_TOGGLE_CLICK_HANDLER = "__BE_toggle_CLICK__";
const BE_TOGGLE_STATE = "__BE_toggle_STATE__";
const BE_TOGGLE_TARGETS = "__BE_toggle_TARGETS__";

// Key codes used for toggle
const KEYDOWN_KEY_CODES = [KEY_CODE_ENTER, KEY_CODE_SPACE];

const isNonStandardTag = (el) => !["BUTTON", "A"].includes(el.tagName);

const getTargets = ({ modifiers, arg, value }, el) => {
  // Any modifiers are considered target IDs
  const targets = Object.keys(modifiers || {});

  // If value is a string, split out individual targets (if space delimited)
  value = typeof value === "string" ? value.split(/\s+/) : value;

  // Support target ID as link href (`href="#id"`)
  if (el.tagName === "A") {
    const href = el.getAttribute("href") || "";
    if (href && href.startsWith("#")) {
      targets.push(href.slice(1));
    }
  }

  // Add ID from `arg` (if provided), and support value
  // as a single string ID or an array of string IDs.
  // If `value` is not an array or string, then it gets filtered out.
  (arg || [])
    .concat(value || [])
    .filter((id) => typeof id === "string" && id)
    .forEach((id) => targets.push(id));

  // Return targets as an array of unique target IDs
  return [...new Set(targets)];
};

const removeClickListener = (el) => {
  const handler = el[BE_TOGGLE_CLICK_HANDLER];

  if (handler) {
    el.removeEventListener("click", handler, false);
    el.removeEventListener("keydown", handler, false);
    delete el[BE_TOGGLE_CLICK_HANDLER];
  }
};

const addClickListener = (el, instance) => {
  removeClickListener(el);

  if (instance) {
    // Create our handler
    const handler = (event) => {
      if (
        !(
          event.type === "keydown" && !KEYDOWN_KEY_CODES.includes(event.keyCode)
        ) &&
        !el.disabled
      ) {
        const targets = el[BE_TOGGLE_TARGETS] || [];
        targets.forEach((target) => {
          EventBus.emit("be::toggle::collapse", target);
        });
      }
    };

    // Add our handler as a property on the element
    el[BE_TOGGLE_CLICK_HANDLER] = handler;

    // Listen for click events
    el.addEventListener("click", handler, { passive: true });

    // Listen for keydown events (if not a button or link)
    if (isNonStandardTag(el)) {
      el.addEventListener("keydown", handler, { passive: true });
    }
  }
};

const removeRootListeners = (el, instance) => {
  if (el[BE_TOGGLE_ROOT_HANDLER] && instance) {
    EventBus.off("be::toggle::state", el[BE_TOGGLE_ROOT_HANDLER]);
    EventBus.off("be::toggle::sync", el[BE_TOGGLE_ROOT_HANDLER]);
    delete el[BE_TOGGLE_ROOT_HANDLER];
  }
};

const addRootListeners = (el, instance) => {
  removeRootListeners(el, instance);

  if (instance) {
    // Create our handler
    const handler = ({ id, state }) => {
      // `state` will be `true` if target is expanded
      if ((el[BE_TOGGLE_TARGETS] || []).includes(id)) {
        el[BE_TOGGLE_STATE] = state;
        setToggleState(el, state);
      }
    };

    // Add our handler as a property on the element
    el[BE_TOGGLE_ROOT_HANDLER] = handler;

    // Listen for toggle state changes and sync
    EventBus.on("be::toggle::state", handler);
    EventBus.on("be::toggle::sync", handler);
  }
};

const setToggleState = (el, state) => {
  // State refers to the visibility of the collapse (true = visible, false = hidden)
  if (state) {
    el.classList.remove("collapsed");
    el.classList.add("not-collapsed");
    el.setAttribute("aria-expanded", "true");
  } else {
    el.classList.remove("not-collapsed");
    el.classList.add("collapsed");
    el.setAttribute("aria-expanded", "false");
  }
};

// Handle directive updates
const handleUpdate = (el, binding, vnode) => {
  // If element is not a button or link, we add `role="button"`
  // and a tabindex for accessibility
  if (isNonStandardTag(el)) {
    if (!el.getAttribute("role")) {
      el.setAttribute("role", "button");
    }

    if (!el.getAttribute("tabindex")) {
      el.setAttribute("tabindex", "0");
    }
  }

  // Ensure the collapse class and `aria-*` attributes persist
  // after element is updated (either by parent re-rendering
  // or changes to this element or its contents).
  setToggleState(el, el[BE_TOGGLE_STATE]);

  // Parse list of target IDs
  const targets = getTargets(binding, el);

  // Ensure the `aria-controls` attribute has not been overwritten
  // or removed when vnode updates. Also ensure to set `overflow-anchor`
  // to `none` to prevent the browser's scroll position from jumping.
  if (targets.length > 0) {
    el.setAttribute("aria-controls", targets.join(" "));
    el.style.overflowAnchor = "none";
  } else {
    el.removeAttribute("aria-controls");
    el.style.overflowAnchor = "";
  }

  // Add/update our click listener(s).
  // Wrap in a `requestAnimationFrame()` to allow any previous
  // click handling to occur first.
  requestAnimationFrame(() => {
    addClickListener(el, vnode.ctx);
  });

  // If targets array has changed, update our root listeners
  if (JSON.stringify(targets) !== JSON.stringify(el[BE_TOGGLE_TARGETS] || [])) {
    // Update targets array on element
    el[BE_TOGGLE_TARGETS] = targets;

    // Request a state update for each target to ensure the
    // expanded state is correct for each target.
    targets.forEach((target) => {
      EventBus.emit("be::toggle::request-state", target);
    });
  }
};

export default {
  beforeMount(el, binding, vnode) {
    // State is initially collapsed until we receive a state event
    el[BE_TOGGLE_STATE] = false;

    // Assume no targets initially
    el[BE_TOGGLE_TARGETS] = [];

    // Add our root listeners
    addRootListeners(el, vnode.ctx);

    // Initial update of trigger
    handleUpdate(el, binding, vnode);
  },

  updated: handleUpdate,

  unmounted(el, binding, vnode) {
    // Remove click listener
    removeClickListener(el);

    // Remove root listeners
    removeRootListeners(el, vnode.ctx);

    // Remove any stored data
    delete el[BE_TOGGLE_STATE];
    delete el[BE_TOGGLE_TARGETS];

    // Reset classes/attributes/styles
    el.classList.remove("collapsed");
    el.classList.remove("not-collapsed");
    el.removeAttribute("aria-expanded");
    el.removeAttribute("aria-controls");
    el.removeAttribute("role");
    el.removeAttribute("tabindex");
    el.style.overflowAnchor = "";
  },
};
