import axios from "axios";
import { handleError } from "@/utils/error-handling";

export const state = {
  comments: [],
  hasLoadedComments: false,
};

export const getters = {
  getComments: (state) => state.comments,

  getCommentsForReference: (state) => (reference) => {
    return state.comments.filter(
      (comment) =>
        comment.commented_id === reference.id &&
        comment.commented_type === reference.type
    );
  },

  hasLoadedComments: (state) => state.hasLoadedComments,
};

export const actions = {
  async fetchComments({ commit }, url) {
    try {
      const { data } = await axios.get(url);
      commit("SET_COMMENTS", data);
      commit("SET_HAS_LOADED_COMMENTS", true);
    } catch (error) {
      handleError(error);
    }
  },

  // Comments are created in the Comments.vue component
};

export const mutations = {
  SET_COMMENTS(state, comments) {
    state.comments = comments;
  },

  ADD_COMMENT(state, comment) {
    state.comments.push(comment);
  },

  UPDATE_COMMENT(state, comment) {
    const index = state.comments.findIndex((c) => c.id === comment.id);

    if (index !== -1) {
      state.comments[index] = comment;
    }
  },

  SET_HAS_LOADED_COMMENTS(state, hasLoadedComments) {
    state.hasLoadedComments = hasLoadedComments;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
