<template>
  <div class="table-responsive">
    <table :id="id" :class="computedTableClasses">
      <slot />
    </table>
  </div>
</template>

<script>
import { generateId } from "@/utils/id";

export default {
  name: "BeTableSimple",

  props: {
    id: {
      type: String,
      required: false,
      default: () => generateId("be-table-simple"),
    },

    hover: {
      type: Boolean,
      required: false,
      default: false,
    },

    striped: {
      type: Boolean,
      required: false,
      default: false,
    },

    tableClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },
  },

  computed: {
    computedTableClasses() {
      return [
        "table",
        this.tableClass,
        { "table-striped": this.striped, "table-hover": this.hover },
      ];
    },
  },
};
</script>
