<template>
  <div>
    <be-skeleton-wrapper :loading="initialLoading">
      <template #loading>
        <be-table-simple>
          <thead>
            <th class="col-shrink" />

            <th>
              <be-skeleton type="text" />
            </th>

            <th>
              <be-skeleton type="text" />
            </th>

            <th>
              <be-skeleton type="text" width="50%" />
            </th>
          </thead>

          <tbody>
            <tr v-for="index in 3" :key="index">
              <td>
                <be-skeleton type="avatar" />
              </td>

              <td>
                <be-skeleton type="text" />
              </td>

              <td>
                <be-skeleton type="text" />
              </td>

              <td>
                <be-skeleton type="button" />
              </td>
            </tr>
          </tbody>
        </be-table-simple>
      </template>

      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="col-shrink"></th>

              <th>
                {{ $t("components.meetings.tabs.sign.e_signature.table.name") }}
              </th>

              <th class="col-shrink" colspan="2">
                {{ $t("attributes.status") }}
              </th>

              <th v-if="!readOnly" class="text-right"></th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="signature in sortedSignatures"
              :key="`signature-${signature.id}`"
            >
              <td>
                <user-avatar :user="signature.name" />
              </td>

              <td>
                <div>
                  {{ signature.name }}
                </div>

                <small v-if="signature.sub_name">
                  {{ signature.sub_name }}
                </small>
              </td>

              <td class="col-shrink pr-0">
                <i
                  v-if="signature.signed_at"
                  v-be-tooltip="{
                    disabled: !compact,
                    title: signedStatus(signature),
                  }"
                  class="fas fa-fw fa-check-circle text-success"
                />

                <i
                  v-else-if="signature.rejected_at"
                  v-be-tooltip="{
                    disabled: !compact,
                    title: rejectedStatus(signature),
                  }"
                  class="fas fa-fw fa-times-circle text-danger"
                />

                <i
                  v-else
                  v-be-tooltip="{
                    disabled: !compact,
                    title: unsignedStatus(signature),
                  }"
                  class="fal fa-fw fa-exclamation-circle"
                />
              </td>

              <td class="col-shrink">
                {{ signatureStatusText(signature) }}
              </td>

              <td v-if="!readOnly" class="text-right">
                <be-spinner v-if="loadingSignatureIds.includes(signature.id)">
                  {{ $t("buttons.states.loading") }}
                </be-spinner>

                <be-button
                  v-else-if="
                    !hasRejectedSignatures &&
                    !signature.signed_at &&
                    signature.user_id === $currentUser.id
                  "
                  :href="signature.path"
                  size="sm"
                  variant="primary"
                  inline
                >
                  {{ $t("components.shared.signature_table.sign") }}
                </be-button>

                <be-button
                  v-else-if="!hasRejectedSignatures && signature.remindable"
                  size="sm"
                  variant="outline-secondary"
                  icon="fa-envelope"
                  inline
                  @click="$emit('remind', signature)"
                >
                  {{ $t("components.shared.signature_table.remind") }}
                </be-button>

                <be-button
                  v-if="signature.rejected_at && signature.rejection_message"
                  size="sm"
                  variant="outline-secondary"
                  inline
                  @click="openRejectionModal(signature)"
                >
                  {{ $t("buttons.titles.read_more") }}
                </be-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </be-skeleton-wrapper>

    <be-modal
      :id="rejectionModalId"
      :title="$t('components.documents.signature_row.rejected_signature')"
      no-footer
      @hidden="clearRejectionModal"
    >
      <template v-if="rejectedSignature">
        <p>
          {{
            $t("components.documents.signature_row.rejected_w_name_w_date", {
              name: rejectedSignature.name,
              date: $d(parseDate(rejectedSignature.rejected_at), "dateTime"),
            })
          }}.
        </p>

        <label>
          {{ $t("activerecord.attributes.signature.rejection_message") }}
        </label>

        <div
          :key="purifyState"
          v-dompurify-html="rejectedSignature.rejection_message"
        />
      </template>
    </be-modal>
  </div>
</template>

<script>
import { compareText } from "@/utils/text-utils";

export default {
  props: {
    signatures: {
      type: Array,
      required: true,
    },

    initialLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    allowedToRemind: {
      type: Boolean,
      required: true,
      default: true,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },

    loadingSignatureIds: {
      type: Array,
      required: false,
      default: () => [],
    },

    compact: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["remind"],

  data() {
    return {
      rejectionModalId: this.generateUuid(),
      rejectedSignature: null,
      purifyState: this.generateUuid(),
    };
  },

  computed: {
    hasRejectedSignatures() {
      return this.signatures.some((signature) => signature.rejected_at);
    },

    sortedSignatures() {
      return [...this.signatures].sort((a, b) => compareText(a, b, "name"));
    },
  },

  methods: {
    signatureStatusText(signature) {
      if (signature.signed_at) {
        return this.signedStatus(signature, this.compact);
      } else if (signature.rejected_at) {
        return this.rejectedStatus(signature, this.compact);
      } else {
        return this.unsignedStatus(signature, this.compact);
      }
    },

    unsignedStatus(signature, compact = false) {
      if (signature.reminded_at) {
        return this.formatStatus(
          this.$t("components.shared.signature_table.reminded"),
          signature.reminded_at,
          compact
        );
      }

      return this.formatStatus(
        this.$t("components.shared.signature_table.requested"),
        signature.requested_at,
        compact
      );
    },

    signedStatus(signature, compact = false) {
      return this.formatStatus(
        this.$t("components.shared.signature_table.signed"),
        signature.signed_at,
        compact
      );
    },

    rejectedStatus(signature, compact = false) {
      return this.formatStatus(
        this.$t("components.shared.signature_table.rejected"),
        signature.rejected_at,
        compact
      );
    },

    formatStatus(string, dateString, compact) {
      if (compact || !dateString) {
        return string;
      } else {
        return `${string} ${this.formatDateString(dateString)}`;
      }
    },

    formatDateString(dateString) {
      const date = new Date(dateString);

      if (date.getDate() == new Date().getDate()) {
        return this.$i18n.d(date, "time");
      } else {
        return this.$i18n.d(date, "short");
      }
    },

    openRejectionModal(signature) {
      if (signature) {
        this.purifyState = this.generateUuid();
        this.rejectedSignature = signature;
        this.$beModal.show(this.rejectionModalId);
      } else {
        this.clearRejectionModal();
      }
    },

    clearRejectionModal() {
      this.rejectedSignature = null;
      this.$beModal.hide(this.rejectionModalId);
    },
  },
};
</script>
