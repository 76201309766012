import i18n from "@/i18n";

const PREVIEW_LENGTH = 125;
function truncateText(text, limit = PREVIEW_LENGTH) {
  if (!text) {
    return "";
  }

  if (text.length >= limit) {
    return `${text.slice(0, limit)}...`;
  } else {
    return text;
  }
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function compareText(a, b, key, locale) {
  if (
    !a[key] ||
    !b[key] ||
    typeof a[key].toLowerCase !== "function" ||
    typeof b[key].toLowerCase !== "function"
  ) {
    return;
  }

  if (!locale) {
    locale = this?.$i18n?.locale || "sv";
  }

  return new Intl.Collator(locale, {
    numeric: true,
    sensitivity: "base",
  }).compare(a[key], b[key]);
}

function validateEmail(email) {
  if (email) {
    // https://stackoverflow.com/a/46181/8020072
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  return null;
}

function convertToKebabCase(string) {
  return string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, "-")
    .toLowerCase();
}

// https://observablehq.com/@mbostock/localized-number-parsing
class NumberParser {
  constructor(locale) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = [
      ...new Intl.NumberFormat(locale, { useGrouping: false }).format(
        9876543210
      ),
    ].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(
      `[${parts.find((d) => d.type === "group").value}]`,
      "g"
    );
    this._decimal = new RegExp(
      `[${parts.find((d) => d.type === "decimal").value}]`
    );
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = (d) => index.get(d);
  }
  parse(string) {
    const value = string
      .trim()
      .replace(this._group, "")
      .replace(this._decimal, ".")
      .replace(this._numeral, this._index);

    return value ? +value : NaN;
  }
}

function parseStringToNumber(string, locale = null) {
  locale = locale || this?.$i18n?.locale || "sv";
  if (!string) return NaN;
  return new NumberParser(locale).parse(string.replace(/\s/g, ""));
}

function toSentence(array, locale) {
  const twoWordConnector =
    i18n.global.messages[locale].support.array.two_words_connector;
  const lastWordConnector =
    i18n.global.messages[locale].support.array.last_word_connector;
  const wordsConnector =
    i18n.global.messages[locale].support.array.words_connector;

  switch (array.length) {
    case 0:
      return "";
    case 1:
      return array[0];
    case 2:
      return `${array[0]}${twoWordConnector}${array[1]}`;
    default:
      return `${array
        .slice(0, -1)
        .join(wordsConnector)}${lastWordConnector}${array.slice(-1)}`;
  }
}

export {
  truncateText,
  capitalize,
  compareText,
  validateEmail,
  convertToKebabCase,
  parseStringToNumber,
  toSentence,
};
