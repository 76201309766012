import { EventBus } from "@/event-bus";

/**
 * Modal helper class for opening/closing modals thats
 * globally registered on the Vue instance as `$beModal`.
 */
class BeModal {
  constructor(vm) {
    Object.assign(this, { _vm: vm });
    Object.defineProperties(this, {
      _vm: { enumerable: true, configurable: false, writable: false },
    });
  }

  show(id, ...options) {
    if (id) {
      EventBus.emit("be::show::modal", id, ...options);
    }
  }

  hide(id, ...options) {
    if (id) {
      EventBus.emit("be::hide::modal", id, ...options);
    }
  }

  toggle(id, ...options) {
    if (id) {
      EventBus.emit("be::toggle::modal", id, ...options);
    }
  }
}

export default BeModal;
