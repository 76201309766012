<template>
  <div class="card mb-2">
    <div class="card-header d-flex align-items-center justify-content-between">
      <h5 class="card-title">
        <slot name="title" />
      </h5>

      <slot name="header-buttons" />
    </div>

    <div class="card-body">
      <slot name="body">
        <div class="row">
          <div class="col-12">
            <slot name="metadata" />
          </div>

          <div class="col-12">
            <slot name="table" />
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>
